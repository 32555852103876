import clsx from 'clsx';
import { ReactElement, useCallback, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Loading } from './loading';

type ContextMenuProps = {
  options: { copy: string; onClick: () => void }[];
  loading?: boolean;
  disabled?: boolean;
} & ({ copy: string } | { button: ReactElement });

export function ContextMenu(props: ContextMenuProps): JSX.Element {
  const disabled = props.loading || props.disabled;
  const [visible, setVisible] = useState(false);

  const handleMouseEvent = useCallback(
    (val: boolean) => () => {
      if (props.disabled) return;
      setVisible(val);
    },
    [props.disabled],
  );

  return (
    <div
      className="text-sm relative"
      onMouseEnter={handleMouseEvent(true)}
      onMouseLeave={handleMouseEvent(false)}
    >
      {'button' in props && props.button}

      {'copy' in props && (
        <button
          type="button"
          className={clsx(
            'relative bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-400 font-medium rounded-lg px-4 py-2.5 text-center inline-flex items-center',
            { 'bg-gray-300': visible || !props.loading },
          )}
          disabled={disabled}
          style={{ cursor: disabled ? 'not-allowed' : '' }}
        >
          <span
            className={clsx('', {
              visible: !props.loading,
              invisible: props.loading,
            })}
          >
            {props.copy}&nbsp;
          </span>
          <FaChevronDown />
          <div
            className={clsx('absolute top-1/3 inset-x-1/3', {
              visible: props.loading,
              invisible: !props.loading,
            })}
          >
            <Loading />
          </div>
        </button>
      )}

      <div
        className={clsx(
          'z-10 cursor-pointer absolute bg-white divide-y divide-gray-100 rounded-lg shadow right-0 overflow-hidden',
          { hidden: !visible || props.loading },
        )}
      >
        <ul>
          {props.options.map((o) => (
            <li key={o.copy}>
              <a
                onClick={(): void => {
                  o.onClick();
                  setVisible(false);
                }}
                className="block whitespace-nowrap px-4 py-2.5 hover:bg-gray-100"
              >
                {o.copy}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
