import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { Dropdown } from 'components/dropdown-v7';
import { Loading } from 'components/loading';
import {
  objectiveStatusDropdownOptions,
  trackedMetricDropdownOptions,
} from 'components/objectives/dropdown-options';
import { Input } from 'components/input';
import { TextArea } from 'components/text-area';
import {
  Objective,
  ObjectivePageQuery,
  ObjectiveStatus,
  ObjectiveTrackedMetric,
  UpdateObjectiveMutation,
  UpdateObjectiveMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type UpdateObjectiveFormData = {
  id: string;
  name: string;
  description: string;
  status: ObjectiveStatus;
  trackedMetrics: ObjectiveTrackedMetric[];
};

const ObjectivePage = (): React.ReactElement => {
  const showNotification = useNotifications();
  const { objectiveId } = useParams<{ objectiveId: string }>();

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<UpdateObjectiveFormData>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (formData: UpdateObjectiveFormData) => {
    const input = formData;
    await updateObjective({ variables: { input } });
    showNotification({
      type: 'success',
      message: 'Objective updated',
    });
  });

  const { loading: loadingQuery } = useQuery<ObjectivePageQuery>(
    gql`
      query ObjectivePage {
        objectives {
          id
          name
          description
          status
          trackedMetrics
          createdAt
          updatedAt
        }
      }
    `,
    {
      onCompleted: (data) => {
        const ob = (data?.objectives ?? []).find(
          (ob: Objective) => ob.id === objectiveId,
        );

        if (ob) {
          const { id, name, description, status, trackedMetrics } = ob;
          reset({ id, name, description, status, trackedMetrics });
        }
      },
    },
  );

  const [updateObjective] = useMutation<
    UpdateObjectiveMutation,
    UpdateObjectiveMutationVariables
  >(gql`
    mutation UpdateObjective($input: UpdateObjectiveInput!) {
      updateObjective(input: $input) {
        objective {
          id
          name
          description
          status
          trackedMetrics
          createdAt
          updatedAt
        }
      }
    }
  `);

  return loadingQuery ? (
    <Loading />
  ) : (
    <div className="flex flex-col space-y-8">
      <div className="w-2/3 bg-white p-4">
        <form onSubmit={onSubmit}>
          <div className="flex-col space-y-6">
            <div className="w-full space-x-4">
              <Input {...register('id')} label="Objective ID" disabled={true} />
            </div>
            <div className="w-full space-x-4">
              <Input
                {...register('name', { required: true })}
                label="Objective Name"
              />
            </div>
            <div className="w-full space-x-4">
              <Dropdown
                name="status"
                label="Status"
                options={objectiveStatusDropdownOptions}
                control={control}
                rules={{ required: true }}
              />
            </div>
            <div className="w-full space-x-4">
              <TextArea
                placeholder="Succinct description of the Objective"
                rows={5}
                {...register('description', { required: true })}
                label="Description"
              />
            </div>
            <div className="w-full space-x-4">
              <Dropdown
                name="trackedMetrics"
                label="Tracked Metrics"
                isMulti
                options={trackedMetricDropdownOptions}
                control={control}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="pt-4">
            <Button fullWidth type="submit" loading={isSubmitting}>
              Update Objective
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ObjectivePage;
