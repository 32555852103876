import { Markdown } from 'components/markdown';
import { NoteRowFragment } from 'graphql/types';
import { formatDateAndTime } from 'utils/misc';
import { ExpandToggle } from './expand-toggle';
import clsx from 'clsx';
import { NoteRowBody } from './note-row-body';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { Tag } from 'components/tag';

const classNames = {
  cell: 'px-6 py-2 leading-5 text-gray-900',
};

export const NoteRow: React.FC<{
  note: NoteRowFragment;
  isExpanded: boolean;
  onToggleExpand: () => void;
}> = ({ note, isExpanded, onToggleExpand }) => {
  const addendums = ('addendums' in note && note.addendums) || [];
  return (
    <>
      <tr key={note.id}>
        <td className={clsx(classNames.cell, 'whitespace-nowrap')}>
          {formatDateAndTime(note.createdAt)}
        </td>
        <td className={clsx(classNames.cell, 'whitespace-nowrap')}>
          {getAuthorDisplayName(note.author, note.legacy)}
        </td>
        <td className={clsx(classNames.cell, 'flex')}>
          {note.archivalNote ? (
            <Tag color="gray" shape="box" size="small">
              Archived
            </Tag>
          ) : (
            <>
              {addendums.length > 0 && (
                <div className="flex justify-center items-center rounded-full bg-orange-400 px-2 mr-1">
                  {addendums.length}
                </div>
              )}
              {note.body && (
                <Markdown src={note.body} className="line-clamp-1" />
              )}
            </>
          )}
        </td>
        <td className={classNames.cell}>
          <ExpandToggle isOpen={isExpanded} onClick={onToggleExpand} />
        </td>
      </tr>
      {isExpanded && (
        <tr className="bg-gray-100">
          <td colSpan={6} valign="top">
            <NoteRowBody note={note} />
          </td>
        </tr>
      )}
    </>
  );
};
