import { Option } from 'components/dropdown-v7';
import { ObjectiveStatus, ObjectiveTrackedMetric } from 'graphql/types';

interface TrackedMetricDropdownOption extends Option {
  value: ObjectiveTrackedMetric;
  label: string;
}

export const trackedMetricDropdownOptions: TrackedMetricDropdownOption[] = [
  { value: 'WEIGHT_KG', label: 'Weight (KG)' },
];

interface ObjectiveStatusDropdownOption extends Option {
  value: ObjectiveStatus;
  label: string;
}

export const objectiveStatusDropdownOptions: ObjectiveStatusDropdownOption[] = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'DRAFT', label: 'Draft' },
];
