import React from 'react';
import { Option, Dropdown } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import { ConsultationWhereInput } from 'graphql/types';
import {
  getConsultationStatusFromString,
  getProblemTypeFromString,
} from 'utils/misc';
import { useForm } from 'react-hook-form-6';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';
import { problemTypeOptions } from 'utils/dropdown-options';
import { DoctorSelect } from 'components/doctor-select';

const FILTER_CHANGE_DEBOUNCE_MS = 500;

export const ConsultationFilter = (): React.ReactElement => {
  const query = useGetNativeQuery();

  const { control, register, watch } = useForm<ConsultationUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const status = getConsultationStatusFromString(watch().status);
  const problemType = getProblemTypeFromString(watch().problemType);
  const doctorId = watch().doctorId;
  const type = watch().type;
  const search = watch().search;
  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () => {
      changeUrl({
        params: {
          status,
          problemType,
          doctorId,
          type,
          search,
          pageIndex: 0,
        },
      });
    },
    FILTER_CHANGE_DEBOUNCE_MS,
    [status, problemType, doctorId, type, search],
  );

  return (
    <form>
      <div className="flex space-x-2 mb-8">
        <div className="w-1/4">
          <Dropdown
            name="status"
            label="Status"
            options={statusFilterOptions}
            control={control}
          />
        </div>
        <div className="w-1/4">
          <Dropdown
            name="problemType"
            label="Problem Type"
            options={[{ label: 'All', value: '' }, ...problemTypeOptions]}
            control={control}
          />
        </div>
        <div className="w-1/4">
          <Dropdown
            name="type"
            label="Type"
            options={typeFilterOptions}
            control={control}
          />
        </div>
        <div className="w-1/4">
          <Input
            ref={register}
            name="search"
            label="Search"
            placeholder="Search by Email, Name or ID"
          />
        </div>
      </div>
      <div className="flex space-x-2 mb-8">
        <div className="w-1/4">
          <DoctorSelect
            showAllDoctors={true}
            name="doctorId"
            label="Practitioner"
            control={control}
          />
        </div>
      </div>
    </form>
  );
};

type ConsultationUrlQueryParams = {
  status: string;
  type: string;
  doctorId: string;
  problemType: string;
  search: string;
};

const useGetNativeQuery = (): ConsultationUrlQueryParams => {
  const urlQuery = useUrlQuery();
  return {
    status: urlQuery.get('status') ?? '',
    type: urlQuery.get('type') ?? '',
    doctorId: urlQuery.get('doctorId') ?? '',
    problemType: urlQuery.get('problemType') ?? '',
    search: urlQuery.get('search') ?? '',
  };
};

export const useBuildConsultationQueryFilter = (): ConsultationWhereInput => {
  const { status, doctorId, problemType, search, type } = useGetNativeQuery();
  return React.useMemo(() => {
    const statusFilter: ConsultationWhereInput = status
      ? { status: { equals: getConsultationStatusFromString(status) } }
      : {};

    const problemTypeFilter: ConsultationWhereInput = problemType
      ? { type: { equals: getProblemTypeFromString(problemType) } }
      : {};

    const doctorFilter: ConsultationWhereInput = doctorId
      ? { doctor: { id: { equals: doctorId } } }
      : {};

    let typeFilter: ConsultationWhereInput = {};
    switch (type) {
      case 'review':
        typeFilter = { isReview: { equals: true } };
        break;
      case 'followup':
        typeFilter = { isFollowUp: { equals: true } };
        break;
      case 'initial':
        typeFilter = {
          isReview: { equals: false },
          isFollowUp: { equals: false },
        };
        break;
    }

    const [firstName, lastName] = search?.trim()?.split(' ') ?? ['', ''];
    const searchFilter: ConsultationWhereInput = search
      ? {
          OR: [
            { id: { contains: search, mode: 'insensitive' } },
            {
              customer: {
                OR: [
                  { email: { contains: search, mode: 'insensitive' } },
                  {
                    firstName: { contains: search, mode: 'insensitive' },
                  },
                  {
                    lastName: { contains: search, mode: 'insensitive' },
                  },
                  { id: { contains: search, mode: 'insensitive' } },
                  {
                    AND: [
                      {
                        firstName: { contains: firstName, mode: 'insensitive' },
                      },
                      { lastName: { contains: lastName, mode: 'insensitive' } },
                    ],
                  },
                ],
              },
            },
          ],
        }
      : {};

    const orderStatusFilter: ConsultationWhereInput = {
      order: {
        AND: [
          {
            status: { equals: 'AWAITING_SCRIPT' },
          },
        ],
      },
    };

    return {
      AND: [
        statusFilter,
        problemTypeFilter,
        doctorFilter,
        typeFilter,
        searchFilter,
        orderStatusFilter,
      ],
    };
  }, [status, doctorId, problemType, search, type]);
};

const typeFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Initial', value: 'initial' },
  { label: 'Followup', value: 'followup' },
  { label: 'Review', value: 'review' },
];

const statusFilterOptions: Option[] = [
  { label: 'All', value: '' },
  { label: 'Awaiting Answers', value: 'AWAITING_ANSWERS' },
  { label: 'Awaiting Doctor', value: 'AWAITING_DOCTOR' },
  { label: 'Awaiting Payment', value: 'AWAITING_PAYMENT' },
  { label: 'Awaiting Survey', value: 'AWAITING_SURVEY' },
  { label: 'Awaiting Verify ID', value: 'AWAITING_VERIFY_ID' },
  { label: 'Canceled', value: 'CANCELED' },
  { label: 'Customer Completed', value: 'CUSTOMER_COMPLETED' },
  { label: 'Doctor Assigned', value: 'DOCTOR_ASSIGNED' },
  { label: 'Doctor Completed', value: 'DOCTOR_COMPLETED' },
  { label: 'Order Completed', value: 'ORDER_COMPLETED' },
];
