import { ReactElement, useState } from 'react';
import {
  getPregnancyStatusString,
  formatDob,
  upperSnakeCaseToCapitalCase,
  formatAgeInYearsAndMonths,
} from 'utils/misc';
import { Button } from 'components/button';
import { MedicalHistoryDrawer } from './medical-history-drawer';
import { EditMedicalRecordDrawerButton } from './edit-medical-record-drawer-button';
import { Customer } from '.././types';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { gql } from '@apollo/client';
import {
  PatientAllergy,
  PatientCondition,
  PatientMedication,
} from 'graphql/types';
import clsx from 'clsx';
import { FaRegFile } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';

type SectionEntry = PatientCondition | PatientMedication | PatientAllergy;

const MedicalRecordSection = ({
  title,
  isStructuredClinicalDataEnabled,
  usesCurrent = false,
  sectionEntries,
  legacySectionEntry,
}: {
  title: string;
  isStructuredClinicalDataEnabled: boolean;
  usesCurrent?: boolean;
  sectionEntries: SectionEntry[] | null | undefined;
  legacySectionEntry: string | null | undefined;
}): ReactElement => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h4 className="text-sm font-bold pb-2">{title}</h4>
        {isStructuredClinicalDataEnabled &&
          usesCurrent &&
          sectionEntries &&
          sectionEntries.length > 0 && (
            <div className="flex space-x-2">
              <div className="space-x-1">
                <span className="text-xs font-semibold text-slate-700">C</span>
                <span className="text-xs text-slate-700">Current</span>
              </div>
              <div className="space-x-1">
                <span className="text-xs font-semibold text-slate-700 opacity-50">
                  P
                </span>
                <span className="text-xs text-slate-700">Previous</span>
              </div>
            </div>
          )}
      </div>
      {isStructuredClinicalDataEnabled && (
        <>
          {sectionEntries && sectionEntries.length > 0 ? (
            <div className="space-y-1">
              {sectionEntries.map((sectionEntry, index) => {
                let name = '';
                switch (sectionEntry.__typename) {
                  case 'PatientCondition':
                    name = sectionEntry.snomedCondition.name;
                    break;
                  case 'PatientMedication':
                    name = sectionEntry.snomedMedication.name;
                    break;
                  case 'PatientAllergy':
                    name = sectionEntry.snomedAllergy.name;
                }

                return (
                  <div
                    className={clsx(
                      'px-2 py-2 flex items-center space-x-2 justify-between',
                      index % 2 === 0 ? 'bg-slate-50' : '',
                    )}
                    key={sectionEntry.id}
                  >
                    <div className="flex space-x-2 items-center">
                      {usesCurrent &&
                        sectionEntry.__typename === 'PatientCondition' && (
                          <span
                            className={clsx(
                              'text-sm font-bold',
                              sectionEntry.current
                                ? 'text-neutral-900'
                                : 'text-neutral-500',
                            )}
                          >
                            {sectionEntry.current ? 'C' : 'P'}
                          </span>
                        )}
                      <span className="text-sm">{name}</span>
                    </div>
                    {sectionEntry.note && (
                      <>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          data-tooltip-id={`${sectionEntry.id}-note-tooltip`}
                          data-tooltip-place="top"
                          data-tooltip-content={sectionEntry.note}
                          data-tooltip-offset={16}
                        >
                          <FaRegFile color="#737373" />
                          <ReactTooltip
                            className="max-w-sm z-10 break-words whitespace-pre-wrap"
                            id={`${sectionEntry.id}-note-tooltip`}
                          />
                        </a>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="bg-slate-50 py-1 px-2 text-sm text-neutral-800 my-1">
              No record listed
            </div>
          )}
          {legacySectionEntry && (
            <span className="break-words text-sm text-neutral-800">
              Legacy notes: {legacySectionEntry}
            </span>
          )}
        </>
      )}
      {!isStructuredClinicalDataEnabled && (
        <span className="break-words text-sm text-neutral-800">
          {legacySectionEntry || '-'}
        </span>
      )}
    </div>
  );
};

const PersonalInformationField = ({
  id,
  label,
  value,
}: {
  id: string;
  label: string;
  value: string;
}): ReactElement => {
  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="text-sm font-semibold text-slate-700">
        {label}
      </label>
      <span id={id} className="text-gray-900 text-sm">
        {value}
      </span>
    </div>
  );
};

const MedicalRecord = ({ customer }: { customer: Customer }): ReactElement => {
  const [medicalHistoryOpen, setMedicalHistoryOpen] = useState(false);
  const featureFlagClient = useFeatureFlagClient();
  const isStructuredClinicalDataEnabled = featureFlagClient.getBoolean(
    'enable-structured-clinical-data',
    { defaultValue: false },
  );
  const {
    allergies,
    dob,
    existingConditions,
    currentMedications,
    pregnancyStatus,
    height,
    weight,
    sex,
    undergoingWeightMaintenance,
    patientConditions,
    patientMedications,
    patientAllergies,
  } = customer.medicalProfile;
  return (
    <div className="overflow-hidden rounded shadow bg-white px-4 pt-2 pb-4">
      {customer.medicalRecordHistory && (
        <MedicalHistoryDrawer
          medicalRecordHistory={customer.medicalRecordHistory}
          show={medicalHistoryOpen}
          onClose={(): void => {
            setMedicalHistoryOpen(false);
          }}
        />
      )}
      <div className="flex flex-row justify-between items-center pb-5 pt-3">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Medical record
        </h3>
        <EditMedicalRecordDrawerButton
          medicalProfile={customer.medicalProfile}
          customerId={customer.id}
        />
      </div>
      <div className="border-b border-gray-200 -mx-4" />
      <h4 className="text-sm font-bold pt-3 pb-2">Personal information</h4>
      <div className="grid grid-cols-2 gap-2 pb-4">
        <PersonalInformationField
          id="dob"
          label="Date of birth:"
          value={
            dob ? `${formatDob(dob)} (${formatAgeInYearsAndMonths(dob)})` : '-'
          }
        />
        <PersonalInformationField
          id="gender-at-birth"
          label="Gender at birth:"
          value={sex ? upperSnakeCaseToCapitalCase(sex) : '-'}
        />
        <PersonalInformationField
          id="weight"
          label="Weight:"
          value={weight ? `${weight.toFixed(1)} kg` : '-'}
        />
        <PersonalInformationField
          id="height"
          label="Height:"
          value={height ? `${height.toFixed(1)} cm` : '-'}
        />
        <PersonalInformationField
          id="pregnancy-status"
          label="Pregnancy Status:"
          value={
            pregnancyStatus ? getPregnancyStatusString(pregnancyStatus) : '-'
          }
        />
        <PersonalInformationField
          id="undergoing-weight-maintenance"
          label="Maintenance Programme:"
          value={
            undergoingWeightMaintenance === true
              ? 'Yes'
              : undergoingWeightMaintenance === false
              ? 'No'
              : '-'
          }
        />
      </div>
      <div className="space-y-2">
        <MedicalRecordSection
          title="Conditions"
          isStructuredClinicalDataEnabled={isStructuredClinicalDataEnabled}
          sectionEntries={patientConditions}
          legacySectionEntry={existingConditions}
          usesCurrent
        />
        <MedicalRecordSection
          title="Medications"
          isStructuredClinicalDataEnabled={isStructuredClinicalDataEnabled}
          sectionEntries={patientMedications}
          legacySectionEntry={currentMedications}
        />
        <MedicalRecordSection
          title="Allergies"
          isStructuredClinicalDataEnabled={isStructuredClinicalDataEnabled}
          sectionEntries={patientAllergies}
          legacySectionEntry={allergies}
        />
      </div>
      <div className="flex justify-end pt-4">
        <div className="w-72">
          <Button
            fullWidth
            variant="outline"
            size="small"
            onClick={(): void => setMedicalHistoryOpen(true)}
          >
            View medical record history
          </Button>
        </div>
      </div>
    </div>
  );
};

const medicalRecordDocument = gql`
  fragment MedicalRecord on MedicalProfile {
    id
    patientConditions {
      id
      current
      note
      updatedAt
      snomedCondition {
        id
        name
      }
    }
    patientMedications {
      id
      note
      updatedAt
      snomedMedication {
        id
        name
      }
    }
    patientAllergies {
      id
      note
      updatedAt
      snomedAllergy {
        id
        name
      }
    }
  }
`;

MedicalRecord.fragment = medicalRecordDocument;

export { MedicalRecord };
