import React from 'react';
import { ProductWhereInput } from 'graphql/types';
import { Dropdown } from 'components/dropdown';
import { useForm } from 'react-hook-form-6';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';
import { problemTypeOptions } from 'utils/dropdown-options';
import { isValidProblemType } from 'utils/misc';

type ProductsUrlQueryParams = {
  productType: string;
  problemTypes: string[];
};

const FILTER_CHANGE_DEBOUNCE_MS = 500;

export const ProductsFilter = (): React.ReactElement => {
  const query = useGetNativeQuery();

  const { control, watch } = useForm<ProductsUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const productType = watch().productType;
  const problemTypes = watch().problemTypes;

  const changeUrl = useChangeUrl();
  useDebouncedEffect(
    () =>
      changeUrl({
        params: {
          productType,
          problemTypes,
          pageIndex: 0,
        },
      }),
    FILTER_CHANGE_DEBOUNCE_MS,
    [productType, problemTypes],
  );

  return (
    <form className="flex mb-8 flex-1 space-x-4">
      <div className="w-1/2">
        <Dropdown
          name="productType"
          label="Product Type"
          options={[
            { label: 'All', value: '' },
            { label: 'OTC', value: 'OTC' },
            { label: 'Rx', value: 'RX' },
            { label: 'Content', value: 'CONTENT' },
            { label: 'Accessory', value: 'ACCESSORY' },
          ]}
          control={control}
        />
      </div>
      <div className="w-1/2">
        <Dropdown
          name="problemTypes"
          label="Problem Types"
          options={problemTypeOptions}
          control={control}
          isMulti
        />
      </div>
    </form>
  );
};

const useGetNativeQuery = (): ProductsUrlQueryParams => {
  const urlQuery = useUrlQuery();

  return {
    productType: urlQuery.get('productType') ?? '',
    problemTypes: urlQuery.getAll('problemTypes') ?? [],
  };
};

export const useBuildProductsQueryFilter = (): ProductWhereInput => {
  const { productType, problemTypes } = useGetNativeQuery();

  return React.useMemo(() => {
    let productTypeFilter: ProductWhereInput = {};
    switch (productType) {
      case 'OTC':
        productTypeFilter = { productType: { equals: 'OTC' } };
        break;
      case 'RX':
        productTypeFilter = { productType: { equals: 'RX' } };
        break;
      case 'CONTENT':
        productTypeFilter = { productType: { equals: 'CONTENT' } };
        break;
      case 'ACCESSORY':
        productTypeFilter = { productType: { equals: 'ACCESSORY' } };
        break;
      default:
        productTypeFilter = {};
        break;
    }

    const filteredProblemTypes = problemTypes.filter(isValidProblemType);

    const problemTypeFilter: ProductWhereInput =
      problemTypes.length > 0
        ? {
            problemTypes: {
              hasSome: filteredProblemTypes,
            },
          }
        : {};

    return {
      AND: [productTypeFilter, problemTypeFilter],
    };
  }, [productType, problemTypes]);
};
