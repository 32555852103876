import React from 'react';
import { Dropdown } from 'components/dropdown';
import { ReviewReason } from 'graphql/types';
import { getReviewReasonFromString } from 'utils/misc';
import { useForm } from 'react-hook-form-6';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';
import { reasonOptions } from 'utils/dropdown-options';
import { usePageIndex } from 'components/table';

export const QueueFilter = (): React.ReactElement => {
  const query = useQueueQueryFilterValues();

  const { control } = useForm<QueueUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const changeUrl = useChangeUrl();

  return (
    <form>
      <div className="flex space-x-2 mb-8">
        <div className="w-1/4">
          <Dropdown
            name="reviewReason"
            label="Reason (review consults only)"
            options={[{ label: 'All', value: '' }, ...reasonOptions]}
            control={control}
            handleSingleSelect={(option) => {
              changeUrl({
                params: {
                  reviewReason: option.value,
                  pageIndex: 0,
                },
              });
            }}
          />
        </div>
      </div>
    </form>
  );
};

type QueueUrlQueryParams = {
  reviewReason: ReviewReason | null;
  pageIndex: number;
};

export const useQueueQueryFilterValues = (): QueueUrlQueryParams => {
  const urlQuery = useUrlQuery();
  const pageIndex = usePageIndex();

  const reviewReason = urlQuery.get('reviewReason');
  const cleanReviewReason = reviewReason
    ? getReviewReasonFromString(reviewReason)
    : null;

  return {
    reviewReason: cleanReviewReason ?? null,
    pageIndex,
  };
};
