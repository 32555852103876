import React from 'react';
import Select, { Styles } from 'react-select';
import {
  Controller,
  Control,
  RegisterOptions,
  FieldValues,
  FieldPath,
} from 'react-hook-form';
import { Label } from 'components/label';
import { InputError } from 'components/input-error';
import { TooltipProps } from './tooltip';

export interface Option<T = string> {
  value?: T;
  label: string | JSX.Element;
  isDisabled?: boolean;
}

interface DropdownProps<P extends FieldValues, T = string> {
  name: FieldPath<P>;
  options: Option<T>[];
  control: Control<P>;
  label?: string;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  errorMessage?: string | JSX.Element;
  rules?: RegisterOptions<P>;
  isMulti?: boolean;
  defaultValue?: string;
  customStyles?: Styles<Option<string>, boolean>;
  handleSingleSelect?: (option: Option<T>) => void;
  placeholder?: string;
  tooltipProps?: TooltipProps;
  description?: string;
  closeMenuOnSelect?: boolean;
}

export const Dropdown = <P extends FieldValues>({
  name,
  options,
  control,
  label,
  isMulti = false,
  disabled = false,
  menuPlacement = 'auto',
  errorMessage,
  rules,
  customStyles,
  defaultValue,
  placeholder,
  handleSingleSelect,
  description,
  tooltipProps,
  closeMenuOnSelect = true,
}: DropdownProps<P>): JSX.Element => (
  <div>
    {(label || description) && (
      <div className="mb-3 flex flex-col gap-1">
        {label && (
          <Label htmlFor={name} tooltip={tooltipProps ?? undefined}>
            {label}
          </Label>
        )}
        {description && <p className="text-sm text-gray-700">{description}</p>}
      </div>
    )}
    <Controller
      render={({ field }) => {
        const { onChange, value, name, ref } = field;
        return (
          <Select
            {...field}
            closeMenuOnSelect={closeMenuOnSelect}
            isMulti={isMulti}
            inputId={name}
            options={options}
            isDisabled={disabled}
            className="dropdown"
            classNamePrefix="dd"
            placeholder={placeholder}
            menuPlacement={menuPlacement}
            styles={{
              control: (base): React.CSSProperties => ({
                ...base,
                borderColor: errorMessage ? '#f56565' : '#e2e8f0',
              }),
              ...customStyles,
            }}
            innerRef={ref}
            defaultInputValue={defaultValue}
            value={
              isMulti && Array.isArray(value)
                ? options.filter((it) => value.includes(it.value))
                : options.find((it) => it.value === value) ?? null
            }
            onChange={(v) => {
              // Multi-select dropdowns
              if (isMulti) {
                if (
                  Array.isArray(v) &&
                  v.every((option) => 'value' in option)
                ) {
                  onChange(v.map((option) => option.value));
                } else if (v === null) {
                  onChange(null);
                }
              }

              // Single-select dropdowns
              if (v && 'value' in v) {
                onChange(v.value);
                if (handleSingleSelect != undefined) {
                  handleSingleSelect(v);
                }
              }
            }}
          />
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
    <InputError>{errorMessage}</InputError>
  </div>
);
