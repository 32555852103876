import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { TextArea } from 'components/text-area';
import {
  LoginAsCustomerMutation,
  LoginAsCustomerMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form-6';
import { useCopyToClipboard } from 'react-use';

type ImpersonateCustomerModalProps = {
  customerId: string;
  show: boolean;
  onClose: () => void;
};

type ImpersonateCustomerForm = {
  reason: string;
};

export const ImpersonateCustomerModal: React.FC<
  ImpersonateCustomerModalProps
> = ({ customerId, show, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ImpersonateCustomerForm>({ mode: 'onChange' });
  const [, copyToClipboard] = useCopyToClipboard();

  const showNotification = useNotifications();

  const [loginAsCustomer, { loading, data }] = useMutation<
    LoginAsCustomerMutation,
    LoginAsCustomerMutationVariables
  >(
    gql`
      mutation loginAsCustomer($input: LoginAsCustomerInput!) {
        loginAsCustomer(input: $input) {
          webAppUrl
          mobileAppUrl
        }
      }
    `,
    {
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to login as customer',
        });
      },
    },
  );

  return (
    <Modal show={show} onClose={onClose} width="w-1/3">
      <section className="bg-gray-200">
        <header className="border-b border-gray-500 py-3 px-5 bg-gray-300 flex flex-row justify-between items-center">
          <h2 className="text-lg font-semibold">Impersonate Customer</h2>
          <Button
            size="small"
            variant="outline"
            color="danger"
            onClick={() => onClose()}
          >
            Close
          </Button>
        </header>

        <form
          className="px-5 py-3 flex flex-col gap-2 items-center"
          onSubmit={handleSubmit(async (data) => {
            await loginAsCustomer({
              variables: {
                input: {
                  customerId,
                  reason: data.reason.trim(),
                },
              },
            });
          })}
        >
          <div className="w-full">
            <TextArea
              label="Impersonation reason*"
              rows={4}
              ref={register({
                required: 'A reason for impersonating the patient',
                validate: {
                  isNotEmpty: (value: string) =>
                    !!value.trim() || 'reason cannot be empty',
                },
              })}
              placeholder="add reason here"
              name="reason"
              errorMessage={errors.reason?.message}
            />
          </div>

          <Button
            color="danger"
            type="submit"
            loading={loading}
            disabled={!isValid}
          >
            Login as customer
          </Button>
        </form>

        {data?.loginAsCustomer && (
          <div className="flex flex-col gap-1 items-center">
            <div className="text-lg font-semibold">Actions</div>
            <div className="flex flex-col gap-2 justify-center text-center">
              {data.loginAsCustomer.webAppUrl ? (
                <Button
                  onClick={() => {
                    if (data.loginAsCustomer?.webAppUrl) {
                      window.open(data.loginAsCustomer!.webAppUrl);
                      onClose();
                    }
                  }}
                >
                  Open in web
                </Button>
              ) : (
                <div className="text-red-600">Web link not available</div>
              )}
              {data.loginAsCustomer.mobileAppUrl ? (
                <Button
                  onClick={() => {
                    if (data.loginAsCustomer?.mobileAppUrl) {
                      copyToClipboard(data.loginAsCustomer.mobileAppUrl);
                      showNotification({
                        type: 'success',
                        message: 'Mobile link copied to clipboard',
                      });
                      onClose();
                    }
                  }}
                >
                  Copy mobile link
                </Button>
              ) : (
                <div className="text-red-600">Mobile link not available</div>
              )}
            </div>
          </div>
        )}

        <article className="space-y-3 px-5 py-3">
          <em>
            This feature should only be used to assist with troubleshooting
            customer issues. Logging in as a customer is recorded in the
            customer audit logs and all actions taken while logged in as the
            customer will be tracked against your personal account. All customer
            data and PII should not be recorded or shared.
          </em>
        </article>
      </section>
    </Modal>
  );
};
