import { formatISO } from 'date-fns/esm';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import isValid from 'date-fns/isValid';
import {
  ClinicianType,
  ConsultationStage,
  ConsultationStatus,
  FulfillmentStatus,
  Maybe,
  OrderStatus,
  PathologyRequestStatus,
  PregnancyStatus,
  ProblemType,
  PurchaseCancelReason,
  TreatmentStatus,
  ReviewReason,
} from 'graphql/types';
import { ConsultDoctorCompletedStatus } from 'pages/customer/types';
import { config } from '../config';
import { formatCurrency } from '@eucalyptusvc/lib-localization';
import { brandFromEngineBrand } from '@eucalyptusvc/lib-adapters';
import { intervalToDuration } from 'date-fns';

export const getProblemTypeEmoji = (problemType?: ProblemType): string => {
  switch (problemType) {
    case 'ERECTILE':
      return '🍌';
    case 'PREMATURE':
      return '💦';
    case 'SLEEP':
      return '🌚';
    case 'MENTAL_HEALTH_GOOD':
      return '😁';
    case 'MENTAL_HEALTH_MEH':
      return '😐';
    case 'MENTAL_HEALTH_SAD':
      return '😟';
    case 'ACNE':
      return '🌋';
    case 'ANTI_AGEING':
      return '⏳';
    case 'SKIN_GENERAL':
      return '🧴';
    case 'CONTRACEPTION':
      return '💊';
    case 'DEPRECATED_OTC':
      return '🛍';
    case 'COLD_SORES':
    case 'HERPES':
      return '👄';
    case 'UTI':
      return '🔥';
    case 'PREGNANCY':
      return '🤰';
    case 'POSTPARTUM':
      return '🐣';
    case 'PRECONCEPTION_NUTRITION':
    case 'PREGNANCY_NUTRITION':
    case 'POSTPARTUM_NUTRITION':
      return '🥗';
    case 'PRECONCEPTION_MENTAL_HEALTH':
    case 'PREGNANCY_MENTAL_HEALTH':
    case 'POSTPARTUM_MENTAL_HEALTH':
      return '🧠';
    case 'PRECONCEPTION_PHYSIO':
    case 'PREGNANCY_PHYSIO':
    case 'POSTPARTUM_PHYSIO':
      return '💪';
    case 'FERTILITY':
    case 'PRECONCEPTION_FERTILITY':
    case 'FERTILITY_HORMONE':
      return '🥚';
    case 'BREASTFEEDING':
      return '🍼';
    case 'INFANT_SLEEP':
      return '😴';
    case 'STI':
      return '🦠';
    case 'WEIGHT_LOSS':
      return `📉`;
    case 'MENOPAUSE':
      return `🧓🏻`;
    case 'GENETIC_TEST':
      return '🧬';
    case 'HAIR':
      return '🧢';
    case 'WOMENS_HEALTH':
      return '👩';
    default:
      return '⚡️';
  }
};

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export type Colors =
  | 'primary'
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'teal'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'dark-blue'
  | 'rose';

export const getProblemTypeColor = (problemType?: ProblemType): Colors => {
  switch (problemType) {
    case 'ERECTILE':
      return 'red';
    case 'PREMATURE':
    case 'SKIN_GENERAL':
      return 'orange';
    case 'SLEEP':
      return 'teal';
    case 'MENTAL_HEALTH_GOOD':
      return 'green';
    case 'MENTAL_HEALTH_MEH':
      return 'yellow';
    case 'ACNE':
      return 'indigo';
    case 'STI':
      return 'indigo';
    case 'ANTI_AGEING':
    case 'CONTRACEPTION':
      return 'pink';
    case 'COLD_SORES':
    case 'HERPES':
      return 'teal';
    case 'FERTILITY':
      return 'teal';
    case 'FERTILITY_HORMONE':
      return 'indigo';
    case 'PRECONCEPTION_FERTILITY':
    case 'PRECONCEPTION_NUTRITION':
    case 'PRECONCEPTION_MENTAL_HEALTH':
    case 'PRECONCEPTION_PHYSIO':
      return 'green';
    case 'PREGNANCY':
    case 'PREGNANCY_NUTRITION':
    case 'PREGNANCY_MENTAL_HEALTH':
    case 'PREGNANCY_PHYSIO':
    case 'GENETIC_TEST':
    case 'WOMENS_HEALTH':
      return 'purple';
    case 'POSTPARTUM':
    case 'POSTPARTUM_NUTRITION':
    case 'POSTPARTUM_MENTAL_HEALTH':
    case 'POSTPARTUM_PHYSIO':
      return 'orange';
    case 'BREASTFEEDING':
      return 'red';
    case 'MENOPAUSE':
      return 'rose';
    case 'INFANT_SLEEP':
      return 'blue';
    case 'WEIGHT_LOSS':
      return 'blue';
    case 'HAIR':
      return 'dark-blue';
    case 'MENTAL_HEALTH_SAD':
    default:
      return 'gray';
  }
};

export const getConsultationStatusFromString = (
  str?: string,
): undefined | ConsultationStatus => {
  switch (str) {
    case 'AWAITING_ANSWERS':
    case 'AWAITING_DOCTOR':
    case 'AWAITING_PAYMENT':
    case 'AWAITING_SURVEY':
    case 'AWAITING_VERIFY_ID':
    case 'CUSTOMER_COMPLETED':
    case 'DOCTOR_ASSIGNED':
    case 'DOCTOR_COMPLETED':
    case 'ORDER_COMPLETED':
    case 'CANCELED':
      return str;
    default:
      return undefined;
  }
};

export const isValidProblemType = (str: string): str is ProblemType => {
  switch (str as ProblemType) {
    case 'ACNE':
    case 'ANTI_AGEING':
    case 'BREASTFEEDING':
    case 'COLD_SORES':
    case 'CONTRACEPTION':
    case 'ERECTILE':
    case 'FERTILITY':
    case 'FERTILITY_HORMONE':
    case 'HAIR':
    case 'HERPES':
    case 'INFANT_SLEEP':
    case 'MENTAL_HEALTH_GOOD':
    case 'MENTAL_HEALTH_MEH':
    case 'MENTAL_HEALTH_SAD':
    case 'DEPRECATED_OTC':
    case 'POSTPARTUM':
    case 'POSTPARTUM_NUTRITION':
    case 'POSTPARTUM_MENTAL_HEALTH':
    case 'POSTPARTUM_PHYSIO':
    case 'PRECONCEPTION_FERTILITY':
    case 'PRECONCEPTION_NUTRITION':
    case 'PRECONCEPTION_MENTAL_HEALTH':
    case 'PRECONCEPTION_PHYSIO':
    case 'PREGNANCY':
    case 'PREGNANCY_NUTRITION':
    case 'PREGNANCY_MENTAL_HEALTH':
    case 'PREGNANCY_PHYSIO':
    case 'PREMATURE':
    case 'SKIN_GENERAL':
    case 'SLEEP':
    case 'UTI':
    case 'STI':
    case 'GENETIC_TEST':
    case 'WEIGHT_LOSS':
    case 'MENOPAUSE':
    case 'WOMENS_HEALTH':
      return true;
    default:
      return false;
  }
};

export const getProblemTypeFromString = (
  str?: string,
): undefined | ProblemType => {
  if (!str || !isValidProblemType(str)) {
    return undefined;
  }

  return str;
};

export const statusColorText = (
  status:
    | TreatmentStatus
    | OrderStatus
    | ConsultationStatus
    | FulfillmentStatus
    | '',
): string => {
  switch (status) {
    case 'ACTIVE':
    case 'COMPLETED':
    case 'CREATED':
    case 'FULFILLED':
      return 'text-green-900';
    case 'PAUSED':
    case 'PROCESSING_ORDER':
    case 'PROCESSING_PAYMENT':
    case 'REVIEW':
    case 'UNFULFILLED':
      return 'text-yellow-900';
    case 'PAYMENT_FAILED':
    case 'CANCELED':
    case 'FAILED_SCRIPT':
    case 'FAILED_SHOPIFY':
    case 'MIGRATED':
      return 'text-red-900';
    case 'AWAITING_VERIFY_ID':
    case 'FOLLOW_UP':
    case '':
    default:
      return 'text-gray-900';
  }
};

export const parseOrderStatus = (value: string): undefined | OrderStatus =>
  isOrderStatus(value) ? value : undefined;

export const isOrderStatus = (value: string): value is OrderStatus => {
  switch (value) {
    case 'AWAITING_SCRIPT':
    case 'CANCELED':
    case 'COMPLETED':
    case 'CREATED':
    case 'FAILED_SCRIPT':
    case 'FAILED_SHOPIFY':
    case 'FAILED_VENDOR_CREATION':
    case 'PENDING_VENDOR_CREATION':
      return true;
    default:
      return false;
  }
};

export const statusColorBackground = (
  status:
    | TreatmentStatus
    | OrderStatus
    | ConsultationStatus
    | FulfillmentStatus
    | '',
  completedStatus?: ConsultDoctorCompletedStatus,
): string => {
  switch (status) {
    case 'ACTIVE':
    case 'COMPLETED':
    case 'CREATED':
    case 'FULFILLED':
      return 'bg-green-100';
    case 'PAUSED':
    case 'PROCESSING_ORDER':
    case 'PROCESSING_PAYMENT':
    case 'REVIEW':
    case 'UNFULFILLED':
      return 'bg-yellow-100';
    case 'PAYMENT_FAILED':
    case 'CANCELED':
    case 'FAILED_SCRIPT':
    case 'FAILED_SHOPIFY':
      return 'bg-red-100';
    case 'AWAITING_VERIFY_ID':
    case 'FOLLOW_UP':
    case '':
    case 'DOCTOR_COMPLETED':
      switch (completedStatus) {
        case ConsultDoctorCompletedStatus.Approved:
          return 'bg-green-500';
        case ConsultDoctorCompletedStatus.Declined:
          return 'bg-red-500';
        default:
          return 'bg-gray-100';
      }
    default:
      return 'bg-gray-100';
  }
};

export const getConsultationStageColor = (stage: ConsultationStage): Colors => {
  switch (stage) {
    case 'INITIAL':
      return 'teal';
    case 'REVIEW':
      return 'blue';
    case 'FOLLOW_UP':
      return 'yellow';
  }
};

export const formatCurrencyAmountCents = (amount: number): string =>
  formatCurrency(brandFromEngineBrand(config.brand), amount, {
    includeSymbol: true,
    includeDecimals: true,
  });

export const formatDateAndTimeSeconds = (date: Date): string => {
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return format(date, 'do MMM yyyy HH:mm:ss');
};

export const formatDateAndTime = (
  input: Date | string,
  inputFormat = 'do MMM yyyy HH:mm',
): string => {
  const date = typeof input === 'string' ? new Date(input) : input;

  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return format(date, inputFormat);
};

export const formatDate = (dateInput: Date | string): string => {
  const date = new Date(dateInput);
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return format(date, 'do MMM yyyy');
};

export const formatDateShortMonthShortYear = (
  dateInput: Date | string,
): string => {
  const date = new Date(dateInput);
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return format(date, 'MMM yy');
};

export const formatDateTimeRange = (
  dateInput: Date | string,
  secondDateInput: Date | string,
): string => {
  const date = new Date(dateInput);
  const secondDate = new Date(secondDateInput);
  if (!isValid(date) || !isValid(secondDate)) {
    return 'Invalid Date';
  }
  return formatDistance(date, secondDate);
};

export const formatDob = (input: string): string => {
  let date;
  if (input.length === 10) {
    date = new Date(`${input}T00:00:00.000`);
  } else {
    date = new Date(input);
  }

  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return format(date, 'dd/MM/yyyy');
};

export const formatYMD = (date: Date): string => {
  if (!isValid(date)) {
    return 'Invalid Date';
  }
  return formatISO(date, { representation: 'date' });
};

export const formatPatientName = (user: { fullName: string }): string =>
  collapseSpaces(user.fullName);

export const upperSnakeCaseToCapitalCase = (s: string): string =>
  s
    .split('_')
    .map((s) => capitaliseString(s.toLowerCase()))
    .join(' ');

/**
 * Collapse any sequences of one or more spaces to a single space, and
 * trim whitespace from beginning and end.
 */
const collapseSpaces = (s: string): string => s.replace(/\s\s+/g, ' ').trim();

export const capitaliseString = (string: string): string => {
  return string.slice(0, 1).toUpperCase() + string.slice(1);
};

export const formatBooleanAsString = (value: Maybe<boolean>): string => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value.toString().toUpperCase();
};

export const dedupeArray = <T>(array: T[]): T[] => Array.from(new Set(array));

export const getPathologyStatusColor = (
  status?: PathologyRequestStatus | null,
): Colors => {
  switch (status) {
    case 'AWAITING_RESULTS':
      return 'yellow';
    default:
      return 'green';
  }
};

export const parseFileUrl = (
  fileString?: string,
): { filename: string; url: string; mimetype: string } | undefined => {
  try {
    if (fileString) {
      return JSON.parse(fileString);
    }
  } catch {
    return;
  }
};

export function mustBe<TValue>(value: Maybe<TValue>): value is TValue {
  return value !== null && value !== undefined;
}

export const getClinicianTypeFromString = (
  input?: string,
): ClinicianType | undefined => {
  switch (input) {
    case 'DOCTOR':
    case 'NURSE':
    case 'PHARMACIST':
      return input;
    default:
      return undefined;
  }
};

export const getPregnancyStatusString = (
  pregnancyStatus: PregnancyStatus,
): string => {
  switch (pregnancyStatus) {
    case 'PREGNANT':
      return 'Pregnant';
    case 'BREASTFEEDING':
      return 'Breastfeeding';
    case 'POTENTIALLY_PREGNANT':
      return 'Potentially pregnant';
    case 'TRYING_FOR_BABY':
      return 'Trying for a baby';
    case 'NOT_PREGNANT':
      return 'Not pregnant';
    case 'NOT_APPLICABLE':
      return 'Not applicable';
  }
};

export const purchaseCancelReasonToDisplayString = (
  cancelReason: Maybe<PurchaseCancelReason>,
): string => {
  switch (cancelReason) {
    case 'PAYMENT_FAILED':
      return 'Payment Failed';
    case 'ADMIN_TRIGGERED':
      return 'Cancelled By Admin';
    case 'UNLINKED_BY_DOCTOR':
      return 'Cancelled By Doctor';
    case 'SUPERSEDED':
      return 'Superseded';
    default:
      return 'Cancelled';
  }
};

export const consultationStageTitle = (
  stage: ConsultationStage,
  reviewReason?: string | null,
) => {
  let stageTitle = upperSnakeCaseToCapitalCase(stage);

  if (
    stage === 'REVIEW' &&
    reviewReason &&
    reviewReason !== 'LEGACY_MIGRATED'
  ) {
    stageTitle += ` - ${upperSnakeCaseToCapitalCase(reviewReason)}`;
  }

  return stageTitle;
};

export const getReviewReasonFromString = (
  str: string,
): ReviewReason | undefined => {
  switch (str) {
    case 'EXPIRED_SCRIPT':
    case 'EXTERNAL_SCRIPT_REQUEST':
    case 'LEGACY_MIGRATED':
    case 'MEDICAL_HISTORY_CHANGES':
    case 'MEDICAL_INQUIRY':
    case 'MEDICATION_CHANGES':
    case 'NOT_SEEING_RESULTS':
    case 'PATHOLOGY_RESULTS':
    case 'PROGRESS_REVIEW':
    case 'REACTIVATION':
    case 'SIDE_EFFECTS':
    case 'STOCKOUT_EVENT':
    case 'TRANSITION_OFF_TREATMENT':
    case 'URGENT_MEDICATION_CHANGES':
    case 'URGENT_NEW_SCRIPT_REQUIRED':
    case 'URGENT_SIDE_EFFECTS':
      return str;
    default:
      return undefined;
  }
};

export const formatAgeInYearsAndMonths = (input: string): string => {
  let date;
  if (input.length === 10) {
    date = new Date(`${input}T00:00:00.000`);
  } else {
    date = new Date(input);
  }

  if (!isValid(date)) {
    return 'Invalid Date';
  }

  const { years, months } = intervalToDuration({
    start: date,
    end: new Date(),
  });
  return `${years}y ${months}m`;
};
