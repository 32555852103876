import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/react-hook-form-6/input';
import { Label } from 'components/label';
import { Modal } from 'components/modal';
import { Checkbox } from 'components/checkbox';
import { useHasPermissions } from 'components/permissions';
import { IoIosSearch } from 'react-icons/io';
import { TextArea } from 'components/text-area';
import {
  Maybe,
  MedicalProfile,
  PregnancyStatus,
  Sex,
  SnomedConditionsQuery,
  SnomedConditionsQueryVariables,
  SnomedMedicationsQuery,
  SnomedMedicationsQueryVariables,
  SnomedAllergiesQuery,
  SnomedAllergiesQueryVariables,
  UpsertMedicalRecordMutation,
  UpsertMedicalRecordMutationVariables,
  UpsertPatientConditionInput,
  UpsertPatientMedicationInput,
  UpsertPatientAllergyInput,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { ComponentType, useEffect, useRef, useState } from 'react';
import { UseFormMethods, useFieldArray, useForm } from 'react-hook-form-6';
import { FaQuestionCircle } from 'react-icons/fa';
import { SlTrash } from 'react-icons/sl';
import { ControlProps, OptionTypeBase, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'react-tooltip';

const sexOptions: Option[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Intersex',
    value: 'INTERSEX',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
];

const Control: ComponentType<ControlProps<OptionTypeBase, false>> = ({
  children,
  ...props
}) => (
  <components.Control {...props}>
    <div className="pl-2">
      <IoIosSearch size={18} />
    </div>
    {children}
  </components.Control>
);

const pregnancyStatusOptions: Option[] = [
  {
    label: 'Pregnant',
    value: 'PREGNANT',
  },
  {
    label: 'Not pregnant',
    value: 'NOT_PREGNANT',
  },
  {
    label: 'Potentially pregnant',
    value: 'POTENTIALLY_PREGNANT',
  },
  {
    label: 'Trying for a baby',
    value: 'TRYING_FOR_BABY',
  },
  {
    label: 'Breastfeeding',
    value: 'BREASTFEEDING',
  },
  {
    label: 'N/A',
    value: 'NOT_APPLICABLE',
  },
];

const ConditionCard = ({
  conditionName,
  idx,
  formRegister,
  isCurrentValue,
  snomedConditionId,
  deleteEntry,
  noteValue,
  isVisible,
}: {
  conditionName: string;
  snomedConditionId: string;
  idx: number;
  isCurrentValue: boolean;
  formRegister: UseFormMethods['register'];
  noteValue: string;
  isVisible: boolean;
  deleteEntry: () => void;
}) => {
  const [isNoteEditable, setIsNoteEditable] = useState(false);
  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isNoteEditable && ref.current) {
      ref.current.focus();
      ref.current.selectionStart = ref.current.value.length;
      ref.current.selectionEnd = ref.current.value.length;
    }
  }, [isNoteEditable]);

  useEffect(() => {
    if (!isVisible) {
      setIsNoteEditable(false);
    }
  }, [isVisible]);

  return (
    <div className="p-3 rounded bg-white">
      <input
        ref={formRegister()}
        name={`conditions[${idx}].snomedConditionId`}
        value={snomedConditionId}
        hidden
        readOnly
      />
      <div className="flex justify-between items-baseline">
        <div className="text-sm font-semibold">{conditionName}</div>
        <div className="flex align-baseline">
          <div className="px-2 content-center">
            <label className="toggle">
              <input
                ref={formRegister()}
                type="checkbox"
                name={`conditions[${idx}].current`}
                defaultChecked={isCurrentValue}
              />
              <span className="slider conditions-slider" />
            </label>
          </div>
          <div className="h-10">
            <label>
              <button
                className="hover:bg-gray-500 bg-gray-200 rounded h-full aspect-square flex items-center justify-center"
                onClick={deleteEntry}
              >
                <SlTrash size="15" />
              </button>
            </label>
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className={`${isNoteEditable ? 'hidden' : ''}`}>
          <span className="text-gray-400 text-sm">{noteValue} </span>
          <label>
            <button
              type="button"
              className="hidden"
              onClick={() => setIsNoteEditable(true)}
            />
            <span className="text-sm text-gray-400 underline">
              {noteValue === '' ? 'Add a note' : 'Edit note'}
            </span>
          </label>
        </div>
        <div className={`${isNoteEditable ? '' : 'hidden'}`}>
          <TextArea
            rows={3}
            placeholder=""
            defaultValue={noteValue}
            ref={(t) => {
              ref.current = t;
              formRegister()(t);
            }}
            name={`conditions[${idx}].note`}
            maxLength={500}
            resize={true}
          />
        </div>
      </div>
    </div>
  );
};

const MedicationCard = ({
  medicationName,
  idx,
  formRegister,
  snomedMedicationId,
  deleteEntry,
  noteValue,
  isVisible,
}: {
  medicationName: string;
  snomedMedicationId: string;
  idx: number;
  formRegister: UseFormMethods['register'];
  noteValue: string;
  isVisible: boolean;
  deleteEntry: () => void;
}) => {
  const [isNoteEditable, setIsNoteEditable] = useState(false);

  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isNoteEditable && ref.current) {
      ref.current.focus();
      ref.current.selectionStart = ref.current.value.length;
      ref.current.selectionEnd = ref.current.value.length;
    }
  }, [isNoteEditable]);

  useEffect(() => {
    if (!isVisible) {
      setIsNoteEditable(false);
    }
  }, [isVisible]);

  return (
    <div className="p-3 rounded bg-white">
      <input
        ref={formRegister()}
        name={`medications[${idx}].snomedMedicationId`}
        value={snomedMedicationId}
        hidden
        readOnly
      />
      <div className="flex justify-between items-baseline">
        <div className="text-sm font-semibold">{medicationName}</div>
        <div className="flex align-baseline">
          <div className="h-10">
            <label>
              <button
                className="hover:bg-gray-500 bg-gray-200 rounded h-full aspect-square flex items-center justify-center"
                onClick={deleteEntry}
              >
                <SlTrash size="15" />
              </button>
            </label>
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className={`${isNoteEditable ? 'hidden' : ''}`}>
          <span className="text-gray-400 text-sm">{noteValue} </span>
          <label>
            <button
              type="button"
              className="hidden"
              onClick={() => setIsNoteEditable(true)}
            />
            <span className="text-sm text-gray-400 underline">
              {noteValue === '' ? 'Add a note' : 'Edit note'}
            </span>
          </label>
        </div>
        <div className={`${isNoteEditable ? '' : 'hidden'}`}>
          <TextArea
            rows={3}
            placeholder=""
            defaultValue={noteValue}
            name={`medications[${idx}].note`}
            maxLength={500}
            ref={(t) => {
              ref.current = t;
              formRegister()(t);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const AllergyCard = ({
  allergyName,
  idx,
  formRegister,
  snomedAllergyId,
  deleteEntry,
  noteValue,
  isVisible,
}: {
  allergyName: string;
  snomedAllergyId: string;
  idx: number;
  formRegister: UseFormMethods['register'];
  noteValue: string;
  isVisible: boolean;
  deleteEntry: () => void;
}) => {
  const [isNoteEditable, setIsNoteEditable] = useState(false);

  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isNoteEditable && ref.current) {
      ref.current.focus();
      ref.current.selectionStart = ref.current.value.length;
      ref.current.selectionEnd = ref.current.value.length;
    }
  }, [isNoteEditable]);

  useEffect(() => {
    if (!isVisible) {
      setIsNoteEditable(false);
    }
  }, [isVisible]);

  return (
    <div className="p-3 rounded bg-white">
      <input
        ref={formRegister()}
        name={`allergies[${idx}].snomedAllergyId`}
        value={snomedAllergyId}
        hidden
        readOnly
      />
      <div className="flex justify-between items-baseline">
        <div className="text-sm font-semibold">{allergyName}</div>
        <div className="flex align-baseline">
          <div className="h-10">
            <label>
              <button
                className="hover:bg-gray-500 bg-gray-200 rounded h-full aspect-square flex items-center justify-center"
                onClick={deleteEntry}
              >
                <SlTrash size="15" />
              </button>
            </label>
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className={`${isNoteEditable ? 'hidden' : ''}`}>
          <span className="text-gray-400 text-sm">{noteValue} </span>
          <label>
            <button
              type="button"
              className="hidden"
              onClick={() => setIsNoteEditable(true)}
            />
            <span className="text-sm text-gray-400 underline">
              {noteValue === '' ? 'Add a note' : 'Edit note'}
            </span>
          </label>
        </div>
        <div className={`${isNoteEditable ? '' : 'hidden'}`}>
          <TextArea
            rows={3}
            placeholder=""
            defaultValue={noteValue}
            name={`allergies[${idx}].note`}
            maxLength={500}
            ref={(t) => {
              ref.current = t;
              formRegister()(t);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export type EditMedicalRecordDrawerProps = {
  customerId: string;
  medicalProfile: MedicalProfile;
  show: boolean;
  onClose: () => void;
};

export const EditMedicalRecordDrawer: React.FC<
  EditMedicalRecordDrawerProps
> = ({ show, onClose, customerId, medicalProfile }) => {
  const featureFlagClient = useFeatureFlagClient();
  const isStructuredClinicalDataEnabled = featureFlagClient.getBoolean(
    'enable-structured-clinical-data',
  );

  const [
    isDeleteLegacyMedicationsConfirmationModalOpen,
    setIsDeleteLegacyMedicationsConfirmationModalOpen,
  ] = useState(false);
  const [displayMedicationsSearch, setDisplayMedicationsSearch] =
    useState(false);

  const [searchMedications] = useLazyQuery<
    SnomedMedicationsQuery,
    SnomedMedicationsQueryVariables
  >(gql`
    query SnomedMedications($searchTerm: String!) {
      snomedMedications(input: { searchTerm: $searchTerm, pageSize: 10 }) {
        snomedMedications {
          id
          name
        }
      }
    }
  `);

  const loadMedicationOptions = async (medicationsSearchTerm: string) =>
    new Promise<{ label: JSX.Element; value: string }[]>((resolve) => {
      if (medicationsSearchTerm.length < 4) {
        resolve([]);
      }

      setTimeout(() => {
        void searchMedications({
          variables: { searchTerm: medicationsSearchTerm },
        }).then((resp) => {
          if (resp.data?.snomedMedications?.snomedMedications) {
            const options = resp.data.snomedMedications.snomedMedications.map(
              (m) => ({
                value: m.id,
                name: m.name,
                label: (
                  <div
                    className="group flex justify-between cursor-pointer"
                    key={m.id}
                  >
                    <div>{m.name}</div>
                    <div className="hidden group-hover:block">+ Add</div>
                  </div>
                ),
              }),
            );
            resolve(options);
          }
          resolve([]);
        });
      }, 300);
    });

  const [
    isDeleteLegacyAllergiesConfirmationModalOpen,
    setIsDeleteLegacyAllergiesConfirmationModalOpen,
  ] = useState(false);
  const [displayAllergiesSearch, setDisplayAllergiesSearch] = useState(false);

  const [searchAllergies] = useLazyQuery<
    SnomedAllergiesQuery,
    SnomedAllergiesQueryVariables
  >(gql`
    query SnomedAllergies($searchTerm: String!) {
      snomedAllergies(input: { searchTerm: $searchTerm, pageSize: 10 }) {
        snomedAllergies {
          id
          name
        }
      }
    }
  `);

  const loadAllergyOptions = async (allergiesSearchTerm: string) =>
    new Promise<{ label: JSX.Element; value: string }[]>((resolve) => {
      if (allergiesSearchTerm.length < 4) {
        resolve([]);
      }

      setTimeout(() => {
        void searchAllergies({
          variables: { searchTerm: allergiesSearchTerm },
        }).then((resp) => {
          if (resp.data?.snomedAllergies?.snomedAllergies) {
            const options = resp.data.snomedAllergies.snomedAllergies.map(
              (a) => ({
                value: a.id,
                name: a.name,
                label: (
                  <div
                    className="group flex justify-between cursor-pointer"
                    key={a.id}
                  >
                    <div>{a.name}</div>
                    <div className="hidden group-hover:block">+ Add</div>
                  </div>
                ),
              }),
            );
            resolve(options);
          }
          resolve([]);
        });
      }, 300);
    });

  const [
    isDeleteLegacyConditionsConfirmationModalOpen,
    setIsDeleteLegacyConditionsConfirmationModalOpen,
  ] = useState(false);
  const [displayConditionsSearch, setDisplayConditionsSearch] = useState(false);

  const [searchConditions] = useLazyQuery<
    SnomedConditionsQuery,
    SnomedConditionsQueryVariables
  >(gql`
    query SnomedConditions($searchTerm: String!) {
      snomedConditions(input: { searchTerm: $searchTerm, pageSize: 10 }) {
        snomedConditions {
          id
          name
        }
      }
    }
  `);

  const loadConditionOptions = async (conditionsSearchTerm: string) =>
    new Promise<{ label: JSX.Element; value: string }[]>((resolve) => {
      if (conditionsSearchTerm.length < 4) {
        resolve([]);
      }

      setTimeout(() => {
        void searchConditions({
          variables: { searchTerm: conditionsSearchTerm },
        }).then((resp) => {
          if (resp.data?.snomedConditions?.snomedConditions) {
            const options = resp.data.snomedConditions.snomedConditions.map(
              (c) => ({
                value: c.id,
                name: c.name,
                label: (
                  <div
                    className="group flex justify-between cursor-pointer"
                    key={c.id}
                  >
                    <div>{c.name}</div>
                    <div className="hidden group-hover:block">+ Add</div>
                  </div>
                ),
              }),
            );
            resolve(options);
          }
          resolve([]);
        });
      }, 300);
    });

  const showNotification = useNotifications();

  const canEditAllergies = useHasPermissions(['EDIT_MEDICAL_RECORD_ALLERGIES']);
  const canEditCurrentMedications = useHasPermissions([
    'EDIT_MEDICAL_RECORD_CURRENT_MEDICATIONS',
  ]);
  const canEditDob = useHasPermissions(['EDIT_MEDICAL_RECORD_DOB']);
  const canEditHeight = useHasPermissions(['EDIT_MEDICAL_RECORD_HEIGHT']);
  const canEditMedicalConditions = useHasPermissions([
    'EDIT_MEDICAL_RECORD_MEDICAL_CONDITIONS',
  ]);
  const canEditPregnancyStatus = useHasPermissions([
    'EDIT_MEDICAL_RECORD_PREGNANCY_STATUS',
  ]);
  const canEditSex = useHasPermissions(['EDIT_MEDICAL_RECORD_SEX']);
  const canEditUndergoingWeightMaintenance = useHasPermissions([
    'EDIT_MEDICAL_RECORD_UNDERGOING_WEIGHT_MAINTENANCE',
  ]);

  // FIXME: Weight (only field) requires something called a provenance type that is baked into
  // the RPC/svc. For doctors, this is set as the consultationId, but changes by admins are not
  // related to a specific consult. I don't have time to unpick all of that, so I'm electing not
  // to allow admins to alter weight.
  // const canEditWeight = useHasPermissions(['EDIT_MEDICAL_RECORD_WEIGHT']);

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<{
    conditions?: {
      snomedConditionId: string;
      name: string;
      note: string;
      current: boolean;
    }[];
    medications?: {
      snomedMedicationId: string;
      name: string;
      note: string;
    }[];
    allergies?: {
      snomedAllergyId: string;
      name: string;
      note: string;
    }[];
    allergyIntolerances?: string | null;
    legacyConditions?: string | null;
    height?: number | null;
    medicationUsages?: string | null;
    sex?: Maybe<Sex>;
    dob: Maybe<string>;
    pregnancyStatus?: Maybe<PregnancyStatus>;
    undergoingWeightMaintenance: Maybe<boolean>;
    weightKg?: number | null;
    weightAction: 'CREATE' | 'OVERRIDE';
  }>({
    defaultValues: {
      medications:
        medicalProfile.patientMedications?.map((c) => ({
          name: c.snomedMedication.name,
          snomedMedicationId: c.snomedMedication.id,
          note: c.note,
        })) || [],
      conditions:
        medicalProfile.patientConditions?.map((c) => ({
          name: c.snomedCondition.name,
          snomedConditionId: c.snomedCondition.id,
          current: c.current,
          note: c.note,
        })) || [],
      allergies:
        medicalProfile.patientAllergies?.map((c) => ({
          name: c.snomedAllergy.name,
          snomedAllergyId: c.snomedAllergy.id,
          note: c.note,
        })) || [],
      allergyIntolerances: medicalProfile.allergies,
      legacyConditions: medicalProfile.existingConditions,
      height: medicalProfile.height,
      medicationUsages: medicalProfile.currentMedications,
      sex: medicalProfile.sex,
      dob: medicalProfile.dob,
      pregnancyStatus: medicalProfile.pregnancyStatus,
      undergoingWeightMaintenance: medicalProfile.undergoingWeightMaintenance,
    },
  });
  register('legacyConditions');
  register('medicationUsages');
  register('allergyIntolerances');

  const [upsertMedicalRecord, { loading }] = useMutation<
    UpsertMedicalRecordMutation,
    UpsertMedicalRecordMutationVariables
  >(
    gql`
      mutation UpsertMedicalRecord(
        $upsertMedicalRecordInput: UpsertMedicalRecordInput!
        $skipUpsertMedicalRecord: Boolean!
        $upsertPatientConditionsInput: UpsertPatientConditionsInput!
        $skipConditionsUpsert: Boolean!
        $deletePatientConditionsInput: DeletePatientConditionsInput!
        $skipConditionsDelete: Boolean!
        $upsertPatientMedicationsInput: UpsertPatientMedicationsInput!
        $skipMedicationsUpsert: Boolean!
        $deletePatientMedicationsInput: DeletePatientMedicationsInput!
        $skipMedicationsDelete: Boolean!
        $upsertPatientAllergiesInput: UpsertPatientAllergiesInput!
        $skipAllergiesUpsert: Boolean!
        $deletePatientAllergiesInput: DeletePatientAllergiesInput!
        $skipAllergiesDelete: Boolean!
      ) {
        upsertMedicalRecord(input: $upsertMedicalRecordInput)
          @skip(if: $skipUpsertMedicalRecord) {
          medicalProfile {
            id
            dob
            existingConditions
            allergies
            currentMedications
            height
            weight
            sex
            age
            bmi
            pregnancyStatus
            requiresClinicalReview
            undergoingWeightMaintenance
          }
          medicalRecordHistory {
            id
            field
            currentValue
            previousValue
            createdAt
            updatedAt
            updatedBy {
              id
              role
              roles {
                id
                value
              }
              fullName
              clinicianName
            }
          }
        }
        upsertPatientConditions(input: $upsertPatientConditionsInput)
          @skip(if: $skipConditionsUpsert) {
          medicalProfile {
            id
            patientConditions {
              id
              note
              current
            }
          }
          medicalRecordHistory {
            id
            field
            currentValue
            previousValue
            createdAt
            updatedAt
            updatedBy {
              id
              role
              roles {
                id
                value
              }
              fullName
              clinicianName
            }
          }
        }
        deletePatientConditions(input: $deletePatientConditionsInput)
          @skip(if: $skipConditionsDelete) {
          users {
            id
            medicalRecordHistory {
              id
              field
              currentValue
              previousValue
              createdAt
              updatedAt
              updatedBy {
                id
                role
                roles {
                  id
                  value
                }
                fullName
                clinicianName
              }
            }
            medicalProfile {
              id
              patientConditions {
                id
                note
              }
            }
          }
        }
        upsertPatientMedications(input: $upsertPatientMedicationsInput)
          @skip(if: $skipMedicationsUpsert) {
          medicalProfile {
            id
            patientMedications {
              id
              note
            }
          }
          medicalRecordHistory {
            id
            field
            currentValue
            previousValue
            createdAt
            updatedAt
            updatedBy {
              id
              role
              roles {
                id
                value
              }
              fullName
              clinicianName
            }
          }
        }
        deletePatientMedications(input: $deletePatientMedicationsInput)
          @skip(if: $skipMedicationsDelete) {
          users {
            id
            medicalRecordHistory {
              id
              field
              currentValue
              previousValue
              createdAt
              updatedAt
              updatedBy {
                id
                role
                roles {
                  id
                  value
                }
                fullName
                clinicianName
              }
            }
            medicalProfile {
              id
              patientMedications {
                id
                note
              }
            }
          }
        }
        upsertPatientAllergies(input: $upsertPatientAllergiesInput)
          @skip(if: $skipAllergiesUpsert) {
          medicalProfile {
            id
            patientAllergies {
              id
              note
            }
          }
          medicalRecordHistory {
            id
            field
            currentValue
            previousValue
            createdAt
            updatedAt
            updatedBy {
              id
              role
              roles {
                id
                value
              }
              fullName
              clinicianName
            }
          }
        }
        deletePatientAllergies(input: $deletePatientAllergiesInput)
          @skip(if: $skipAllergiesDelete) {
          users {
            id
            medicalRecordHistory {
              id
              field
              currentValue
              previousValue
              createdAt
              updatedAt
              updatedBy {
                id
                role
                roles {
                  id
                  value
                }
                fullName
                clinicianName
              }
            }
            medicalProfile {
              id
              patientAllergies {
                id
                note
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Medical record edited',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error editing medical record',
        });
      },
    },
  );

  const onSubmit = handleSubmit(
    async ({
      allergyIntolerances,
      legacyConditions,
      conditions,
      medications,
      allergies,
      medicationUsages,
      dob,
      height,
      pregnancyStatus,
      undergoingWeightMaintenance,
      sex,
    }) => {
      const deletePatientConditionIds: string[] = [];
      const snomedConditionIdsInForm =
        conditions?.map((formCondition) => formCondition.snomedConditionId) ||
        [];
      for (const profileCondition of medicalProfile.patientConditions || []) {
        if (
          !snomedConditionIdsInForm.includes(
            profileCondition.snomedCondition.id,
          )
        ) {
          deletePatientConditionIds.push(profileCondition.id);
        }
      }

      const upsertPatientConditionInputs: UpsertPatientConditionInput[] = [];
      if (conditions) {
        for (const formCondition of conditions) {
          const medicalProfileCondition =
            medicalProfile.patientConditions?.find(
              (a) => a.snomedCondition.id === formCondition.snomedConditionId,
            );

          if (!medicalProfileCondition) {
            // insert
            upsertPatientConditionInputs.push({
              snomedConditionId: formCondition.snomedConditionId,
              note: formCondition.note,
              current: formCondition.current,
            });
          } else if (
            medicalProfileCondition.note !== formCondition.note ||
            medicalProfileCondition.current !== formCondition.current
          ) {
            // update
            upsertPatientConditionInputs.push({
              snomedConditionId: formCondition.snomedConditionId,
              note: formCondition.note,
              current: formCondition.current,
            });
          }
        }
      }

      const deletePatientMedicationIds: string[] = [];
      const snomedMedicationIdsInForm =
        medications?.map(
          (formMedication) => formMedication.snomedMedicationId,
        ) || [];
      for (const profileMedication of medicalProfile.patientMedications || []) {
        if (
          !snomedMedicationIdsInForm.includes(
            profileMedication.snomedMedication.id,
          )
        ) {
          deletePatientMedicationIds.push(profileMedication.id);
        }
      }

      const upsertPatientMedicationInputs: UpsertPatientMedicationInput[] = [];
      if (medications) {
        for (const formMedication of medications) {
          const medicalProfileMedication =
            medicalProfile.patientMedications?.find(
              (a) =>
                a.snomedMedication.id === formMedication.snomedMedicationId,
            );

          if (!medicalProfileMedication) {
            // insert
            upsertPatientMedicationInputs.push({
              snomedMedicationId: formMedication.snomedMedicationId,
              note: formMedication.note,
            });
          } else if (medicalProfileMedication.note !== formMedication.note) {
            // update
            upsertPatientMedicationInputs.push({
              snomedMedicationId: formMedication.snomedMedicationId,
              note: formMedication.note,
            });
          }
        }
      }

      const deletePatientAllergyIds: string[] = [];
      const snomedAllergyIdsInForm =
        allergies?.map((formAllergy) => formAllergy.snomedAllergyId) || [];
      for (const profileAllergy of medicalProfile.patientAllergies || []) {
        if (!snomedAllergyIdsInForm.includes(profileAllergy.snomedAllergy.id)) {
          deletePatientAllergyIds.push(profileAllergy.id);
        }
      }

      const upsertPatientAllergyInputs: UpsertPatientAllergyInput[] = [];
      if (allergies) {
        for (const formAllergy of allergies) {
          const medicalProfileAllergy = medicalProfile.patientAllergies?.find(
            (a) => a.snomedAllergy.id === formAllergy.snomedAllergyId,
          );

          if (!medicalProfileAllergy) {
            // insert
            upsertPatientAllergyInputs.push({
              snomedAllergyId: formAllergy.snomedAllergyId,
              note: formAllergy.note,
            });
          } else if (medicalProfileAllergy.note !== formAllergy.note) {
            // update
            upsertPatientAllergyInputs.push({
              snomedAllergyId: formAllergy.snomedAllergyId,
              note: formAllergy.note,
            });
          }
        }
      }

      await upsertMedicalRecord({
        variables: {
          skipUpsertMedicalRecord:
            allergyIntolerances === medicalProfile.patientAllergies &&
            medicationUsages === medicalProfile.currentMedications &&
            dob === medicalProfile.dob &&
            Number(height) === medicalProfile.height &&
            legacyConditions === medicalProfile.existingConditions &&
            pregnancyStatus === medicalProfile.pregnancyStatus &&
            sex === medicalProfile.sex &&
            undergoingWeightMaintenance ===
              medicalProfile.undergoingWeightMaintenance,
          upsertMedicalRecordInput: {
            customerId,
            ...(canEditAllergies ? { allergyIntolerances } : {}),
            ...(canEditCurrentMedications ? { medicationUsages } : {}),
            ...(canEditDob ? { dob } : {}),
            ...(canEditHeight && height ? { heightCm: Number(height) } : {}),
            ...(canEditMedicalConditions
              ? { conditions: legacyConditions }
              : {}),
            ...(canEditPregnancyStatus ? { pregnancyStatus } : {}),
            ...(canEditSex ? { sex } : {}),
            ...(canEditUndergoingWeightMaintenance
              ? { undergoingWeightMaintenance }
              : {}),
          },
          skipConditionsUpsert:
            isStructuredClinicalDataEnabled || !canEditMedicalConditions
              ? upsertPatientConditionInputs.length === 0
              : true,
          upsertPatientConditionsInput: {
            patientId: customerId,
            patientConditionInputs: upsertPatientConditionInputs,
          },
          skipConditionsDelete:
            isStructuredClinicalDataEnabled || !canEditMedicalConditions
              ? deletePatientConditionIds.length === 0
              : true,
          deletePatientConditionsInput: {
            patientConditionIds: deletePatientConditionIds,
          },
          skipMedicationsUpsert:
            isStructuredClinicalDataEnabled || !canEditCurrentMedications
              ? upsertPatientMedicationInputs.length === 0
              : true,
          upsertPatientMedicationsInput: {
            patientId: customerId,
            patientMedicationInputs: upsertPatientMedicationInputs,
          },
          skipMedicationsDelete:
            isStructuredClinicalDataEnabled || !canEditCurrentMedications
              ? deletePatientMedicationIds.length === 0
              : true,
          deletePatientMedicationsInput: {
            patientMedicationIds: deletePatientMedicationIds,
          },
          skipAllergiesUpsert:
            isStructuredClinicalDataEnabled || !canEditAllergies
              ? upsertPatientAllergyInputs.length === 0
              : true,
          upsertPatientAllergiesInput: {
            patientId: customerId,
            patientAllergyInputs: upsertPatientAllergyInputs,
          },
          skipAllergiesDelete:
            isStructuredClinicalDataEnabled || !canEditAllergies
              ? deletePatientAllergyIds.length === 0
              : true,
          deletePatientAllergiesInput: {
            patientAllergyIds: deletePatientAllergyIds,
          },
        },
      });

      onClose();

      reset({
        allergyIntolerances,
        legacyConditions,
        conditions: conditionsFieldArray.fields.map((c) => ({
          name: c.name,
          current: c.current,
          snomedConditionId: c.snomedConditionId,
          note: c.note,
        })),
        medications: medicationsFieldArray.fields.map((m) => ({
          name: m.name,
          snomedMedicationId: m.snomedMedicationId,
          note: m.note,
        })),
        allergies: allergiesFieldArray.fields.map((a) => ({
          name: a.name,
          snomedAllergyId: a.snomedAllergyId,
          note: a.note,
        })),
        undergoingWeightMaintenance,
        medicationUsages,
        dob,
        height,
        pregnancyStatus,
        sex,
      });
    },
  );

  const conditionsFieldArray = useFieldArray({
    control,
    name: 'conditions',
  });

  const medicationsFieldArray = useFieldArray({
    control,
    name: 'medications',
  });

  const allergiesFieldArray = useFieldArray({
    control,
    name: 'allergies',
  });

  return (
    <Drawer show={show} onClose={onClose}>
      <div className="h-full overflow-y-auto bg-slate-100">
        <div className="flex flex-col justify-between top-0 pt-6 pl-6 pr-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">
            Edit Medical Record
          </h1>
          <section className="flex flex-col">
            <div className="mb-6">
              <p>
                These fields are part of the patient record and used in the
                script. Any changes will be recorded in the patient details
                history.
              </p>
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Personal Information
            </h2>
            <form
              onSubmit={onSubmit}
              className="grid grid-cols-2 gap-y-3 gap-x-2"
            >
              {canEditDob && (
                <div>
                  <div className="mb-3">
                    <Label htmlFor="dob">DOB</Label>
                  </div>
                  <div className="w-full">
                    <input
                      id="dob"
                      name="dob"
                      type="date"
                      ref={register}
                      className="hover:border-gray-500 rounded p-3 w-full"
                    />
                  </div>
                </div>
              )}
              {canEditSex && (
                <div>
                  <div className="w-full">
                    <Dropdown
                      name="sex"
                      label="Sex"
                      control={control}
                      options={sexOptions}
                    />
                  </div>
                </div>
              )}
              {canEditHeight && (
                <div className="col-span-2">
                  <div className="mb-3">
                    <Label htmlFor="height">Height (cm)</Label>
                  </div>
                  <div className="">
                    <div className="w-full">
                      <Input
                        name="height"
                        type="number"
                        step={0.1}
                        min={90}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              )}
              {canEditPregnancyStatus && (
                <div className="col-span-2">
                  <div className="w-full">
                    <Dropdown
                      name="pregnancyStatus"
                      label="Pregnancy Status"
                      control={control}
                      options={pregnancyStatusOptions}
                    />
                  </div>
                </div>
              )}

              <div className="col-span-2">
                <Label htmlFor="undergoingWeightMaintenance">
                  Maintenance Programme
                </Label>
                <div className="pt-2">
                  <Checkbox
                    label=""
                    disabled={!canEditUndergoingWeightMaintenance}
                    name="undergoingWeightMaintenance"
                    ref={register}
                  />
                </div>
              </div>

              <div className="border-b border-gray-300" />
              {isStructuredClinicalDataEnabled ? (
                <div className="col-span-2 flex flex-col space-y-8">
                  {canEditMedicalConditions && (
                    <>
                      <div className="col-span-2 flex flex-col">
                        <div className="flex justify-between">
                          <h2 className="text-xl font-semibold text-gray-900 mb-2">
                            Conditions
                          </h2>
                          <div className="flex space-x-2">
                            <div>
                              <span className="font-bold">C </span>
                              Current
                            </div>
                            <div>
                              <span className="font-bold text-gray-500">
                                P{' '}
                              </span>
                              Previous
                            </div>
                          </div>
                        </div>
                        <div className="py-2 space-y-2">
                          {conditionsFieldArray.fields.map((c, idx) => {
                            setValue(
                              `conditions[${idx}].snomedConditionId`,
                              c.snomedConditionId,
                            );

                            return (
                              <ConditionCard
                                key={c.snomedConditionId}
                                snomedConditionId={c.snomedConditionId}
                                conditionName={c.name}
                                idx={idx}
                                formRegister={register}
                                isCurrentValue={c.current}
                                noteValue={c.note}
                                deleteEntry={() =>
                                  conditionsFieldArray.remove(idx)
                                }
                                isVisible={show}
                              />
                            );
                          })}
                        </div>

                        <div className="pb-2">
                          <div
                            className={`${
                              displayConditionsSearch ? 'hidden' : ''
                            }`}
                          >
                            <Button
                              fullWidth
                              onClick={() => setDisplayConditionsSearch(true)}
                              variant="outline"
                            >
                              Add a condition
                            </Button>
                          </div>
                          {displayConditionsSearch && (
                            <AsyncSelect
                              isClearable
                              autoFocus
                              components={{ Control }}
                              className="dropdown"
                              classNamePrefix="dd"
                              key="conditions"
                              placeholder="Type something here"
                              loadOptions={loadConditionOptions}
                              cacheOptions
                              value={null}
                              onChange={(e) => {
                                if (!e) {
                                  return;
                                }

                                conditionsFieldArray.append({
                                  snomedConditionId: e.value,
                                  name: e.name,
                                  note: '',
                                  current: true,
                                });
                                setDisplayConditionsSearch(false);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            watch('legacyConditions', '') === '' ||
                            watch('legacyConditions') === null
                              ? 'hidden'
                              : ''
                          }
                        >
                          <div className="pb-2">
                            <div className="flex flex-row justify-between">
                              <div className="flex items-baseline">
                                <h3 className="heading-sm inline">
                                  Conditions (legacy)
                                </h3>
                                <div>
                                  <FaQuestionCircle
                                    data-tooltip-id="legacy-conditions-tooltip"
                                    className="inline"
                                  />
                                  <Tooltip id="legacy-conditions-tooltip">
                                    <div className="max-w-sm">
                                      Legacy conditions should be added to the
                                      patient record using structured
                                      conditions. Once that&apos;s done you can
                                      delete this section.
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setIsDeleteLegacyConditionsConfirmationModalOpen(
                                    true,
                                  );
                                }}
                                type="button"
                                className="text-red-500 underline"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <div className="text-gray-400 py-2">
                            {medicalProfile.existingConditions}
                          </div>
                          <Modal
                            show={isDeleteLegacyConditionsConfirmationModalOpen}
                            onClose={() =>
                              setIsDeleteLegacyConditionsConfirmationModalOpen(
                                false,
                              )
                            }
                          >
                            <div className="bg-white p-8 rounded-lg space-y-4">
                              <div className="font-semibold">
                                Are you sure you want to delete?
                              </div>
                              <div>
                                Once you delete legacy conditions this action
                                cannot be undone.
                              </div>
                              <div className="flex flex-row space-x-4 justify-between">
                                <Button
                                  onClick={() => {
                                    setIsDeleteLegacyConditionsConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  variant="outline"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    setValue('legacyConditions', '', {
                                      shouldDirty: true,
                                      shouldValidate: true,
                                    });
                                    setIsDeleteLegacyConditionsConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  color="danger"
                                >
                                  Confirm
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div className="border-b border-gray-300" />
                    </>
                  )}
                  {canEditCurrentMedications && (
                    <>
                      <div className="col-span-2 flex flex-col">
                        <div className="flex justify-between">
                          <h2 className="text-xl font-semibold text-gray-900 mb-2">
                            Medications
                          </h2>
                        </div>
                        <div className="py-2 space-y-2">
                          {medicationsFieldArray.fields.map((m, idx) => {
                            setValue(
                              `medications[${idx}].snomedMedicationId`,
                              m.snomedMedicationId,
                            );

                            return (
                              <MedicationCard
                                key={m.snomedMedicationId}
                                snomedMedicationId={m.snomedMedicationId}
                                medicationName={m.name}
                                idx={idx}
                                formRegister={register}
                                noteValue={m.note}
                                deleteEntry={() =>
                                  medicationsFieldArray.remove(idx)
                                }
                                isVisible={show}
                              />
                            );
                          })}
                        </div>

                        <div className="pb-2">
                          <div
                            className={`${
                              displayMedicationsSearch ? 'hidden' : ''
                            }`}
                          >
                            <Button
                              fullWidth
                              onClick={() => setDisplayMedicationsSearch(true)}
                              variant="outline"
                            >
                              Add a medication
                            </Button>
                          </div>
                          {displayMedicationsSearch && (
                            <AsyncSelect
                              isClearable
                              autoFocus
                              components={{ Control }}
                              className="dropdown"
                              classNamePrefix="dd"
                              key="medications"
                              placeholder="Type something here"
                              loadOptions={loadMedicationOptions}
                              cacheOptions
                              value={null}
                              onChange={(e) => {
                                if (!e) {
                                  return;
                                }

                                medicationsFieldArray.append({
                                  snomedMedicationId: e.value,
                                  name: e.name,
                                  note: '',
                                });
                                setDisplayMedicationsSearch(false);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            watch('medicationUsages', '') === '' ||
                            watch('medicationUsages') === null
                              ? 'hidden'
                              : ''
                          }
                        >
                          <div className="pb-2">
                            <div className="flex flex-row justify-between">
                              <div className="flex items-baseline">
                                <h3 className="heading-sm inline">
                                  Medications (legacy)
                                </h3>
                                <div>
                                  <FaQuestionCircle
                                    data-tooltip-id="legacy-medications-tooltip"
                                    className="inline"
                                  />
                                  <Tooltip id="legacy-medications-tooltip">
                                    <div className="max-w-sm">
                                      Legacy medications should be added to the
                                      patient record using structured
                                      medications. Once that&apos;s done you can
                                      delete this section.
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setIsDeleteLegacyMedicationsConfirmationModalOpen(
                                    true,
                                  );
                                }}
                                type="button"
                                className="text-red-500 underline"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <div className="text-gray-400 py-2">
                            {medicalProfile.currentMedications}
                          </div>
                          <Modal
                            show={
                              isDeleteLegacyMedicationsConfirmationModalOpen
                            }
                            onClose={() =>
                              setIsDeleteLegacyMedicationsConfirmationModalOpen(
                                false,
                              )
                            }
                          >
                            <div className="bg-white p-8 rounded-lg space-y-4">
                              <div className="font-semibold">
                                Are you sure you want to delete?
                              </div>
                              <div>
                                Once you delete legacy medications this action
                                cannot be undone.
                              </div>
                              <div className="flex flex-row space-x-4 justify-between">
                                <Button
                                  onClick={() => {
                                    setIsDeleteLegacyMedicationsConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  variant="outline"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    setValue('medicationUsages', '', {
                                      shouldDirty: true,
                                      shouldValidate: true,
                                    });
                                    setIsDeleteLegacyMedicationsConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  color="danger"
                                >
                                  Confirm
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div className="border-b border-gray-300" />
                    </>
                  )}
                  {canEditAllergies && (
                    <>
                      <div className="col-span-2 flex flex-col">
                        <div className="flex justify-between">
                          <h2 className="text-xl font-semibold text-gray-900 mb-2">
                            Allergies
                          </h2>
                        </div>
                        <div className="py-2 space-y-2">
                          {allergiesFieldArray.fields.map((a, idx) => {
                            setValue(
                              `allergies[${idx}].snomedAllergyId`,
                              a.snomedAllergyId,
                            );

                            return (
                              <AllergyCard
                                key={a.snomedAllergyId}
                                snomedAllergyId={a.snomedAllergyId}
                                allergyName={a.name}
                                idx={idx}
                                formRegister={register}
                                noteValue={a.note}
                                deleteEntry={() =>
                                  allergiesFieldArray.remove(idx)
                                }
                                isVisible={show}
                              />
                            );
                          })}
                        </div>

                        <div className="pb-2">
                          <div
                            className={`${
                              displayAllergiesSearch ? 'hidden' : ''
                            }`}
                          >
                            <Button
                              fullWidth
                              onClick={() => setDisplayAllergiesSearch(true)}
                              variant="outline"
                            >
                              Add an allergy
                            </Button>
                          </div>

                          {displayAllergiesSearch && (
                            <AsyncSelect
                              isClearable
                              autoFocus
                              components={{ Control }}
                              className="dropdown"
                              classNamePrefix="dd"
                              key="allergies"
                              placeholder="Type something here"
                              loadOptions={loadAllergyOptions}
                              cacheOptions
                              value={null}
                              onChange={(e) => {
                                if (!e) {
                                  return;
                                }

                                allergiesFieldArray.append({
                                  snomedAllergyId: e.value,
                                  name: e.name,
                                  note: '',
                                });

                                setDisplayAllergiesSearch(false);
                              }}
                            />
                          )}
                        </div>
                        <div
                          className={
                            watch('allergyIntolerances', '') === '' ||
                            watch('allergyIntolerances') === null
                              ? 'hidden'
                              : ''
                          }
                        >
                          <div className="pb-2">
                            <div className="flex flex-row justify-between">
                              <div className="flex items-baseline">
                                <h3 className="heading-sm inline">
                                  Allergies (legacy)
                                </h3>
                                <div>
                                  <FaQuestionCircle
                                    data-tooltip-id="legacy-allergies-tooltip"
                                    className="inline"
                                  />
                                  <Tooltip id="legacy-allergies-tooltip">
                                    <div className="max-w-sm">
                                      Legacy allergies should be added to the
                                      patient record using structured allergies.
                                      Once that&apos;s done you can delete this
                                      section.
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  setIsDeleteLegacyAllergiesConfirmationModalOpen(
                                    true,
                                  );
                                }}
                                type="button"
                                className="text-red-500 underline"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <div className="text-gray-400 py-2">
                            {medicalProfile.allergies}
                          </div>
                          <Modal
                            show={isDeleteLegacyAllergiesConfirmationModalOpen}
                            onClose={() =>
                              setIsDeleteLegacyAllergiesConfirmationModalOpen(
                                false,
                              )
                            }
                          >
                            <div className="bg-white p-8 rounded-lg space-y-4">
                              <div className="font-semibold">
                                Are you sure you want to delete?
                              </div>
                              <div>
                                Once you delete legacy allergies this action
                                cannot be undone.
                              </div>
                              <div className="flex flex-row space-x-4 justify-between">
                                <Button
                                  onClick={() => {
                                    setIsDeleteLegacyAllergiesConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  variant="outline"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    setValue('allergyIntolerances', '', {
                                      shouldDirty: true,
                                      shouldValidate: true,
                                    });
                                    setIsDeleteLegacyAllergiesConfirmationModalOpen(
                                      false,
                                    );
                                  }}
                                  fullWidth
                                  color="danger"
                                >
                                  Confirm
                                </Button>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div className="border-b border-gray-300" />
                    </>
                  )}
                  <div className="flex flex-shrink-0 justify-between col-span-2 space-x-4 bottom-0 sticky pt-6 pb-6 bg-slate-100">
                    <Button
                      variant="outline"
                      color="danger"
                      onClick={() => {
                        reset();
                        onClose();
                      }}
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      loading={loading}
                      disabled={!isDirty}
                      fullWidth
                    >
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {canEditMedicalConditions && (
                    <div className="col-span-2">
                      <TextArea
                        label="Medical conditions"
                        name="legacyConditions"
                        placeholder={'None listed'}
                        defaultValue={watch('legacyConditions') ?? undefined}
                        ref={register}
                        maxLength={500}
                        rows={4}
                        resize
                      />
                    </div>
                  )}
                  {canEditCurrentMedications && (
                    <div className="col-span-2">
                      <TextArea
                        label="Current medications"
                        name="medicationUsages"
                        placeholder="None listed"
                        defaultValue={watch('medicationUsages') ?? undefined}
                        ref={register}
                        maxLength={500}
                        rows={4}
                        resize
                      />
                    </div>
                  )}
                  {canEditAllergies && (
                    <div className="col-span-2">
                      <TextArea
                        label="Allergies"
                        name="allergyIntolerances"
                        placeholder="None listed"
                        defaultValue={watch('allergyIntolerances') ?? undefined}
                        maxLength={500}
                        ref={register}
                        rows={4}
                        resize
                      />
                    </div>
                  )}
                  <div className="flex justify-between col-span-2 space-x-4">
                    <Button
                      variant="outline"
                      color="danger"
                      onClick={() => {
                        reset();
                        onClose();
                      }}
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      loading={loading}
                      disabled={!isDirty}
                      fullWidth
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}
            </form>
          </section>
        </div>
      </div>
    </Drawer>
  );
};
