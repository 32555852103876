import { ConsultationStatus } from 'graphql/types';

type ConsultationStatusOptions = {
  [key in ConsultationStatus]: string;
};

export const consultationStatusDescriptions: ConsultationStatusOptions = {
  AWAITING_SURVEY: 'The patient is required to complete their quiz to proceed',
  AWAITING_PAYMENT:
    'The patient has completed the quiz and is required to pay for the consultation',
  AWAITING_DOCTOR: 'The consultation must be assigned to a practitioner',
  DOCTOR_ASSIGNED:
    'The consultation requires an action from the practitioner, such as answering a question or prescribing a treatment',
  AWAITING_ANSWERS:
    'The practitioner has asked a question and requires a response from the patient',
  DOCTOR_COMPLETED:
    'The practitioner has prescribed a treatment and the patient must confirm and pay to procced, please direct patients to their profile',
  CUSTOMER_COMPLETED:
    'The patient has completed this consultation by paying for their treatment. This consultation is now considered closed and no more actions are possible',
  ORDER_COMPLETED:
    'The patient has received their first order on the treatment. This consultation is now considered closed and no more actions are possible',
  AWAITING_RESULTS: `An admin must input results from the patient's pathology test to proceed`,
  AWAITING_VERIFY_ID: `The patient's identity must be verified to proceed`,
  CANCELED:
    'The consultation has been cancelled and cannot continue, patients will be offered a message to reach out to support for further help. This consultation is now considered closed and no more actions are possible',
};
