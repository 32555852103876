import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Dropdown, Option } from 'components/dropdown';
import { Modal } from 'components/modal';
import { TextArea } from 'components/text-area';
import {
  PausePurchaseMutation,
  PausePurchaseMutationVariables,
  PurchasePauseReason,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import { FaPause } from 'react-icons/fa';

type Props = {
  disabled: boolean;
  purchaseId: string;
};

const pausePurchaseDropdownOptions: Option<PurchasePauseReason>[] = [
  { label: 'Poor customer service', value: 'CUSTOMER_SERVICE' },
  {
    label: 'Poor delivery experience',
    value: 'DELIVERY_EXPERIENCE',
  },
  {
    label: 'Patient has reached goal weight',
    value: 'GOAL_REACHED',
  },
  {
    label: 'Poor health coach experience',
    value: 'HEALTH_COACHING_EXPERIENCE',
  },
  {
    label: 'Patient has leftover medication',
    value: 'LEFTOVER',
  },
  {
    label: 'External medical advice or change in medical history',
    value: 'MEDICAL_STATUS_CHANGES',
  },
  {
    label: 'Poor medical support experience',
    value: 'MEDICAL_SUPPORT_EXPERIENCE',
  },
  { label: 'Not seeing expected results', value: 'NO_RESULTS' },
  {
    label: 'Non-medication inclusions do not meet patient needs',
    value: 'PROGRAM_NOT_MEETING_NEEDS',
  },
  {
    label: 'Patient is experiencing side effects',
    value: 'SIDE_EFFECTS',
  },
  {
    label: 'Financial barrier but satisfied with the program',
    value: 'TOO_EXPENSIVE',
  },
  { label: 'Temporary pause (e.g. travelling, surgery)', value: 'TRAVELLING' },
  {
    label: 'Desired medication not available',
    value: 'DESIRED_MEDICATION_UNAVAILABLE',
  },
  {
    label: 'Not meeting maintenance requirements',
    value: 'MAINTENANCE_PROGRAM_NON_COMPLIANCE',
  },
  { label: 'Other', value: 'OTHER' },
];

export const PauseButton: React.FC<Props> = ({ disabled, purchaseId }) => {
  const showNotification = useNotifications();
  const [showModal, setShowModal] = React.useState(false);
  const form = useForm<{ description: string; reason: PurchasePauseReason }>();
  const formReasonWatcher = form.watch('reason');
  const [pausePurchase, { loading }] = useMutation<
    PausePurchaseMutation,
    PausePurchaseMutationVariables
  >(
    gql`
      mutation pausePurchase($input: PausePurchaseInput!) {
        pausePurchase(input: $input) {
          purchase {
            id
            status
            syncGroups {
              id
              status
            }
            contexts {
              id
              status
              pausedAt
              pauseDescription
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setShowModal(false);
        showNotification({
          type: 'success',
          message: 'Purchase paused',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Unable to pause purchase',
        });
      },
    },
  );

  return (
    <>
      <button
        type="button"
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border bg-primary-100 border-primary-500 text-primary text-sm leading-5 font-medium rounded hover:bg-primary-200 transition duration-150 ease-in-out"
        disabled={disabled || loading}
        onClick={() => setShowModal(true)}
      >
        <FaPause className="mr-2" />
        Pause Purchase
      </button>

      {showModal && (
        <Modal show onClose={() => setShowModal(false)} isAutoOverflow={false}>
          <div className="bg-white shadow rounded px-4 py-5 mr-auto ml-auto space-y-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Pause purchase
            </h3>
            <form
              className="space-y-4"
              onSubmit={form.handleSubmit(async (vals) => {
                await pausePurchase({
                  variables: {
                    input: {
                      purchaseId: purchaseId,
                      pauseReason: vals.reason,
                      pauseDescription: vals.description,
                    },
                  },
                });
              })}
            >
              <p>Are you sure you want to pause this purchase?</p>

              <Dropdown
                name="reason"
                label="Reason*"
                placeholder="Provide the reason this purchase needs to be paused."
                errorMessage={form.errors.reason?.message}
                control={form.control}
                rules={{ required: 'Pause reason is required' }}
                options={pausePurchaseDropdownOptions}
              />
              <TextArea
                name="description"
                label="description"
                placeholder="Please elaborate why this purchase needs to be paused."
                rows={4}
                errorMessage={form.errors.description?.message}
                ref={form.register({
                  required:
                    formReasonWatcher === 'OTHER' &&
                    'Description is required when pause reason is "Other"',
                })}
              />
              <div className="flex space-x-4 bg-gray-100">
                <Button
                  fullWidth
                  variant="outline"
                  disabled={loading}
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>

                <Button
                  fullWidth
                  color="danger"
                  type="submit"
                  disabled={!!form.errors.description?.message}
                  loading={loading}
                >
                  Pause
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};
