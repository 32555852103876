import { ObjectiveStatus } from '../../graphql/types';
import { Colors } from '../../utils/misc';
import { Tag } from '../tag';

const objectiveStatusColor: Record<ObjectiveStatus, Colors> = {
  ACTIVE: 'green',
  INACTIVE: 'gray',
  DRAFT: 'orange',
};

const statusDisplay: Record<ObjectiveStatus, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DRAFT: 'Draft',
};

export const ObjectiveStatusTag = (props: { status: ObjectiveStatus }) => (
  <div>
    <Tag size="small" color={objectiveStatusColor[props.status]}>
      {statusDisplay[props.status]}
    </Tag>
  </div>
);
