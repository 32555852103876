import { UserRole, UserWhereInput } from 'graphql/types';
import { Input } from 'components/react-hook-form-6/input';
import React from 'react';
import { useForm } from 'react-hook-form-6';
import { useDebouncedEffect } from 'utils/use-debounce-effect';
import { useUrlQuery } from 'utils/use-url-query';
import { useChangeUrl } from 'utils/user-change-url';

const FILTER_CHANGE_DEBOUNCE_MS = 500;

type UserSearchUrlQueryParams = {
  search: string;
};

const useGetNativeQuery = (): UserSearchUrlQueryParams => {
  const urlQuery = useUrlQuery();
  return {
    search: urlQuery.get('search') ?? '',
  };
};

export const UserFilter = (): React.ReactElement => {
  const query = useGetNativeQuery();
  const changeUrl = useChangeUrl();

  const { register, watch } = useForm<UserSearchUrlQueryParams>({
    mode: 'onChange',
    defaultValues: query,
  });

  const search = watch().search;

  useDebouncedEffect(
    () => {
      changeUrl({
        params: {
          search,
          pageIndex: 0,
        },
      });
    },
    FILTER_CHANGE_DEBOUNCE_MS,
    [search],
  );

  return (
    <form
      onSubmit={(e): void => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="flex space-x-2 mb-8">
        <div className="w-full">
          <Input
            ref={register}
            name="search"
            autoFocus={true}
            label="Search"
            placeholder="Search by Email, Name or ID"
          />
        </div>
      </div>
    </form>
  );
};

export const useBuildUserQueryFilter = (
  userRole: UserRole | undefined,
): UserWhereInput => {
  const { search } = useGetNativeQuery();

  return React.useMemo(() => {
    const [firstName, lastName] = search?.trim()?.split(' ') ?? ['', ''];
    const excludeTestUsers = {
      email: {
        not: { startsWith: 'synthetic_test_user' },
      },
    };

    const whereInput: UserWhereInput = {
      AND: [excludeTestUsers],
    };

    if (search) {
      whereInput?.AND?.push({
        OR: [
          { email: { contains: search, mode: 'insensitive' } },
          {
            firstName: { contains: search, mode: 'insensitive' },
          },
          {
            lastName: { contains: search, mode: 'insensitive' },
          },
          { id: { equals: search } },
          {
            AND: [
              { firstName: { contains: firstName, mode: 'insensitive' } },
              { lastName: { contains: lastName, mode: 'insensitive' } },
            ],
          },
        ],
      });
    }

    if (userRole) {
      whereInput?.AND?.push({ role: { equals: userRole } });
    }

    return whereInput;
  }, [search, userRole]);
};
