import { consultationStatuses } from 'utils/statuses';
import { Button } from 'components/button';
import {
  ConsultationStatus,
  UpdateConsultationStatusMutation,
  UpdateConsultationStatusMutationVariables,
} from 'graphql/types';
import { ReactElement, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNotifications } from 'notifications';
import clsx from 'clsx';
import { FaCheck } from 'react-icons/fa';
import { upperSnakeCaseToCapitalCase } from 'utils/misc';
import { consultationStatusDescriptions } from './consultation-status-descriptions';

/**
 * Controls whether or not a status will be selectable in the UI.
 *
 * @note we disallow awaiting_answers as it has been manually set incorrectly in the past as
 * a workaround to clear a consultation from the clinicians review page when waiting for the patient to
 * action something asynchronously.
 * @see https://www.notion.so/eucalyptus-vc/Handling-patients-incorrectly-put-into-Awaiting-Answers-68c68c4f21954bc1ae9b9729add002d0
 * @note order_completed and customer_completed are disabled because they are terminating statuses that we don't allow admins to update to.
 * admins have incorrectly been setting these statuses for consults, causing patients to not be able to either access their consultation or confirm their order.
 */
const DISABLED_STATUSES: ConsultationStatus[] = [
  'AWAITING_ANSWERS',
  'ORDER_COMPLETED',
  'CUSTOMER_COMPLETED',
];

export const ConsultationStatusSelectList = ({
  status,
  consultationId,
  onUpdate,
}: {
  consultationId: string;
  status: ConsultationStatus;
  onUpdate: () => void;
}): ReactElement => {
  const showNotification = useNotifications();
  const [selected, setSelected] = useState(status);

  const [updateConsultationStatus, { loading: loadingMutation }] = useMutation<
    UpdateConsultationStatusMutation,
    UpdateConsultationStatusMutationVariables
  >(
    gql`
      mutation UpdateConsultationStatus(
        $consultationId: ID!
        $consultationStatus: ConsultationStatus!
      ) {
        updateConsultationStatus(
          consultationId: $consultationId
          consultationStatus: $consultationStatus
        ) {
          consultation {
            id
            status
            logs {
              id
              event
              status
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Consultation updated',
        });
        onUpdate();
      },
      onError: () =>
        showNotification({
          type: 'error',
          message: 'Unable to update the consultation status',
        }),
    },
  );

  const handleUpdate = async (): Promise<void> => {
    await updateConsultationStatus({
      variables: {
        consultationId: consultationId,
        consultationStatus: selected,
      },
    });
  };

  return (
    <div className="bg-background-light rounded p-4 space-y-4">
      <h3 className="heading-md mb-2">Select Consultation Status</h3>
      <div className="flex flex-col">
        {consultationStatuses.map((s) => (
          <ConsultationStatusOption
            key={s}
            status={s}
            onClick={(): void => setSelected(s)}
            selected={s === selected}
            disabled={DISABLED_STATUSES.includes(s)}
          />
        ))}
      </div>
      <Button
        fullWidth
        disabled={selected === status}
        onClick={handleUpdate}
        loading={loadingMutation}
      >
        Update
      </Button>
    </div>
  );
};

const ConsultationStatusOption = ({
  status,
  onClick,
  selected,
  disabled,
}: {
  status: ConsultationStatus;
  onClick: () => void;
  children?: ReactElement;
  selected: boolean;
  disabled?: boolean;
}): ReactElement => {
  return (
    <button
      className={clsx('bg-white flex p-4 shadow mb-4 space-x-2 text-left', {
        'hover:bg-gray-100': !(disabled || selected),
        'bg-gray-200': selected,
        'text-gray-300 bg-gray-500': disabled,
      })}
      onClick={onClick}
      disabled={disabled || selected}
    >
      {selected && <FaCheck className="my-auto mr-2" />}
      <div className="space-y-1">
        <div className="font-medium">{upperSnakeCaseToCapitalCase(status)}</div>
        <div
          className={clsx({
            'text-gray-700': !disabled,
            'text-gray-300': disabled,
          })}
        >
          {consultationStatusDescriptions[status]}
        </div>
      </div>
    </button>
  );
};
