import { ReactElement } from 'react';
import { CellProps, Column } from 'react-table';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from 'components/table';
import { formatDateAndTime } from 'utils/misc';
import { Customer } from './types';
import { FaInfoCircle } from 'react-icons/fa';
import { isBefore } from 'date-fns';

const authorCollectionDate = new Date('2024-07-01');

type AddressHistory =
  | NonNullable<NonNullable<NonNullable<Customer>['address']>['history']>
  | NonNullable<
      NonNullable<NonNullable<Customer>['residentialAddress']>['history']
    >;

const AuthorCell = ({
  value,
}: CellProps<AddressHistory[number], AddressHistory[number]>): ReactElement => {
  const { author, createdAt } = value;
  if (!author || !createdAt) {
    return <p className=" text-gray-600">-</p>;
  }

  if (isBefore(new Date(createdAt), authorCollectionDate)) {
    return <p className="text-xs text-gray-600">Not Collected</p>;
  }

  const name = (author.clinicianName ?? author.fullName).toLowerCase();
  let role = 'Customer';
  switch (author.role) {
    case 'PHARMACY':
      role = 'Pharmacy';
      break;
    case 'SUPERADMIN':
      role = 'Admin';
      break;
    case 'HEALTH_COACH':
      role = 'Health Coach';
      break;
    case 'DOCTOR':
      role = 'Prescriber';
      break;
    case 'CUSTOMER':
    default:
      role = 'patient';
  }

  return (
    <div className="text-xs capitalize">
      {name} ({role})
    </div>
  );
};

const DateCell = ({
  value,
}: {
  value: string | null | undefined;
}): ReactElement => {
  if (!value) {
    return <p className="text-xs text-gray-600">-</p>;
  }
  return <div className="text-xs">{formatDateAndTime(value)}</div>;
};

const ValueCell = ({
  value,
}: {
  value: string | null | undefined;
}): ReactElement => {
  if (!value) {
    return <p className=" text-gray-600">-</p>;
  }
  return <div>{value}</div>;
};

const columns: Column<AddressHistory[number]>[] = [
  {
    Header: 'Address',
    accessor: 'formattedString',
    Cell: ValueCell,
    disableSortBy: true,
    className: 'w-2/4',
  },
  {
    Header: 'Changed At',
    accessor: 'createdAt',
    Cell: DateCell,
    disableSortBy: true,
    className: 'w-1/4',
  },
  {
    Header: 'Changed By',
    accessor: (row): AddressHistory[number] => row,
    Cell: AuthorCell,
    className: 'w-1/4',
  },
];

type AddressHistoryDrawerProps = {
  history: AddressHistory;
  show: boolean;
  onClose: () => void;
};

export const AddressHistoryDrawer: React.FC<AddressHistoryDrawerProps> = ({
  show,
  onClose,
  history,
}) => {
  const tableInstance = usePaginatingSortingTable({
    columns,
    data: history,
    pageNumber: 1,
  });

  const showDisclaimer = history.some((h) =>
    isBefore(new Date(h.createdAt), authorCollectionDate),
  );

  return (
    <Drawer format="default" show={show} onClose={onClose}>
      <div className="h-full bg-gray-200 overflow-scroll space-y-5">
        <div className="flex justify-between mx-5 top-0 p-5">
          <h1 className="text-2xl font-semibold text-gray-900">
            Address History
          </h1>
          <div>
            <Button fullWidth size="small" variant="outline" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
        {showDisclaimer && (
          <div className="flex mx-5 space-x-2 px-5 py-3 rounded items-center bg-yellow-100 border border-yellow-500">
            <FaInfoCircle className="text-yellow-700 " />
            <p className="text-gray-700 text-xs">
              &quot;Changed By&quot; values were collected from July 2024; refer
              to the Patient Audit Logs for Author information prior to this
              date.
            </p>
          </div>
        )}
        <section className="flex flex-col mx-5 bg-gray-200">
          <div className="flex flex-col pb-5">
            {history.length === 0 && (
              <p className="text-gray-700 w-full">No Address History</p>
            )}
            {history.length > 0 && (
              <Table tableInstance={tableInstance}>
                <TableHead />
                <TableBody>
                  <>
                    {tableInstance.page?.map((row) => {
                      tableInstance.prepareRow(row);
                      return (
                        <TableRow row={row} key={row.id}>
                          <>
                            {row.cells.map((cell) => (
                              <TableCell
                                key={`${cell.row.original.id}-${cell.column.id}`}
                                cell={cell}
                              />
                            ))}
                          </>
                        </TableRow>
                      );
                    })}
                  </>
                </TableBody>
              </Table>
            )}
          </div>
        </section>
      </div>
    </Drawer>
  );
};
