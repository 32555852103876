import { formatDate } from 'utils/misc';
import { Customer } from './types';
import { Tag } from 'components/tag';
import { SmallTableHeadings } from 'components/small-table/small-table-headings';
import { Copyable } from 'components/copyable';
import { useState } from 'react';

const columns = ['ID', 'Asset', 'From', 'To', 'Status'];

export const AccessSection: React.FC<{
  accesses: NonNullable<Customer['accesses']>;
}> = ({ accesses }) => {
  const [max, setMax] = useState(5);

  return (
    <div className="border-t border-gray-200 bg-white shadow overflow-hidden rounded">
      <div className="px-4 py-5 border-b border-gray-200 space-y-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Access
          <span className="ml-2 text-gray-500">{accesses.length}</span>
        </h3>
      </div>
      <div>
        <div className="-mt-2 overflow-x-auto text-xs">
          <div className="pt-2 min-w-full">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <SmallTableHeadings headings={columns} />
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {accesses.length === 0 && (
                  <tr>
                    <td className="px-4 py-2 text-gray-500 text-sm">
                      Patient has no accesses
                    </td>
                  </tr>
                )}
                {accesses.slice(0, max).map((access) => (
                  <tr key={access.id}>
                    <td className="pl-6">
                      <Copyable text={access.id}>
                        {(copied) => (
                          <pre className="cursor-pointer leading-5 text-gray-500">
                            {copied ? 'Copied' : access.id.slice(-6)}
                          </pre>
                        )}
                      </Copyable>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.asset.name}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.__typename === 'TimedAccess'
                        ? formatDate(new Date(access.start))
                        : '-'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      {access.__typename === 'TimedAccess'
                        ? formatDate(new Date(access.end))
                        : '-'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <Tag
                        size="small"
                        shape="box"
                        color={access.active ? 'indigo' : 'gray'}
                      >
                        {access.active ? 'Active' : 'Expired'}
                      </Tag>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {accesses.length > max && (
            <button
              onClick={() => setMax((m) => m + 5)}
              className="min-w-full bg-gray-100 text-gray-500 font-medium text-sm py-2"
            >
              View more
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
