import type { ReactElement } from 'react';
import clsx from 'clsx';

type Option<T extends string> = {
  label: string;
  value: T;
  description?: string;
};

type ToggleTabsProps<T extends string> = {
  options: Option<T>[];
  onOptionSelect: (value: T) => void;
  value?: string;
};

export const ToggleTabs = <T extends string>({
  options,
  onOptionSelect,
  value,
}: ToggleTabsProps<T>): ReactElement => {
  return (
    <div className="flex">
      {options.map(({ value: v, label, description }) => (
        <button
          key={v}
          onClick={() => onOptionSelect(v)}
          className={clsx(
            'w-full p-2 first:rounded-l last:rounded-r border border-r-0 last:border-r border-blue-300',
            { 'bg-blue-50': value === v },
          )}
        >
          <span className="text-sm font-semibold text-blue-500">{label}</span>
          {description && (
            <>
              <br />
              <span className="text-sm">{description}</span>
            </>
          )}
        </button>
      ))}
    </div>
  );
};
