import { gql, useMutation } from '@apollo/client';
import { useHasPermissions } from 'components/permissions';
import {
  SwitchCandidatesFragment,
  PurchaseStatus,
  SwitchCustomerPurchaseMutation,
  SwitchCustomerPurchaseMutationVariables,
} from 'graphql/types';
import React from 'react';
import { OfferingSelectModal } from 'components/flexi-plans/offering-select-modal';
import { useNotifications } from 'notifications';
import { useHistory } from 'react-router-dom';
import { FaArrowsAltH } from 'react-icons/fa';
import { sequenceNodeFragment } from 'components/sequence-editor/node';

const requiredPermissions = 'SWITCH_CUSTOMER_OFFERINGS';

export const switchCandidatesFragment = gql`
  fragment SwitchCandidates on Offering {
    switchCandidates {
      id
      friendlyName
      sequenceSets {
        id
        name
        sequences {
          id
          internalName
          nodes {
            ...SequenceEditorSequenceNode
          }
        }
      }
    }
  }
  ${sequenceNodeFragment}
`;

const switchCustomerPurchaseMutation = gql`
  mutation SwitchCustomerPurchase($input: SwitchCustomerPurchaseInput!) {
    switchCustomerPurchase(input: $input) {
      oldPurchase {
        id
        status
        supersededBy {
          id
        }
      }
      newPurchase {
        id
        purchaseGroup {
          id
          customerPurchases {
            id
          }
        }
        customer {
          id
          purchases {
            id
          }
        }
      }
    }
  }
`;

type Props = {
  purchaseId: string;
  purchaseStatus: PurchaseStatus;
  switchCandidates?: SwitchCandidatesFragment['switchCandidates'];
  selectedSequences?: {
    sequenceId: string;
    isDisabled: boolean;
  }[];
  selectedSequencesIds?: string[];
};

export const SwitchButton: React.FC<Props> = ({
  purchaseId,
  purchaseStatus,
  switchCandidates,
  selectedSequences,
}) => {
  const history = useHistory();
  const showNotification = useNotifications();
  const [showModal, setShowModal] = React.useState(false);
  const hasSwitchOfferingsPerms = useHasPermissions([requiredPermissions]);
  const hasNoSwitchCandidates =
    !switchCandidates || switchCandidates.length < 1;
  const invalidStatus = ['COMPLETED', 'CANCELLED'].includes(purchaseStatus);
  const isDisabled =
    invalidStatus || hasNoSwitchCandidates || !hasSwitchOfferingsPerms;

  let tooltipText;
  switch (isDisabled) {
    case !hasSwitchOfferingsPerms:
      tooltipText = `This action can only be performed by users with the ${requiredPermissions} permissions`;
      break;
    case invalidStatus:
      tooltipText = 'Only active or paused purchases can be switched';
      break;
    case hasNoSwitchCandidates:
      tooltipText = 'There are no switch candidates for the purchase';
  }

  const [switchCustomerPurchase, { loading: switchPurchaseLoading }] =
    useMutation<
      SwitchCustomerPurchaseMutation,
      SwitchCustomerPurchaseMutationVariables
    >(switchCustomerPurchaseMutation, {
      onCompleted: () => {
        setShowModal(false);
        showNotification({
          type: 'success',
          message: 'Customer purchase switched successfully.',
        });
      },
      onError: () =>
        showNotification({
          type: 'error',
          message: 'Error switching customer purchase.',
        }),
    });

  const handleOfferingSelected = async (selection: {
    offeringId: string;
    sequenceSelections: { sequenceSetId: string; sequenceId: string }[];
  }) => {
    const result = await switchCustomerPurchase({
      variables: {
        input: {
          purchaseId,
          offeringSelection: {
            offeringId: selection.offeringId,
            sequenceSelections: selection.sequenceSelections,
          },
        },
      },
    });
    const newPurchaseId = result.data?.switchCustomerPurchase?.newPurchase?.id;
    if (newPurchaseId) {
      history.push(newPurchaseId);
    }
  };

  return (
    <>
      <button
        type="button"
        title={tooltipText}
        className="inline-flex disabled:cursor-not-allowed disabled:opacity-25 items-center px-4 py-2 border bg-primary-100 border-primary-500 text-primary text-sm leading-5 font-medium rounded hover:bg-primary-200 transition duration-150 ease-in-out"
        disabled={isDisabled}
        onClick={(): void => setShowModal(true)}
      >
        <FaArrowsAltH className="mr-2" />
        Switch Purchase
      </button>

      {showModal && (
        <OfferingSelectModal
          ctaText="Switch Offering"
          onClose={() => setShowModal(false)}
          offerings={switchCandidates}
          onOfferingSelected={handleOfferingSelected}
          selection={{
            sequences: selectedSequences,
          }}
          loading={false}
          updating={switchPurchaseLoading}
        />
      )}
    </>
  );
};
