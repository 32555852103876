import React from 'react';
import { buildRoute } from 'utils/routes';
import { Link } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';

export const ChatSection = ({
  customer,
}: {
  customer: {
    id: string;
    chatThreads?:
      | Array<{
          __typename?: 'ChatThread';
          id: string;
        }>
      | null
      | undefined;
  };
}): React.ReactElement | null => {
  const { client } = useChatContext();

  if (!customer.chatThreads?.length || !client) {
    return null;
  }

  return (
    <div className="overflow-hidden rounded shadow bg-white px-4 py-5 border-b border-gray-200 flex justify-between items-center">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Health Coach Chat
      </h3>
      <Link to={buildRoute.chat(customer.id)}>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded text-gray-700 bg-white focus:outline-none focus:ring-blue focus:border-blue-300 active:text-gray-800 active:bg-white transition duration-150 ease-in-out"
        >
          View chat
        </button>
      </Link>
    </div>
  );
};
