import { gql, useMutation } from '@apollo/client';
import {
  CreatePractitionerBookingWindowOverrideMutation,
  CreatePractitionerBookingWindowOverrideMutationVariables,
  ProblemType,
  QueueSummaryWindowFragment,
  RemovePractitionerBookingWindowOverrideMutation,
  RemovePractitionerBookingWindowOverrideMutationVariables,
} from 'graphql/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form-6';

type UseQueueManagerProps = {
  practitionerBookingWindows: QueueSummaryWindowFragment[];
  problemType: ProblemType;
  onComplete: () => Promise<void>;
};

export const useQueueManager = ({
  practitionerBookingWindows,
  problemType,
  onComplete,
}: UseQueueManagerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const overridesToWindowIdsMap = new Map<
    NonNullable<QueueSummaryWindowFragment['override']>,
    QueueSummaryWindowFragment[]
  >();
  const formDefaultValues: { [key: string]: boolean } = {};

  for (const window of practitionerBookingWindows) {
    formDefaultValues[window.id] = !window.override?.id;
    if (window.override) {
      if (!overridesToWindowIdsMap.get(window.override)) {
        overridesToWindowIdsMap.set(window.override, []);
      }
      overridesToWindowIdsMap.get(window.override)?.push(window);
    }
  }

  const { watch, register, handleSubmit, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  const [createPractitionerBookingWindowOverrideMutation] = useMutation<
    CreatePractitionerBookingWindowOverrideMutation,
    CreatePractitionerBookingWindowOverrideMutationVariables
  >(gql`
    mutation CreatePractitionerBookingWindowOverride(
      $startAt: DateTime!
      $endAt: DateTime!
      $problemType: ProblemType!
    ) {
      createPractitionerBookingWindowOverride(
        input: { problemType: $problemType, startAt: $startAt, endAt: $endAt }
      ) {
        overrides {
          id
          problemType
          createdAt
          endAt
        }
      }
    }
  `);

  const [removePractitionerBookingWindowOverrideMutation] = useMutation<
    RemovePractitionerBookingWindowOverrideMutation,
    RemovePractitionerBookingWindowOverrideMutationVariables
  >(gql`
    mutation RemovePractitionerBookingWindowOverride($overrideId: ID!) {
      removePractitionerBookingWindowOverride(overrideId: $overrideId) {
        success
      }
    }
  `);

  const submit = handleSubmit((formData) => {
    setIsLoading(true);
    const overridesToRemove: string[] = [];
    for (const [override, windows] of overridesToWindowIdsMap) {
      const allWindowsAreBlocked = windows.reduce((result, window) => {
        return result && formData[window.id] === false;
      }, true);
      if (!allWindowsAreBlocked) {
        overridesToRemove.push(override.id);
      }
    }

    const overridesToAdd = [];
    let consecutiveBlockedWindowsWithoutOverride = [];
    for (const window of practitionerBookingWindows) {
      const isWindowBlocked = formData[window.id] === false;

      if (
        !(window.override && !overridesToRemove.includes(window.override.id)) &&
        isWindowBlocked
      ) {
        consecutiveBlockedWindowsWithoutOverride.push(window);
        if (
          practitionerBookingWindows.indexOf(window) !==
          practitionerBookingWindows.length - 1
        ) {
          continue;
        }
      }

      if (consecutiveBlockedWindowsWithoutOverride.length) {
        const overrideToAdd = {
          startAt: consecutiveBlockedWindowsWithoutOverride[0].startAt,
          endAt:
            consecutiveBlockedWindowsWithoutOverride[
              consecutiveBlockedWindowsWithoutOverride.length - 1
            ].endAt,
        };
        overridesToAdd.push(overrideToAdd);
        consecutiveBlockedWindowsWithoutOverride = [];
      }
    }

    (async () => {
      setIsLoading(true);
      const removalPromises = [];
      for (const overrideId of overridesToRemove) {
        removalPromises.push(
          removePractitionerBookingWindowOverrideMutation({
            variables: {
              overrideId,
            },
          }),
        );
      }
      if (removalPromises.length) {
        await Promise.all(removalPromises);
      }

      const creationPromises = [];
      for (const override of overridesToAdd) {
        creationPromises.push(
          createPractitionerBookingWindowOverrideMutation({
            variables: {
              startAt: override.startAt,
              endAt: override.endAt,
              problemType,
            },
          }),
        );
      }
      if (creationPromises.length) {
        await Promise.all(creationPromises);
      }
      await onComplete();
      setIsLoading(false);
    })();
  });

  return {
    loading: isLoading,
    submit,
    watch,
    register,
    reset: () => {
      reset(formDefaultValues);
    },
  };
};
