import { gql, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { trackedMetricLabels } from 'components/objectives/labels';
import { CreateObjectiveModal } from 'components/objectives/create-objective-modal';
import { ObjectiveStatusTag } from 'components/objectives/objective-status-tag';
import { useHasPermissions } from 'components/permissions';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/table';
import {
  CreateObjectiveMutation,
  CreateObjectiveMutationVariables,
  Objective,
  ObjectivesPageQuery,
  ObjectiveStatus,
  ObjectiveTrackedMetric,
} from 'graphql/types';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import { buildRoute } from 'utils/routes';
import { useNotifications } from 'notifications';

const columns: Column<Objective>[] = [
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (c) => <ObjectiveStatusTag status={c.value} />,
    className: 'w-1/12',
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (c) => <span className="font-semibold">{c.value}</span>,
    className: 'w-1/6',
  },
  {
    Header: 'Tracked Metrics',
    accessor: 'trackedMetrics',
    Cell: (c) => (
      <ul className="my-2">
        {c.value.map((tm: ObjectiveTrackedMetric, i) => (
          <li key={`${tm}-${i}`}>{trackedMetricLabels.get(tm) ?? tm}</li>
        ))}
      </ul>
    ),
    className: 'w-1/6',
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: (c) => <p className="my-2">{c.value}</p>,
  },
];

const objectiveStatusOrder = new Map<ObjectiveStatus, number>([
  ['ACTIVE', 0],
  ['DRAFT', 1],
  ['INACTIVE', 2],
]);

const ObjectivesPage = (): React.ReactElement => {
  const showNotification = useNotifications();
  const history = useHistory();
  const canEditObjectives = useHasPermissions(['EDIT_OBJECTIVES']);
  const [showModal, setShowModal] = useState(false);

  const allObjectivesQuery = gql`
    query ObjectivesPage {
      objectives {
        id
        name
        description
        status
        trackedMetrics
        createdAt
        updatedAt
      }
    }
  `;

  const query = useQuery<ObjectivesPageQuery>(allObjectivesQuery);

  const [createObjective] = useMutation<
    CreateObjectiveMutation,
    CreateObjectiveMutationVariables
  >(
    gql`
      mutation CreateObjective($input: CreateObjectiveInput!) {
        createObjective(input: $input) {
          objective {
            id
            name
            description
            status
            trackedMetrics
            createdAt
            updatedAt
          }
        }
      }
    `,
    {
      update(cache, { data }) {
        const newObjectiveFromResponse = data?.createObjective?.objective;
        const existingObjectives = cache.readQuery<ObjectivesPageQuery>({
          query: allObjectivesQuery,
        });

        if (!newObjectiveFromResponse || !existingObjectives) {
          return;
        }

        cache.writeQuery({
          query: allObjectivesQuery,
          data: {
            objectives: [
              ...(existingObjectives.objectives ?? []),
              newObjectiveFromResponse,
            ],
          },
        });
      },
    },
  );

  const sortedObjectives = useMemo(() => {
    return [...(query.data?.objectives || [])].sort(
      (a: Objective, b: Objective) =>
        (objectiveStatusOrder.get(a.status) ?? Number.MAX_SAFE_INTEGER) -
        (objectiveStatusOrder.get(b.status) ?? Number.MAX_SAFE_INTEGER),
    );
  }, [query.data]);

  const table = useTable({
    columns,
    data: sortedObjectives,
  });

  return (
    <>
      <div className="flex flex-col space-y-8">
        <div className="flex justify-end">
          <div>
            <Button
              disabled={!canEditObjectives}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Create Objective
            </Button>
          </div>
        </div>
        {query.loading ? (
          <div className="w-full flex justify-center p-5">
            <Loading />
          </div>
        ) : (
          <Table tableInstance={table}>
            <TableHead />
            <TableBody>
              {table.rows.map((row) => {
                table.prepareRow(row);
                return (
                  <TableRow key={row.id} row={row}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={cell.column.id}
                        cell={cell}
                        onClick={() => {
                          history.push(
                            buildRoute.objective(cell.row.original.id),
                          );
                        }}
                      />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
      {showModal && (
        <CreateObjectiveModal
          onClose={() => {
            setShowModal(false);
          }}
          onCreateObjective={async (formData) => {
            await createObjective({ variables: { input: formData } });
            showNotification({
              type: 'success',
              message: `Created new objective "${formData.name}"`,
            });
          }}
        />
      )}
    </>
  );
};

export default ObjectivesPage;
