import React, { useCallback, useState } from 'react';
import { GetSequencesListQuery } from '../../graphql/types';
import { routes } from '../../utils/routes';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  usePaginatingSortingTable,
} from '../../components/table';
import { getProblemTypeColor, getProblemTypeEmoji } from '../../utils/misc';
import { Tag } from '../../components/tag';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { gql, useSuspenseQuery } from '@apollo/client';
import { Cell, CellProps, Column } from 'react-table';
import { ProtectedButton } from 'components/button';
import { Input } from 'components/input';

export const SequencesPage = (): React.ReactElement => {
  const history = useHistory();
  const [search, setSearch] = useState('');

  const { data, error } = useSuspenseQuery<GetSequencesListQuery>(gql`
    query getSequencesList {
      sequences(problemTypes: []) {
        id
        internalName
        friendlyName
        problemTypes
        status
      }
    }
  `);

  const goToSequence = useCallback(
    (cell: Cell<Sequence, unknown>) => {
      if (cell.column.id !== 'selection') {
        history.push(
          generatePath(routes.sequence, {
            sequenceId: cell.row.original.id,
          }),
        );
      }
    },
    [history],
  );

  const tableInstance = usePaginatingSortingTable({
    columns,
    data:
      data?.sequences?.filter((s) => {
        if (search === '') {
          return true;
        }
        if (s.friendlyName.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        if (s.internalName.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      }) ?? [],
    pageNumber: 1,
    disableSortBy: true,
  });

  if (error) {
    return (
      <div>
        Error - Unable to load sequences, please contact #help-technology.
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-start gap-4 mb-8">
        <div className="flex-1">
          <Input
            name="search"
            autoFocus
            placeholder="Search..."
            value={search}
            onChange={(evt) => setSearch(evt.currentTarget.value)}
          />
        </div>
        <Link to={routes.createSequence}>
          <ProtectedButton
            requiredPermissions={['EDIT_OFFERINGS']}
            fullWidth
            variant="outline"
          >
            Create New
          </ProtectedButton>
        </Link>
      </div>
      <div>
        <Table tableInstance={tableInstance}>
          <TableHead />
          <TableBody>
            {tableInstance.page.map(
              (row) => (
                tableInstance.prepareRow(row),
                (
                  <TableRow row={row} key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell
                        key={`${cell.row.original.id}-${cell.column.id}`}
                        cell={cell}
                        onClick={goToSequence}
                      />
                    ))}
                  </TableRow>
                )
              ),
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

type Sequence = NonNullable<GetSequencesListQuery['sequences']>[number];

const ProblemTypesCell = (
  cell: CellProps<Sequence, Sequence['problemTypes']>,
): React.ReactElement => (
  <div className="mt-1">
    {cell.value?.map((problemType, i) => (
      <div key={`${i}_${problemType}`} className="inline-block mr-1 mb-1">
        <Tag size="small" color={getProblemTypeColor(problemType)}>
          <span role="img" className="pr-1">
            {problemType && getProblemTypeEmoji(problemType)}
          </span>
          {problemType?.replaceAll('_', ' ')}
        </Tag>
      </div>
    ))}
  </div>
);

function StatusCell(
  cell: CellProps<Sequence, Sequence['status']>,
): React.ReactElement {
  if (cell.value === 'AVAILABLE') {
    return (
      <Tag size="small" color="green">
        Available
      </Tag>
    );
  }
  if (cell.value === 'DEPRECATED') {
    return (
      <Tag size="small" color="red">
        Deprecated
      </Tag>
    );
  }
  if (cell.value === 'PHASING_OUT') {
    return (
      <Tag size="small" color="yellow">
        Phasing Out
      </Tag>
    );
  }
  if (cell.value === 'UNAVAILABLE') {
    return (
      <Tag size="small" color="blue">
        Unavailable
      </Tag>
    );
  }
  if (cell.value === 'DRAFT') {
    return (
      <Tag size="small" color="gray">
        Draft
      </Tag>
    );
  }
  return <div>{cell.value}</div>;
}

const columns: Column<Sequence>[] = [
  {
    Header: 'Sequence Name',
    accessor: 'internalName',
    Cell: (c) => c.value,
    className: 'w-2/6',
  },
  {
    Header: 'Patient Facing Friendly Name',
    accessor: 'friendlyName',
    Cell: (c) => c.value,
    className: 'w-2/6',
  },
  {
    Header: 'Problem Types',
    accessor: 'problemTypes',
    Cell: ProblemTypesCell,
    className: 'w-1/6',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: StatusCell,
    className: 'w-1/6',
  },
];
