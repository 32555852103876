import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import {
  ResetCustomerPasswordMutation,
  ResetCustomerPasswordMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import React from 'react';

export const ResetPasswordModal: React.FC<{
  customerId: string;
  email: string;
  show: boolean;
  onClose: () => void;
  onResetEmailSent: () => void;
}> = ({ customerId, show, onClose, email, onResetEmailSent }) => {
  const showNotification = useNotifications();
  const [resetCustomerPassword, { loading }] = useMutation<
    ResetCustomerPasswordMutation,
    ResetCustomerPasswordMutationVariables
  >(
    gql`
      mutation ResetCustomerPassword($id: String!) {
        resetCustomerPassword(id: $id)
      }
    `,
    {
      variables: { id: customerId },
      onCompleted(data) {
        if (data.resetCustomerPassword) {
          onClose();
          onResetEmailSent();
          showNotification({
            type: 'success',
            message: 'Reset password email sent',
          });
        }
      },
      refetchQueries: [
        {
          query: gql`
            query AuditLogs(
              $targetId: String!
              $targetType: AuditLogTargetType!
            ) {
              auditLogs(
                orderBy: { createdAt: asc }
                where: {
                  targetId: { equals: $targetId }
                  targetType: { equals: $targetType }
                }
              ) {
                action
                createdAt
                details
                id
                targetId
                targetType
                user {
                  id
                  role
                  lastName
                  firstName
                  fullName
                }
              }
            }
          `,
          variables: {
            targetId: customerId,
            targetType: 'USER',
          },
        },
      ],
    },
  );

  return (
    <Modal show={show} onClose={onClose} width="w-96">
      <div className="bg-white rounded p-4 space-y-4 w-full">
        <h1 className="text-lg leading-6 font-medium text-gray-900">
          Confirm password reset
        </h1>
        <p>This will send a reset password link to:</p>
        <p>{email}</p>
        <div className="flex flew-row justify-end w-full">
          <Button
            fullWidth
            type="button"
            loading={loading}
            onClick={() => resetCustomerPassword()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
