import clsx from 'clsx';
import type { Maybe, TimedObservation } from 'graphql/types';
import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { ReactComponent as Delta } from '../assets/delta.svg';
import WeightGraph from './weight-graph';
import './weight-table-styles.css';
import { GraphTableTabs } from '../graph/graph-table-tabs';
import { formatDate, formatDateTimeRange } from '../../utils/misc';

interface Weight {
  initialWeightValueKg: Maybe<number>;
  currentWeightValueKg: Maybe<number>;
  initialWeightTrackedDate: Maybe<string>;
  mostRecentWeightTrackedDate: Maybe<string>;
  trackingCountPerWeek: Maybe<number>;
  trackingCountTotal: Maybe<number>;
  timedObservations: Maybe<
    {
      id: string;
      timestamp: string;
      weightValue?: Maybe<number>;
      waistValue?: Maybe<number>;
    }[]
  >;
}

interface WeightObservationsProps {
  weightObservations: Weight;
  currentMilestone?: {
    completionKg: number;
    order: number;
    isGoalWeight: boolean;
  };
}

const getWeightDelta = (
  initial: number,
  current: Maybe<number>,
): { delta: number; status: 'gain' | 'loss' | 'none' } => {
  if (!current) {
    return {
      delta: 0,
      status: 'none',
    };
  }
  const rawDelta = current - initial;

  let status: 'gain' | 'loss' | 'none' = 'none';
  if (rawDelta < 0) {
    status = 'loss';
  }

  if (rawDelta > 0) {
    status = 'gain';
  }

  return {
    delta: Math.round(Math.abs(rawDelta * 100)) / 100,
    status,
  };
};

export const WeightObservations = ({
  weightObservations,
  currentMilestone,
}: WeightObservationsProps): ReactElement => {
  const [selectedView, setSelectedView] = React.useState<'graph' | 'table'>(
    'graph',
  );

  const {
    initialWeightTrackedDate,
    mostRecentWeightTrackedDate,
    currentWeightValueKg,
    initialWeightValueKg,
    trackingCountPerWeek,
    trackingCountTotal,
    timedObservations,
  } = weightObservations;

  const data = useMemo(
    () =>
      timedObservations && timedObservations.length > 0
        ? [...timedObservations].reverse()
        : [],
    [timedObservations],
  );

  const weightDataPoints = useMemo(
    () =>
      data.reduce(
        (acc: { timestamp: Date; value: number }[], curr: TimedObservation) => {
          if (curr.weightValue) {
            return [
              ...acc,
              { timestamp: new Date(curr.timestamp), value: curr.weightValue },
            ];
          }

          return acc;
        },
        [],
      ),
    [data],
  );

  const weightDelta = initialWeightValueKg
    ? getWeightDelta(initialWeightValueKg, currentWeightValueKg)
    : null;

  return (
    <div className="flex flex-col space-y-4 px-2">
      <div className="sticky top-0 bg-white bg-opacity-90">
        <div className="flex justify-between mt-2 mb-4">
          <div className="flex flex-col items-start">
            <div className="text-sm">
              <span className="font-semibold">{'Last tracked'}</span>
            </div>
            <div className="text-sm">
              <span className="font-normal">
                {mostRecentWeightTrackedDate
                  ? formatDate(mostRecentWeightTrackedDate)
                  : '―'}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div className="text-sm">
              <span className="font-semibold">{'Times tracked'}</span>
            </div>
            <div className="text-sm">
              <span className="font-normal">
                {initialWeightTrackedDate &&
                  mostRecentWeightTrackedDate &&
                  `${trackingCountTotal} (in ${formatDateTimeRange(
                    new Date(initialWeightTrackedDate),
                    new Date(mostRecentWeightTrackedDate),
                  )})`}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div className="text-sm">
              <span className="font-semibold">{'Avg. frequency'}</span>
            </div>
            <div className="text-sm">
              <span className="font-normal">
                {trackingCountPerWeek
                  ? `${trackingCountPerWeek} times per week`
                  : // eslint-disable-next-line
                    '―'}
              </span>
            </div>
          </div>
        </div>
        <GraphTableTabs selectedTab={selectedView} onSelect={setSelectedView} />
      </div>
      {selectedView === 'table' && (
        <div>
          {data.length === 0 && (
            <div className="text-sm">
              <span className="text-slate-600 px-2">
                {'No tracked entries yet'}
              </span>
            </div>
          )}

          {data.length > 0 && (
            <table className="w-full table-auto font-normal text-left weight-table text-sm">
              <thead>
                <tr>
                  <th className="font-normal">{'Date & time'}</th>
                  <th className="font-normal text-center">{'Weight (kg)'}</th>
                  <th className="font-normal text-center">{'Waist (cm)'}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d: TimedObservation, i) => (
                  <tr
                    key={d.id}
                    className={clsx(i % 2 === 0 ? 'bg-slate-200' : 'bg-white')}
                  >
                    <td>{formatDate(d.timestamp)}</td>
                    <td
                      className={clsx(
                        'text-center',
                        !d.weightValue && 'text-slate-600',
                      )}
                    >
                      {d.weightValue ?? '―'}
                    </td>
                    <td
                      className={clsx(
                        'text-center',
                        !d.waistValue && 'text-slate-600',
                      )}
                    >
                      {d.waistValue ?? '―'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
      {selectedView === 'graph' && (
        <div className="flex flex-col">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <div className="text-xs">{'Weight change to date'}</div>
              {weightDelta && (
                <div
                  className={clsx('flex items-center space-x-1', {
                    'text-red-500': weightDelta.status === 'gain',
                    'text-green-500': weightDelta.status === 'loss',
                    'text-slate-500': weightDelta.status === 'none',
                  })}
                >
                  {weightDelta.status !== 'none' && (
                    <Delta
                      className={clsx('mr-1 transform', {
                        'rotate-180': weightDelta.status === 'gain',
                      })}
                    />
                  )}

                  <div className="text-xl font-semibold">
                    <span>{weightDelta.delta}</span>
                  </div>
                  <div className="text-base font-semibold">
                    <span className="font-light">{'kg'}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center space-x-1">
                <div className="text-sm">
                  <span className="font-light">{'Starting:'}</span>
                </div>
                <div className="text-base font-semibold">
                  {initialWeightValueKg ?? '―'}
                </div>
                <div className="text-sm">
                  <span className="font-light">{'kg'}</span>
                </div>
              </div>
              <div className="flex items-center space-x-1">
                <div className="text-sm">
                  <span className="font-light">{'Current:'}</span>
                </div>
                <div className="text-base font-semibold">
                  {currentWeightValueKg ?? '―'}
                </div>
                <div className="text-sm">
                  <span className="font-light">{'kg'}</span>
                </div>
              </div>
            </div>
          </div>
          {weightDataPoints.length > 0 && (
            <div className="pt-2">
              <WeightGraph
                data={weightDataPoints}
                currentMilestone={currentMilestone}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
