import React from 'react';
import { ConsignmentFilter } from './consignment-filter';

const Consignments = (): React.ReactElement => {
  return (
    <>
      <ConsignmentFilter />
    </>
  );
};

export default Consignments;
