import { orderInfoFragment } from '../../../fragments';
import { gql } from '@apollo/client';
import { noteRowFragment } from 'components/notes/notes-table';
import { addNoteModalConsultationFragment } from 'components/notes/add-note/add-note-modal';
import { customerCriticalNoteDocument } from 'components/notes/critical-note/critical-note-card';
import { MedicalRecord } from '../medical-record/medical-record';
import ConsignmentsSection from '../consignmentsSection';

export const customerQuery = gql`
  query Customer(
    $where: UserWhereUniqueInput!
    $membershipEnabled: Boolean!
    $customerId: ID!
  ) {
    customer: user(where: $where) {
      id
      firstName
      lastName
      fullName
      preferredName
      legalFirstName
      legalLastName
      createdAt
      updatedAt
      phone
      email
      defaultPaymentGateway
      birthday
      canDeleteUser
      ...CustomerCriticalNote
      ...ConsignmentsSection
      conditions {
        id
        problemType
        canCreateReviewConsultation
        reopenableConsultation {
          id
        }
        phoneCalls {
          id
          answeredAt
        }
      }
      chatThreads {
        id
      }
      individualHealthcareIdentifier {
        id
        ihi
        status
        recordStatus
        updatedAt
      }
      medicalProfile {
        id
        patientConditions {
          id
          note
          current
          snomedCondition {
            id
            name
          }
        }
        patientMedications {
          id
          note
          snomedMedication {
            id
            name
          }
        }
        patientAllergies {
          id
          note
          snomedAllergy {
            id
            name
          }
        }
        dob
        existingConditions
        allergies
        currentMedications
        height
        weight
        sex
        age
        bmi
        pregnancyStatus
        requiresClinicalReview
        undergoingWeightMaintenance
        ...MedicalRecord
      }
      medicalRecordHistory {
        id
        field
        currentValue
        previousValue
        createdAt
        updatedAt
        updatedBy {
          id
          role
          roles {
            id
            value
          }
          fullName
          clinicianName
        }
      }
      tracker {
        id
        weightLoss {
          id
          bmiProgress {
            id
            initial
            mostRecent
            difference
            deltaSentiment
          }
          waistProgress {
            id
            initial
            mostRecent
            difference
            deltaSentiment
          }
          weightProgress {
            id
            initial
            mostRecent
            difference
            deltaSentiment
          }
          currentWeightValueKg
          mostRecentWeightTrackedDate
          initialWeightValueKg
          initialWeightTrackedDate
          trackingCountPerWeek
          trackingCountTotal
          timedObservations {
            id
            timestamp
            weightValue
            waistValue
          }
          weightGoal {
            id
            bmi
            weightKg
            feedbackType
            milestones {
              id
              status
              order
              currentPercentageComplete
              completionKg
              achievedAt
            }
          }
        }
      }
      consultations(orderBy: { createdAt: desc }) {
        id
        status
        createdAt
        completedAt
        isApproved
        stage
        purchasePrompt {
          id
        }
        type
        prescribedSequences {
          id
          status
          createdAt
          sequenceContext {
            id
            mermaid
            purchase {
              id
            }
          }
          consultation {
            id
            customer {
              id
            }
          }
          sequence {
            id
            internalName
          }
        }
        doctor {
          id
          firstName
          lastName
          fullName
          clinicianName
        }
        logs {
          id
          updatedAt
          updatedBy {
            id
            email
            firstName
            lastName
            fullName
            role
          }
          data
          event
          status
        }
        phoneCalls {
          id
          status
        }
        reviewReason

        ...AddNoteModalConsultation
      }
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        prefecture
        municipality
        townArea
        deliveryInstructions
        company
        building
        history {
          id
          createdAt
          formattedString
          author {
            id
            role
            fullName
            clinicianName
          }
        }
      }
      residentialAddress {
        id
        line1
        line2
        city
        postalCode
        prefecture
        municipality
        townArea
        state
        country
        company
        building
        history {
          id
          createdAt
          formattedString
          author {
            id
            role
            fullName
            clinicianName
          }
        }
      }
      verification {
        id
        name
        data
        sub
      }
      treatments(orderBy: { updatedAt: desc }) {
        id
        status
        type
        canReschedule
        supersededByFlexiPlans
        nextPayment
        refillsLeft
        repeats
        createdAt
        updatedAt
        canRefill
        canPause
        canReview
        canRevertReview
        plan {
          id
          name
          amount
          repeats
        }
        product {
          id
          name
          description
          units
          usage
          plan {
            id
            name
            amount
            repeats
          }
        }
        orders(orderBy: { createdAt: desc }) {
          ...orderInfo
        }
        otcSchedules {
          id
          isActive
          cadence
          quantity
          createdAt
          updatedAt
          product {
            id
            name
          }
          treatmentId
        }
        jobs(orderBy: { scheduledAt: desc }) {
          id
          updatedAt
          type
          scheduledAt
          doneAt
          message
          source
          failed
          createdAt
          data
          order {
            id
          }
          isReApprove
          canceled
          canReschedule
        }
        logs(orderBy: { updatedAt: asc }) {
          id
          updatedAt
          updatedBy {
            id
            email
            firstName
            lastName
            fullName
            role
          }
          data
          event
          status
        }
        experimentPaymentPlan {
          id
          paymentPlan
          discountPercentage
          createdAt
          charge {
            id
            amount
            updatedAt
            status
          }
          numberOfOrdersRemaining
          version
        }
        experimentAvailablePaymentPlans {
          paymentPlan
          discountPercentage
          cadence
        }
        scriptId
        script {
          id
          expiresAt
        }
        customerStatedTreatmentStartedAt
        consultation {
          id
        }
      }
      memberships @include(if: $membershipEnabled) {
        userId
        id
        type
        membershipTerms(take: 1, orderBy: { createdAt: desc }) {
          id
          isValid
          status
          subscriptionStatus
          startDate
          endDate
          membershipFee
          jobs(orderBy: { scheduledAt: desc }) {
            id
            updatedAt
            type
            scheduledAt
            doneAt
            message
            source
            createdAt
            data
            order {
              id
            }
            isReApprove
            failed
            canceled
          }
        }
      }
      quizApplications(purpose: INITIAL, hasNoConsultation: true) {
        id
        canBeDeleted
        quizId
        customerId
        problemType
        purpose
        createdAt
        submittedAt
        isNonEnglish
        languageDisplayName
        archivedAt
        responses {
          question {
            id
            options {
              value(languageCode: EN_AU)
              originalValue: value
            }
            shortcode
            title(languageCode: EN_AU)
            originalTitle: title
          }
          response {
            answers(languageCode: EN_AU) {
              value
              imageUrl
            }
            originalAnswers: answers {
              value
              imageUrl
            }
          }
        }
      }
      purchaseGroups {
        id
        unmetActivationRequirements
        pricingSessionId
        createdAt
        customerPurchases {
          id
          status
          cancelReason
          contexts {
            id
            status
            addon
            prescribedSequences {
              id
            }
            sequence {
              id
              friendlyName
            }
          }
          offering {
            id
            friendlyName
            advertisedName
            problemTypes
          }
        }
      }
      ordersWithoutTreatment: orders(where: { treatmentId: null }) {
        ...orderInfo
      }
      accesses {
        id
        createdAt
        active
        asset {
          id
          name
        }
        ... on TimedAccess {
          start
          end
        }
      }
      ... @defer {
        referralUrl
      }
    }
    filteredNotes(input: { customerId: $customerId }) {
      ...noteRow
    }
  }

  ${orderInfoFragment}
  ${noteRowFragment}
  ${addNoteModalConsultationFragment}
  ${customerCriticalNoteDocument}
  ${MedicalRecord.fragment}
  ${ConsignmentsSection.fragment}
`;
