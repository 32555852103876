import { Control, Controller, RegisterOptions } from 'react-hook-form-6';
import { ValueType } from 'react-select';
import Creatable from 'react-select/creatable';
import { Label } from './label';
import { InputError } from './input-error';

interface Option<T = string> {
  value: T;
  label: string;
}

interface Props<T = string> {
  name: string;
  control: Control;
  options: Array<Option<T>>;
  rules?: RegisterOptions;
  label: string;
  tooltip: Parameters<typeof Label>[0]['tooltip'];
  errorMessage?: string;
}

export function CreatableDropdown(props: Props) {
  return (
    <div>
      <div className="mb-3">
        <Label htmlFor={props.name} tooltip={props.tooltip}>
          {props.label}
        </Label>
      </div>
      <Controller
        defaultValue={[]}
        rules={props.rules}
        render={({ value, onChange, onBlur, name, ref }) => (
          <Creatable
            isMulti={true}
            inputId={props.name}
            options={props.options}
            className="dropdown"
            classNamePrefix="dd"
            innerRef={ref}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={(v: ValueType<{ value: string }, true>): void => {
              if (v === null || v === undefined) {
                onChange([]);
              } else {
                onChange(v);
              }
            }}
          />
        )}
        name={props.name}
        control={props.control}
      />

      <InputError>{props.errorMessage}</InputError>
    </div>
  );
}
