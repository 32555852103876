import {
  FaRegCopy,
  FaPlus,
  FaRegTrashAlt,
  FaInfoCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import { ReactComponent as DraggableIcon } from 'components/assets/draggable.svg';
import { ReactComponent as OpenInNewIcon } from 'components/assets/open-in-new.svg';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import type { Document } from '@contentful/rich-text-types';
import { AiOutlineEdit } from 'react-icons/ai';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  HealthCoachingFlowTemplateQuery,
  HealthCoachingFlowTemplateQueryVariables,
  TimeInterval,
  UpdateHealthCoachingFlowTemplateMutation,
  UpdateHealthCoachingFlowTemplateMutationVariables,
  UpdateHealthCoachingFlowTemplateStatusMutation,
  UpdateHealthCoachingFlowTemplateStatusMutationVariables,
  UpsertHealthCoachingFlowNodeTemplateInput,
  UpsertHealthCoachingFlowTemplateFragment,
} from 'graphql/types';
import { Prompt, useParams } from 'react-router-dom';
import { Loading } from 'components/loading';
import { v4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Input } from 'components/react-hook-form-6/input';
import { useForm } from 'react-hook-form-6';
import { requiredValidation } from 'utils/form-validation';
import { Modal } from 'components/modal';
import { ReactComponent as CloseIcon } from 'components/assets/close.svg';
import { Button } from 'components/button';
import { DraggableProvided } from 'react-beautiful-dnd';
import { isEqual, capitalize } from 'lodash';
import { useNotifications } from 'notifications';
import { Tag } from 'components/tag';
import { GridTransition } from 'components/grid-transition';
import clsx from 'clsx';
import { createClient as createContentfulClient } from 'contentful';

const defaultNewContentfulItemContent = '-';

const contentfulClient = createContentfulClient({
  space: '1p96mcpzbbp4',
  accessToken: 'Q5v1ntos5fC3Nufprz7qkiIhl6nRtycqGtUiKEe2m9I',
});

type FlowNodeTemplate = NonNullable<
  NonNullable<UpsertHealthCoachingFlowTemplateFragment>['nodeTemplates']
>[0];

type FlowMessageNodeTemplate = Extract<
  NonNullable<
    NonNullable<UpsertHealthCoachingFlowTemplateFragment>['nodeTemplates']
  >[0],
  { __typename?: 'HealthCoachingFlowMessageNodeTemplate' }
>;

type FlowMessageNodeTemplateContentfulMessage =
  FlowMessageNodeTemplate['contentfulMessages'][0];

const validateMessagePlaceholdersAndVariables = (
  message: string,
): { hasUnfilledVariables: boolean; hasInvalidPlaceholders: boolean } => {
  if (!message)
    return { hasUnfilledVariables: false, hasInvalidPlaceholders: false };

  const generalPattern = /\{\{.*?\}\}/g;
  const attPattern = /\{\{att\.\w+?\}\}/;
  const varPattern = /\{\{var\.\w+?\}\}/;

  const matches = message.match(generalPattern) || [];
  const unfilledVariables = matches.filter((placeholder: string) =>
    varPattern.test(placeholder),
  );
  const invalidPlaceholders = matches.filter(
    (placeholder: string) =>
      !attPattern.test(placeholder) && !varPattern.test(placeholder),
  );

  return {
    hasUnfilledVariables: unfilledVariables.length > 0,
    hasInvalidPlaceholders: invalidPlaceholders.length > 0,
  };
};

const fetchDetailsForContentfulId = async (contentfulId: string) => {
  try {
    const { fields } = await contentfulClient.getEntry(contentfulId);

    const contentfulItemTitle =
      typeof fields['title'] === 'string'
        ? fields['title']
        : defaultNewContentfulItemContent;

    const bodyRichText = fields['bodyRichText'] as Document;

    const message =
      bodyRichText && bodyRichText.nodeType
        ? documentToHtmlString(bodyRichText)
        : defaultNewContentfulItemContent;

    const { hasUnfilledVariables, hasInvalidPlaceholders } =
      validateMessagePlaceholdersAndVariables(message);

    const isMessageValid = !hasUnfilledVariables && !hasInvalidPlaceholders;

    return {
      title: contentfulItemTitle,
      message: fields['bodyRichText'] || defaultNewContentfulItemContent,
      valid: isMessageValid,
    };
  } catch {
    return {
      title: defaultNewContentfulItemContent,
      message: defaultNewContentfulItemContent,
      valid: false,
    };
  }
};

type FlowTemplateMessageNodePlaceholderProps = {
  contentfulIds: Set<string>;
  addContentItem: (
    contentfulMessage: Omit<FlowMessageNodeTemplateContentfulMessage, 'id'>,
  ) => void;
};

function FlowTemplateMessageNodePlaceholder({
  contentfulIds,
  addContentItem,
}: FlowTemplateMessageNodePlaceholderProps) {
  const [showAddContentfulItem, setShowAddContentfulItem] = useState(false);

  return (
    <div className="rounded border border-slate-200 py-20">
      <p className="text-sm font-semibold text-center mt-1.5">
        This message doesn&apos;t contain any content
      </p>
      <p className="text-sm text-center mt-1">
        For a message to work, you will need to add at least one content item.
      </p>
      <button
        className="flex items-center justify-center mx-auto px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold mt-3 mb-1.5"
        onClick={() => setShowAddContentfulItem(true)}
      >
        <FaPlus className="mr-1 text-gray-700" size={14} />
        <span>Add content</span>
      </button>

      {showAddContentfulItem && (
        <AddOrEditContentfulItem
          contentfulIds={contentfulIds}
          saveContentfulItem={addContentItem}
          onClose={() => setShowAddContentfulItem(false)}
        />
      )}
    </div>
  );
}

type AddOrEditContentfulItemProps = {
  contentfulId?: string;
  contentfulIds: Set<string>;
  saveContentfulItem: (
    saveProps: Omit<FlowMessageNodeTemplateContentfulMessage, 'id'>,
  ) => void;
  onClose: () => void;
};

function AddOrEditContentfulItem({
  contentfulId,
  contentfulIds,
  saveContentfulItem,
  onClose,
}: AddOrEditContentfulItemProps) {
  const { register, errors, formState, trigger, handleSubmit } = useForm<{
    contentfulId: string;
  }>({
    defaultValues: {
      contentfulId,
    },
  });

  const saveContent = handleSubmit(async (data) => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    const formattedContentfulId = data.contentfulId.trim();
    const details = await fetchDetailsForContentfulId(formattedContentfulId);
    saveContentfulItem({
      contentfulId: formattedContentfulId,
      ...details,
    });
    onClose();
  });

  return (
    <Modal show onClose={onClose} width="w-[500px]">
      <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <p className="text-base uppercase font-semibold">
              {contentfulId?.length ? 'Edit content' : 'Add content'}
            </p>

            <button className="p-2" onClick={onClose}>
              <CloseIcon width={16} height={16} className="fill-black" />
            </button>
          </div>

          <Input
            name="contentfulId"
            label="Contentful ID"
            autoFocus
            ref={register({
              ...requiredValidation('contentfulId'),
              validate(v) {
                if (typeof v === 'string' && !contentfulIds.has(v)) {
                  return true;
                }
                return 'Cannot add duplicate contentful IDs to the same message node';
              },
            })}
            errorMessage={errors.contentfulId?.message}
          />

          <div className="flex justify-end gap-5">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>

            <Button loading={formState.isSubmitting} onClick={saveContent}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

type FlowTemplateMessageNodeContentfulItemProps = {
  contentfulIds: Set<string>;
  contentfulMessage: FlowMessageNodeTemplateContentfulMessage;
  updateContentItem(
    updatedContentfulMessage: Omit<
      FlowMessageNodeTemplateContentfulMessage,
      'id'
    >,
  ): void;
  deleteContentItem(): void;
  isMessageInvalid: boolean;
  index: number;
};

function FlowTemplateMessageNodeContentfulItem({
  contentfulIds,
  contentfulMessage,
  updateContentItem,
  deleteContentItem,
  isMessageInvalid,
  index,
}: FlowTemplateMessageNodeContentfulItemProps) {
  const { contentfulId, valid, message, title } = contentfulMessage;
  const [
    showContentfulItemDeletionConfirmation,
    setShowContentfulItemDeletionConfirmation,
  ] = useState(false);
  const [showEditContentfulItem, setShowEditContentfulItem] = useState(false);
  const [focusedContentfulMessage, setFocusedContentfulMessage] =
    useState<FlowMessageNodeTemplateContentfulMessage>();

  const closeContentItemEditModal = () => {
    setShowEditContentfulItem(false);
    setFocusedContentfulMessage(undefined);
  };

  const closeContentItemDeleteModal = () => {
    setShowContentfulItemDeletionConfirmation(false);
    setFocusedContentfulMessage(undefined);
  };

  const isValid = index === 0 ? !isMessageInvalid || valid : valid;

  return (
    <div className={clsx('w-full table-row', !isValid && 'bg-red-100')}>
      <div className="table-cell px-4 py-3 w-72 align-middle">
        <span className="tooltip">
          <p className="text-sm font-semibold line-clamp-1">{title}</p>
          <span className="tooltiptext text-sm p-1 rounded-md">{title}</span>
        </span>
      </div>
      <div className="table-cell px-4 py-3 text-sm align-middle">
        <div>
          {message === defaultNewContentfulItemContent ? (
            defaultNewContentfulItemContent
          ) : (
            <div
              className="line-clamp-1"
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(message),
              }}
            />
          )}
        </div>
      </div>
      <div className="table-cell px-4 py-3 text-sm w-80 ml-auto mr-6 align-middle">
        {contentfulId.length ? (
          <div className="flex items-center justify-start gap-2">
            <p
              className={clsx(
                'whitespace-nowrap overflow-hidden text-ellipsis',
                !isValid && 'font-bold',
              )}
            >
              {contentfulId}
            </p>
            {isValid && (
              <a
                href={`https://app.contentful.com/spaces/1p96mcpzbbp4/entries/${contentfulId}`}
                target="_blank"
                rel="noreferrer"
              >
                <OpenInNewIcon className="h-3 w-3 fill-slate-500" />
              </a>
            )}
          </div>
        ) : (
          defaultNewContentfulItemContent
        )}
      </div>
      <div className="table-cell px-4 py-3 w-28 ml-auto mr-0">
        <div className="flex items-center gap-2">
          <button
            className="h-8 w-8 flex items-center justify-center"
            onClick={() => {
              setFocusedContentfulMessage(contentfulMessage);
              setShowEditContentfulItem(true);
            }}
          >
            <AiOutlineEdit size={20} className="fill-slate-500" />
          </button>

          <button
            className="h-8 w-8 flex items-center justify-center"
            onClick={() => {
              setFocusedContentfulMessage(contentfulMessage);
              setShowContentfulItemDeletionConfirmation(true);
            }}
          >
            <FaRegTrashAlt size={16} className="fill-slate-500" />
          </button>
        </div>
      </div>

      {focusedContentfulMessage && showContentfulItemDeletionConfirmation && (
        <Modal show onClose={closeContentItemDeleteModal} width="w-[500px]">
          <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <p className="text-base uppercase font-semibold">
                  Delete content item?
                </p>

                <button className="p-2" onClick={closeContentItemDeleteModal}>
                  <CloseIcon width={16} height={16} className="fill-black" />
                </button>
              </div>

              <p className="text-base mb-4">
                This will delete the content item from the message node.
              </p>

              <div className="flex justify-end gap-5">
                <Button variant="outline" onClick={closeContentItemDeleteModal}>
                  Close
                </Button>

                <Button
                  color="danger"
                  onClick={() => {
                    setShowContentfulItemDeletionConfirmation(false);
                    deleteContentItem();
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {focusedContentfulMessage && showEditContentfulItem && (
        <AddOrEditContentfulItem
          contentfulId={focusedContentfulMessage.contentfulId}
          contentfulIds={contentfulIds}
          saveContentfulItem={updateContentItem}
          onClose={closeContentItemEditModal}
        />
      )}
    </div>
  );
}

type FlowTemplateMessageNodeProps = {
  index: number;
  contentfulMessages: FlowMessageNodeTemplateContentfulMessage[];
  draggableProvided: DraggableProvided;
  duplicateMessageNode: (messageNodeIndex: number) => void;
  deleteMessageNode: (messageNodeIndex: number) => void;
  addContentItem: (addProps: {
    messageNodeIndex: number;
    contentfulMessage: Omit<FlowMessageNodeTemplateContentfulMessage, 'id'>;
  }) => void;
  updateContentItem: (updateProps: {
    messageNodeIndex: number;
    itemIndex: number;
    contentfulMessage: FlowMessageNodeTemplateContentfulMessage;
  }) => void;
  deleteContentItem: (deleteProps: {
    messageNodeIndex: number;
    itemIndex: number;
  }) => void;
  contentfulIds: Set<string>;
  isMessageInvalid: boolean;
};

function FlowTemplateMessageNode({
  index,
  contentfulMessages,
  draggableProvided,
  duplicateMessageNode,
  deleteMessageNode,
  addContentItem,
  updateContentItem,
  deleteContentItem,
  contentfulIds,
  isMessageInvalid,
}: FlowTemplateMessageNodeProps) {
  const [showAddContentfulItem, setShowAddContentfulItem] = useState(false);
  const [showConfirmMessageNodeDeletion, setShowConfirmMessageNodeDeletion] =
    useState(false);

  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className="bg-white rounded border border-slate-300 overflow-hidden mb-8"
    >
      <div className="px-5 py-3 bg-slate-50 border-b border-slate-300 flex items-center">
        <div
          className="h-5 w-5 flex items-center justify-center"
          {...draggableProvided.dragHandleProps}
        >
          <DraggableIcon className="fill-slate-300 w-5 h-5" />
        </div>

        <div className="h-6 w-6 rounded-full border border-slate-300 ml-3 flex items-center justify-center">
          <span className="text-sm font-semibold">{index + 1}</span>
        </div>

        <p className="ml-2 text-base font-semibold text-slate-700 grow">
          Message node
        </p>

        <button
          className="h-10 w-10 flex items-center justify-center cursor-pointer"
          onClick={() => duplicateMessageNode(index)}
        >
          <FaRegCopy size={16} className="fill-slate-500" />
        </button>
        <button
          className="h-10 w-10 flex items-center justify-center cursor-pointer"
          onClick={() => setShowConfirmMessageNodeDeletion(true)}
        >
          <FaRegTrashAlt size={16} className="fill-slate-500" />
        </button>
      </div>
      <div className="p-6">
        {!contentfulMessages.length ? (
          <FlowTemplateMessageNodePlaceholder
            contentfulIds={contentfulIds}
            addContentItem={(contentfulMessage) =>
              addContentItem({
                messageNodeIndex: index,
                contentfulMessage,
              })
            }
          />
        ) : (
          <div>
            <div className="rounded border border-slate-200">
              <div className="w-full table">
                <div className="table-row bg-slate-50">
                  {['Name', 'Preview', 'Contentful ID', ''].map(
                    (colTitle, index) => (
                      <div
                        key={index}
                        className="table-cell uppercase text-xs font-semibold py-3 px-4 text-left"
                      >
                        {colTitle}
                      </div>
                    ),
                  )}
                </div>

                {contentfulMessages.map((cm, cmIndex) => (
                  <FlowTemplateMessageNodeContentfulItem
                    key={`cm-${index}-${cmIndex}`}
                    contentfulMessage={cm}
                    contentfulIds={contentfulIds}
                    updateContentItem={(updatedContentfulMessage) =>
                      updateContentItem({
                        messageNodeIndex: index,
                        itemIndex: cmIndex,
                        contentfulMessage: {
                          ...cm,
                          ...updatedContentfulMessage,
                        },
                      })
                    }
                    deleteContentItem={() =>
                      deleteContentItem({
                        messageNodeIndex: index,
                        itemIndex: contentfulMessages.indexOf(cm),
                      })
                    }
                    isMessageInvalid={isMessageInvalid}
                    index={index}
                  />
                ))}
              </div>
            </div>

            <button
              className="flex items-center justify-center ml-auto px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold mt-5"
              onClick={() => setShowAddContentfulItem(true)}
            >
              <FaPlus className="mr-1 text-gray-700" size={14} />
              <span>Add content variant</span>
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showConfirmMessageNodeDeletion}
        onClose={() => setShowConfirmMessageNodeDeletion(false)}
        width="w-[500px]"
      >
        <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <p className="text-base uppercase font-semibold">
                Delete message node?
              </p>

              <button
                className="p-2"
                onClick={() => setShowConfirmMessageNodeDeletion(false)}
              >
                <CloseIcon width={16} height={16} className="fill-black" />
              </button>
            </div>

            <p className="text-base mb-4">
              This will delete the message node, and all of its content items.
            </p>

            <div className="flex justify-end gap-5">
              <Button
                variant="outline"
                onClick={() => setShowConfirmMessageNodeDeletion(false)}
              >
                Close
              </Button>

              <Button
                color="danger"
                onClick={() => {
                  deleteMessageNode(index);
                  setShowConfirmMessageNodeDeletion(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {showAddContentfulItem && (
        <AddOrEditContentfulItem
          contentfulIds={contentfulIds}
          saveContentfulItem={(contentfulMessage) =>
            addContentItem({
              messageNodeIndex: index,
              contentfulMessage,
            })
          }
          onClose={() => setShowAddContentfulItem(false)}
        />
      )}
    </div>
  );
}

type FlowTemplateWaitNodeProps = {
  index: number;
  count: number;
  interval: TimeInterval;
  deleteNode: (nodeIndex: number) => void;
  draggableProvided: DraggableProvided;
  updateWaitInterval: (
    nodeIndex: number,
    interval: TimeInterval,
    count: number,
  ) => void;
};

type EditWaitIntervalProps = {
  count: number;
  saveWaitCount: (count: number) => void;
  onClose: () => void;
};

function EditWaitInterval({
  count,
  saveWaitCount,
  onClose,
}: EditWaitIntervalProps) {
  const { register, errors, formState, trigger, handleSubmit } = useForm<{
    count: number;
  }>({
    defaultValues: {
      count,
    },
  });

  const saveCount = handleSubmit(async (data) => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }

    saveWaitCount(parseInt(data.count.toString()));
    onClose();
  });

  return (
    <Modal show onClose={onClose} width="w-[500px]">
      <div className="bg-white rounded-lg p-6">
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between">
            <p className="text-base uppercase font-semibold">
              Edit wait interval
            </p>

            <button className="p-2" onClick={onClose}>
              <CloseIcon width={16} height={16} className="fill-black" />
            </button>
          </div>

          <Input
            name="count"
            type="number"
            label="Days"
            ref={register({ min: 1, max: 31, required: true })}
            errorMessage={
              errors.count
                ? 'Please enter a number between 1 and 31'
                : undefined
            }
          />

          <div className="flex justify-end gap-5">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>

            <Button loading={formState.isSubmitting} onClick={saveCount}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function FlowTemplateWaitNode({
  index,
  deleteNode,
  count,
  interval,
  draggableProvided,
  updateWaitInterval,
}: FlowTemplateWaitNodeProps) {
  const [confirmNodeDeletion, setConfirmNodeDeletion] = useState(false);
  const [showEditWaitIntervalCount, setShowEditWaitIntervalCount] =
    useState(false);

  return (
    <div
      className="bg-white rounded border border-slate-300 overflow-hidden mb-8"
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
    >
      <div className="px-5 py-3 bg-slate-50 border-b border-slate-300 flex items-center">
        <div
          className="h-5 w-5 flex items-center justify-center"
          {...draggableProvided.dragHandleProps}
        >
          <DraggableIcon className="fill-slate-300 w-5 h-5" />
        </div>
        <div className="h-6 w-6 rounded-full border border-slate-300 ml-3 flex items-center justify-center">
          <span className="text-sm font-semibold">{index + 1}</span>
        </div>
        <p className="ml-2 text-base font-semibold text-slate-700 grow">
          Wait node
        </p>
        <button
          className="h-10 w-10 flex items-center justify-center cursor-pointer"
          onClick={() => setConfirmNodeDeletion(true)}
        >
          <FaRegTrashAlt size={16} className="fill-slate-500" />
        </button>
      </div>

      <div className="p-6">
        <div className="rounded border border-slate-200 overflow-auto py-3 px-4 flex justify-between items-center bg-slate-50">
          <p className="text-sm text-slate-800">
            Wait for {count} {capitalize(interval)}
          </p>

          <button
            className="h-8 w-8 flex items-center justify-center mr-2"
            onClick={() => setShowEditWaitIntervalCount(true)}
          >
            <AiOutlineEdit size={20} className="fill-slate-500" />
          </button>
        </div>
      </div>

      <Modal
        show={confirmNodeDeletion}
        onClose={() => setConfirmNodeDeletion(false)}
        width="w-[500px]"
      >
        <div className="bg-white rounded-lg p-6 flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <p className="text-base uppercase font-semibold">
                Delete wait node?
              </p>

              <button
                className="p-2"
                onClick={() => setConfirmNodeDeletion(false)}
              >
                <CloseIcon width={16} height={16} className="fill-black" />
              </button>
            </div>

            <p className="text-base mb-4">This will delete the wait node.</p>

            <div className="flex justify-end gap-5">
              <Button
                variant="outline"
                onClick={() => setConfirmNodeDeletion(false)}
              >
                Close
              </Button>

              <Button
                color="danger"
                onClick={() => {
                  deleteNode(index);
                  setConfirmNodeDeletion(false);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {showEditWaitIntervalCount && (
        <EditWaitInterval
          count={count}
          saveWaitCount={(newCount) =>
            updateWaitInterval(index, interval, newCount)
          }
          onClose={() => setShowEditWaitIntervalCount(false)}
        />
      )}
    </div>
  );
}

const upsertHealthCoachingFlowTemplateFragment = gql`
  fragment upsertHealthCoachingFlowTemplate on HealthCoachingFlowTemplate {
    id
    name
    repeatable
    status
    nodeTemplates {
      id
      ... on HealthCoachingFlowMessageNodeTemplate {
        contentfulMessages {
          id
          contentfulId
          title
          message
          valid
        }
      }
      ... on HealthCoachingFlowWaitNodeTemplate {
        count
        interval
      }
    }
  }
`;

const healthCoachingFlowTemplateQueryDocument = gql`
  query HealthCoachingFlowTemplate($id: ID!) {
    healthCoachingFlowTemplate(id: $id) {
      ...upsertHealthCoachingFlowTemplate
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const updateHealthCoachingFlowTemplateMutationDocument = gql`
  mutation UpdateHealthCoachingFlowTemplate(
    $input: UpsertHealthCoachingFlowTemplateInput!
  ) {
    upsertHealthCoachingFlowTemplate(input: $input) {
      healthCoachingFlowTemplate {
        ...upsertHealthCoachingFlowTemplate
      }
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const updateHealthCoachingFlowTemplateStatusMutationDocument = gql`
  mutation UpdateHealthCoachingFlowTemplateStatus(
    $input: UpdateHealthCoachingFlowTemplateStatusInput!
  ) {
    updateHealthCoachingFlowTemplateStatus(input: $input) {
      healthCoachingFlowTemplate {
        ...upsertHealthCoachingFlowTemplate
      }
    }
  }
  ${upsertHealthCoachingFlowTemplateFragment}
`;

const flowTemplateStatusColorMap = {
  PUBLISHED: 'green',
  UNPUBLISHED: 'red',
  DRAFT: 'orange',
} as const;

const handleBeforeUnloadWindowEvent = (event: BeforeUnloadEvent) =>
  event.preventDefault();

export default function HealthCoachingFlowTemplate() {
  const { flowId } = useParams<{ flowId: string }>();
  const [nodes, setNodes] = useState<FlowNodeTemplate[]>([]);
  const showNotification = useNotifications();

  const { data, loading } = useQuery<
    HealthCoachingFlowTemplateQuery,
    HealthCoachingFlowTemplateQueryVariables
  >(healthCoachingFlowTemplateQueryDocument, {
    variables: {
      id: flowId,
    },
    onCompleted: (data) =>
      setNodes([...(data?.healthCoachingFlowTemplate?.nodeTemplates ?? [])]),
  });
  const [
    updateHealthCoachingFlowTemplateMutation,
    { loading: updatingFlowTemplate },
  ] = useMutation<
    UpdateHealthCoachingFlowTemplateMutation,
    UpdateHealthCoachingFlowTemplateMutationVariables
  >(updateHealthCoachingFlowTemplateMutationDocument, {
    onCompleted: (data) =>
      setNodes([
        ...(data?.upsertHealthCoachingFlowTemplate?.healthCoachingFlowTemplate
          ?.nodeTemplates ?? []),
      ]),
  });
  const [
    updateHealthCoachingFlowTemplateStatusMutation,
    { loading: updatingFlowTemplateStatus },
  ] = useMutation<
    UpdateHealthCoachingFlowTemplateStatusMutation,
    UpdateHealthCoachingFlowTemplateStatusMutationVariables
  >(updateHealthCoachingFlowTemplateStatusMutationDocument);

  const existingNodes = data?.healthCoachingFlowTemplate?.nodeTemplates ?? [];

  const handleOnDragEnd = useCallback(
    ({ source, destination }: DropResult): void => {
      if (!destination) {
        return;
      }

      setNodes((curr) => {
        const [draggedMessageNode] = curr.splice(source.index, 1);
        curr.splice(destination.index, 0, draggedMessageNode);
        return [...curr];
      });
    },
    [setNodes],
  );

  const needsToSaveFlow = !isEqual(existingNodes, nodes);

  const someItemDoesNotExistInContentful = nodes.some((n) => {
    if (n.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      return false;
    }

    return n.contentfulMessages.some(({ valid, title }) => {
      return !valid && title === defaultNewContentfulItemContent;
    });
  });

  const isNodeInvalid = nodes.map((node, index) => {
    if (node.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      return false;
    }

    return node.contentfulMessages.some(({ message, valid }) => {
      const { hasInvalidPlaceholders } =
        validateMessagePlaceholdersAndVariables(documentToHtmlString(message));

      if (index === 0) {
        return (
          !valid && (hasInvalidPlaceholders || someItemDoesNotExistInContentful)
        );
      } else {
        return !valid;
      }
    });
  });

  useEffect(() => {
    if (needsToSaveFlow) {
      window.addEventListener('beforeunload', handleBeforeUnloadWindowEvent);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnloadWindowEvent);
    }

    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadWindowEvent);
  }, [needsToSaveFlow]);

  const updateWaitInterval = (
    nodeIndex: number,
    interval: TimeInterval,
    count: number,
  ) => {
    const waitNode = nodes[nodeIndex];
    if (waitNode?.__typename !== 'HealthCoachingFlowWaitNodeTemplate') {
      throw new Error(`expected node at index ${nodeIndex} to be a wait node`);
    }

    setNodes((curr) => {
      curr.splice(nodeIndex, 1, {
        ...waitNode,
        interval,
        count,
      });
      return [...curr];
    });
  };

  if (loading && !data) {
    return (
      <div className="flex justify-center text-lg">
        <Loading />
      </div>
    );
  }

  if (!data?.healthCoachingFlowTemplate) {
    throw new Error(
      `Unable to find health coaching flow template with id: ${flowId}`,
    );
  }

  const { id, name, repeatable, status } = data.healthCoachingFlowTemplate;
  const statusColor = flowTemplateStatusColorMap[status];

  const addNewLocalMessageNode = () =>
    setNodes((curr) => [
      ...curr,
      {
        __typename: 'HealthCoachingFlowMessageNodeTemplate',
        id: v4(),
        contentfulMessages: [],
      },
    ]);

  const addNewLocalWaitNode = () =>
    setNodes((curr) => [
      ...curr,
      {
        __typename: 'HealthCoachingFlowWaitNodeTemplate',
        id: v4(),
        interval: 'DAYS',
        count: 1,
      },
    ]);

  const duplicateLocalMessageNode = (messageNodeIndex: number) => {
    const messageNode = nodes[messageNodeIndex];
    if (messageNode.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      throw new Error(
        `expected node at index ${messageNodeIndex} to be a message node`,
      );
    }
    const contentfulItems = messageNode.contentfulMessages;

    setNodes((curr) => {
      curr.splice(
        messageNodeIndex + 1,
        0,
        {
          __typename: 'HealthCoachingFlowWaitNodeTemplate',
          id: v4(),
          interval: 'DAYS',
          count: 1,
        },
        {
          __typename: 'HealthCoachingFlowMessageNodeTemplate',
          id: v4(),
          contentfulMessages: contentfulItems,
        },
      );
      return [...curr];
    });
  };

  const deleteLocalNode = (nodeIndex: number) => {
    setNodes((curr) => {
      curr.splice(nodeIndex, 1);
      return [...curr];
    });
  };

  const addLocalContentfulMessage = ({
    messageNodeIndex,
    contentfulMessage,
  }: {
    messageNodeIndex: number;
    contentfulMessage: Omit<FlowMessageNodeTemplateContentfulMessage, 'id'>;
  }) => {
    const messageNode = nodes[messageNodeIndex];
    if (messageNode.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      throw new Error(
        `expected node at index ${messageNodeIndex} to be a message node`,
      );
    }
    const updatedContentfulMessages = [...messageNode.contentfulMessages];

    updatedContentfulMessages.push({
      id: v4(),
      ...contentfulMessage,
    });

    setNodes(
      nodes.map((mn, i) => {
        if (i === messageNodeIndex) {
          return {
            ...mn,
            contentfulMessages: updatedContentfulMessages,
          };
        }

        return mn;
      }),
    );
  };

  const updateLocalContentfulMessage = ({
    messageNodeIndex,
    itemIndex,
    contentfulMessage,
  }: {
    messageNodeIndex: number;
    itemIndex: number;
    contentfulMessage: FlowMessageNodeTemplateContentfulMessage;
  }) => {
    const messageNode = nodes[messageNodeIndex];
    if (messageNode.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      throw new Error(
        `expected node at index ${messageNodeIndex} to be a message node`,
      );
    }

    const updatedContentfulMessages = [...messageNode.contentfulMessages];

    updatedContentfulMessages.splice(itemIndex, 1, contentfulMessage);

    setNodes((curr) => {
      curr.splice(messageNodeIndex, 1, {
        ...messageNode,
        contentfulMessages: updatedContentfulMessages,
      });
      return [...curr];
    });
  };

  const deleteLocalContentfulMessage = ({
    messageNodeIndex,
    itemIndex,
  }: {
    messageNodeIndex: number;
    itemIndex: number;
  }) => {
    const messageNode = nodes[messageNodeIndex];
    if (messageNode.__typename !== 'HealthCoachingFlowMessageNodeTemplate') {
      throw new Error(
        `expected node at index ${messageNodeIndex} to be a message node`,
      );
    }

    const filteredContentfulMessages = [...messageNode.contentfulMessages];
    filteredContentfulMessages.splice(itemIndex, 1);

    setNodes((curr) => {
      curr.splice(messageNodeIndex, 1, {
        ...messageNode,
        contentfulMessages: filteredContentfulMessages,
      });
      return [...curr];
    });
  };

  const hasEmptyContentfulMessage = nodes.some(
    (n) =>
      n.__typename === 'HealthCoachingFlowMessageNodeTemplate' &&
      n.contentfulMessages.length === 0,
  );

  const saveFlowTemplate = async () => {
    if (status === 'PUBLISHED' && hasEmptyContentfulMessage) {
      showNotification({
        type: 'error',
        message:
          'Published flows must have at least one message node with content',
      });
      return;
    }

    await updateHealthCoachingFlowTemplateMutation({
      variables: {
        input: {
          id: id,
          name: name,
          repeatable: repeatable,
          nodeTemplates: nodes.map<UpsertHealthCoachingFlowNodeTemplateInput>(
            (n) => {
              if (n.__typename === 'HealthCoachingFlowMessageNodeTemplate') {
                return {
                  message: {
                    id: n.id,
                    contentfulIds: n.contentfulMessages.map(
                      (cm) => cm.contentfulId,
                    ),
                  },
                };
              }
              if (n.__typename === 'HealthCoachingFlowWaitNodeTemplate') {
                return {
                  wait: {
                    id: n.id,
                    interval: n.interval,
                    count: n.count,
                  },
                };
              }
              throw new Error('not implemented');
            },
          ),
        },
      },
    });

    showNotification({
      type: 'success',
      message: 'Flow updated',
    });
  };

  const updateFlowTemplateStatus = async (
    newStatus: 'PUBLISHED' | 'UNPUBLISHED',
  ) => {
    if (newStatus === 'PUBLISHED' && hasEmptyContentfulMessage) {
      showNotification({
        type: 'error',
        message:
          'Cannot publish an empty message node. Please add content or delete node.',
      });
      return;
    }
    await updateHealthCoachingFlowTemplateStatusMutation({
      variables: {
        input: {
          id: flowId,
          status: newStatus,
        },
      },
    });

    showNotification({
      type: 'success',
      message:
        newStatus === 'PUBLISHED' ? 'Flow published' : 'Flow unpublished',
    });
  };

  return (
    <div className="relative z-0">
      <div className="flex justify-between pt-2 pb-8 bg-background-light">
        <div className="flex gap-2 items-center">
          <Tag shape="box" color={statusColor} size="large">
            {status}
          </Tag>
          <p className="font-semibold">{name}</p>
        </div>

        <div className="flex justify-end gap-2">
          <Button
            disabled={!needsToSaveFlow || isNodeInvalid.some(Boolean)}
            loading={updatingFlowTemplate}
            onClick={saveFlowTemplate}
          >
            {status === 'PUBLISHED' ? 'Save to published' : 'Save'}
          </Button>

          {(status === 'DRAFT' || status === 'UNPUBLISHED') && (
            <Button
              variant="outline"
              loading={updatingFlowTemplateStatus}
              disabled={!nodes.length || needsToSaveFlow}
              onClick={() => {
                void updateFlowTemplateStatus('PUBLISHED');
              }}
            >
              Publish
            </Button>
          )}

          {status === 'PUBLISHED' && (
            <Button
              variant="outline"
              onClick={() => {
                void updateFlowTemplateStatus('UNPUBLISHED');
              }}
            >
              Unpublish
            </Button>
          )}
        </div>
      </div>

      <GridTransition show={isNodeInvalid.some(Boolean)}>
        <div className="p-3 rounded flex items-start gap-1 border border-red-500 bg-red-50 mb-8">
          <div className="w-6 h-6 flex items-center justify-center">
            <FaExclamationCircle size={16} className="fill-red-600" />
          </div>
          <div className="grow">
            {someItemDoesNotExistInContentful && (
              <p className="text-base font-semibold text-black">
                Some items were not found in contentful.
              </p>
            )}
            {isNodeInvalid.some(Boolean) && (
              <p className="text-base font-semibold text-black">
                The message contains invalid placeholders or unfilled variables.
              </p>
            )}
            {status === 'PUBLISHED' && (
              <p className="text-base text-black">
                This template needs to be updated, otherwise coaches will
                encounter errors trying to send this flow. Update or delete the
                flagged variants and then save your changes.
              </p>
            )}
          </div>
        </div>
      </GridTransition>

      <GridTransition show={needsToSaveFlow && !isNodeInvalid.some(Boolean)}>
        <div className="p-3 rounded flex items-start gap-1 border border-blue-400 bg-blue-50 mb-8">
          <div className="w-6 h-6 flex items-center justify-center">
            <FaInfoCircle size={16} className="fill-blue-600" />
          </div>
          <div className="grow">
            <p className="text-base font-semibold text-slate-700">
              You have unpublished changes
            </p>
            {status === 'PUBLISHED' && (
              <p className="text-base text-slate-700">
                Saving will publish your changes to live coaching action. If you
                wish to make changes offline, please unpublish the flow first.
              </p>
            )}
          </div>
        </div>
      </GridTransition>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="flowTemplateMessageNodes">
          {(provided): JSX.Element => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {nodes.map((node, index) => (
                <Draggable key={node.id} draggableId={node.id} index={index}>
                  {(provided): JSX.Element => {
                    switch (node.__typename) {
                      case 'HealthCoachingFlowMessageNodeTemplate':
                        return (
                          <FlowTemplateMessageNode
                            index={index}
                            draggableProvided={provided}
                            contentfulMessages={node.contentfulMessages}
                            duplicateMessageNode={duplicateLocalMessageNode}
                            deleteMessageNode={deleteLocalNode}
                            addContentItem={addLocalContentfulMessage}
                            updateContentItem={updateLocalContentfulMessage}
                            deleteContentItem={deleteLocalContentfulMessage}
                            contentfulIds={
                              new Set(
                                node.contentfulMessages.map(
                                  (m) => m.contentfulId,
                                ),
                              )
                            }
                            isMessageInvalid={isNodeInvalid[index]}
                          />
                        );
                      case 'HealthCoachingFlowWaitNodeTemplate':
                        return (
                          <FlowTemplateWaitNode
                            index={index}
                            deleteNode={deleteLocalNode}
                            interval={node.interval}
                            count={node.count}
                            draggableProvided={provided}
                            updateWaitInterval={updateWaitInterval}
                          />
                        );
                      default:
                        throw new Error(
                          `node type ${node.__typename} not supported`,
                        );
                    }
                  }}
                </Draggable>
              ))}

              {/* maintains height of container during drag */}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="px-5 py-0.5 rounded border border-slate-200 bg-white/50 mt-8">
        <div className="flex flex-row justify-center gap-x-8">
          <button
            className="flex items-center justify-center px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold my-32 w-[212px]"
            onClick={addNewLocalMessageNode}
          >
            <FaPlus className="mr-1 text-gray-700" size={14} />
            <span>Add message node</span>
          </button>
          <button
            className="flex items-center justify-center px-4 py-2.5 rounded border border-gray-700 hover:shadow-md transition-shadow text-sm font-semibold my-32 w-[212px]"
            onClick={addNewLocalWaitNode}
          >
            <FaPlus className="mr-1 text-gray-700" size={14} />
            <span>Add wait node</span>
          </button>
        </div>
      </div>

      <Prompt
        when={needsToSaveFlow}
        message="Changes that you made may not be saved."
      />
    </div>
  );
}
