import { useForm } from 'react-hook-form-6';
import { gql, useMutation } from '@apollo/client';
import { Button, ProtectedButton } from 'components/button';
import { Input } from 'components/react-hook-form-6/input';
import { TextArea } from 'components/text-area';
import {
  AppendSequenceFaqMutation,
  AppendSequenceFaqMutationVariables,
  DeleteSequenceFaqMutation,
  DeleteSequenceFaqMutationVariables,
  SequencePageQuery,
  UpdateSequenceFaqMutation,
  UpdateSequenceFaqMutationVariables,
} from 'graphql/types';
import { requiredValidation } from 'utils/form-validation';
import React, { useEffect } from 'react';
import { Tag } from 'components/tag';

type FaqForm = {
  answerMarkdown: string;
  question: string;
  videoUrl?: string | null | undefined;
};

export const Faq = ({
  faq,
  sequenceId,
  mode,
  setShowFaqFormFalse,
}: {
  sequenceId: string;
  faq?: NonNullable<SequencePageQuery['sequence']>['faqs'][number];
  mode: 'CREATE' | 'EDIT';
  setShowFaqFormFalse?: () => void;
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    errors,
    formState: { isSubmitSuccessful, isDirty },
    getValues,
  } = useForm<FaqForm>({
    defaultValues: faq
      ? {
          question: faq.question,
          answerMarkdown: faq.answerMarkdown,
          videoUrl: faq.videoUrl,
        }
      : {},
  });
  // react-hook-form magic to reset form state isDirty on submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      if (mode === 'CREATE') reset({}, {});

      if (mode === 'EDIT') reset(getValues(), {});
    }
  }, [getValues, isSubmitSuccessful, reset, mode]);

  const [updateSequenceFaq, { loading: loadingUpdateFaq }] = useMutation<
    UpdateSequenceFaqMutation,
    UpdateSequenceFaqMutationVariables
  >(gql`
    mutation UpdateSequenceFaq($input: UpdateSequenceFaqInput!) {
      updateSequenceFaq(input: $input) {
        faq {
          id
          question
          answerMarkdown
          videoUrl
        }
      }
    }
  `);

  const [appendSequenceFaq, { loading: loadingAppendFaq }] = useMutation<
    AppendSequenceFaqMutation,
    AppendSequenceFaqMutationVariables
  >(gql`
    mutation AppendSequenceFaq($input: AppendSequenceFaqInput!) {
      appendSequenceFaq(input: $input) {
        sequence {
          id
          faqs {
            id
            question
            answerMarkdown
            videoUrl
          }
        }
      }
    }
  `);

  const [deleteSequenceFaq, { loading: loadingDeleteFaq }] = useMutation<
    DeleteSequenceFaqMutation,
    DeleteSequenceFaqMutationVariables
  >(gql`
    mutation DeleteSequenceFaq($input: DeleteSequenceFaqInput!) {
      deleteSequenceFaq(input: $input) {
        sequence {
          id
          faqs {
            id
          }
        }
      }
    }
  `);

  const upsertSequenceFaq = async (formValues: FaqForm): Promise<void> => {
    switch (mode) {
      case 'CREATE':
        await appendSequenceFaq({
          variables: {
            input: {
              answerMarkdown: formValues.answerMarkdown,
              question: formValues.question,
              sequenceId,
              videoUrl: formValues.videoUrl || undefined,
            },
          },
        });

        break;
      case 'EDIT':
        if (!faq) {
          return;
        }
        await updateSequenceFaq({
          variables: {
            input: {
              id: faq.id,
              answerMarkdown: formValues.answerMarkdown,
              question: formValues.question,
              videoUrl: formValues.videoUrl || undefined,
            },
          },
        });
        break;
    }
  };

  return (
    <div className="bg-white shadow overflow-hidden rounded p-4 m-2 w-full">
      {mode === 'CREATE' && (
        <div className="mb-4">
          <Tag color="gray">Draft FAQ</Tag>
        </div>
      )}
      <form onSubmit={handleSubmit(upsertSequenceFaq)}>
        <div className="space-y-1">
          <Input
            name="question"
            label="Question"
            ref={register(requiredValidation('question'))}
            errorMessage={errors.question?.message}
          />
          <TextArea
            name="answerMarkdown"
            label="Answer"
            rows={5}
            placeholder=""
            errorMessage={errors.answerMarkdown?.message}
            ref={register(requiredValidation('answerMarkdown'))}
          />
          <Input
            name="videoUrl"
            label="Video URL"
            ref={register()}
            errorMessage={errors.videoUrl?.message}
          />
          <div className="flex justify-end">
            <div className="w-1/3 p-2">
              <ProtectedButton
                fullWidth
                requiredPermissions={['EDIT_OFFERINGS']}
                disabled={loadingDeleteFaq || !isDirty}
                loading={loadingAppendFaq || loadingUpdateFaq}
                type="submit"
              >
                {<SubmitButton mode={mode} />}
              </ProtectedButton>
            </div>
            {mode === 'EDIT' && (
              <div className="w-1/3 p-2">
                {isDirty ? (
                  <Button
                    fullWidth
                    loading={loadingDeleteFaq}
                    disabled={loadingUpdateFaq}
                    onClick={(): void => {
                      reset(control.defaultValuesRef.current);
                    }}
                    variant="outline"
                    color="danger"
                  >
                    Cancel
                  </Button>
                ) : (
                  <ProtectedButton
                    fullWidth
                    requiredPermissions={['EDIT_OFFERINGS']}
                    loading={loadingDeleteFaq}
                    disabled={loadingUpdateFaq}
                    onClick={(): void => {
                      if (faq) {
                        deleteSequenceFaq({
                          variables: { input: { id: faq?.id } },
                        });
                      }
                    }}
                    variant="solid"
                    color="danger"
                  >
                    Delete
                  </ProtectedButton>
                )}
              </div>
            )}
            {mode === 'CREATE' && (
              <div className="w-1/3 p-2">
                <Button
                  fullWidth
                  loading={loadingDeleteFaq}
                  disabled={loadingUpdateFaq}
                  onClick={(): void => {
                    if (setShowFaqFormFalse) setShowFaqFormFalse();
                  }}
                  variant="outline"
                  color="danger"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const SubmitButton = ({ mode }: { mode: 'CREATE' | 'EDIT' }): JSX.Element => {
  switch (mode) {
    case 'CREATE':
      return <>Create</>;
    case 'EDIT':
      return <>Update</>;
  }
};
