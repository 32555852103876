// THIS FILE IS COPIED FROM https://github.com/GetStream/stream-chat-react/blob/develop/src/components/Gallery/Gallery.tsx
import React, { CSSProperties, MutableRefObject, useState } from 'react';
import clsx from 'clsx';

import type { Attachment } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import {
  Modal,
  ModalGallery as DefaultModalGallery,
  useComponentContext,
  useTranslationContext,
} from 'stream-chat-react';
import { Button } from '../button';
import { useHasPermissions } from '../permissions';
import photoRedactedPlaceholder from '../assets/photo-redacted-placeholder.png';

type GalleryProps<
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
> = {
  images: ((
    | {
        image_url?: string | undefined;
        thumb_url?: string | undefined;
      }
    | Attachment<StreamChatGenerics>
  ) & { previewUrl?: string; style?: CSSProperties })[];
  innerRefs?: MutableRefObject<(HTMLElement | null)[]>;
};

const UnMemoizedGallery = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: GalleryProps<StreamChatGenerics>,
) => {
  const [isBlurred, setBlurred] = useState(true);
  const { images, innerRefs } = props;

  const [index, setIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const canViewPii = useHasPermissions(['VIEW_PATIENT_PII']);

  const { ModalGallery = DefaultModalGallery } = useComponentContext('Gallery');
  const { t } = useTranslationContext('Gallery');

  const countImagesDisplayedInPreview = 4;
  const lastImageIndexInPreview = countImagesDisplayedInPreview - 1;

  const toggleModal = (selectedIndex: number) => {
    if (isBlurred) return;

    if (modalOpen) {
      setModalOpen(false);
    } else {
      setIndex(selectedIndex);
      setModalOpen(true);
    }
  };

  function sanitizeUrl(url?: string) {
    if (!url) {
      return 'about:blank';
    }

    try {
      return new URL(url).href;
    } catch {
      return 'about:blank';
    }
  }

  const renderImages = images
    .slice(0, countImagesDisplayedInPreview)
    .map((image, i) =>
      i === lastImageIndexInPreview &&
      images.length > countImagesDisplayedInPreview ? (
        <button
          className="str-chat__gallery-placeholder"
          data-testid="gallery-image-last"
          key={`gallery-image-${i}`}
          onClick={() => toggleModal(i)}
          style={{
            backgroundImage: `url(${
              images[lastImageIndexInPreview].previewUrl ||
              images[lastImageIndexInPreview].image_url ||
              images[lastImageIndexInPreview].thumb_url
            })`,
            ...image.style,
          }}
          {...(innerRefs?.current && {
            ref: (r) => (innerRefs.current[i] = r),
          })}
        >
          <p>
            {t<string>('{{ imageCount }} more', {
              imageCount: images.length - countImagesDisplayedInPreview,
            })}
          </p>
        </button>
      ) : (
        <button
          className={clsx('str-chat__gallery-image')}
          data-testid="gallery-image"
          key={`gallery-image-${i}`}
          onClick={() => toggleModal(i)}
        >
          <img
            alt="User uploaded content"
            src={sanitizeUrl(
              image.previewUrl || image.image_url || image.thumb_url,
            )}
            style={image.style}
            {...(innerRefs?.current && {
              ref: (r) => (innerRefs.current[i] = r),
            })}
          />
        </button>
      ),
    );

  if (!canViewPii) {
    return (
      <img
        src={photoRedactedPlaceholder}
        alt="Photo Redacted"
        style={{ maxHeight: 'inherit' }}
      />
    );
  }

  return (
    <>
      <div
        className={clsx('str-chat__gallery', {
          'filter blur-xl': isBlurred,
          'str-chat__gallery--square': images.length > lastImageIndexInPreview,
          'str-chat__gallery-two-rows': images.length > 2,
        })}
      >
        {renderImages}

        <Modal
          onClose={() => setModalOpen((modalOpen) => !modalOpen)}
          open={modalOpen}
        >
          <ModalGallery images={images} index={index} />
        </Modal>
      </div>
      <Button
        fullWidth
        onClick={(): void => {
          setBlurred(!isBlurred);
        }}
      >
        {`${isBlurred ? 'Reveal' : 'Blur'} images`}
      </Button>
    </>
  );
};

export const Gallery = React.memo(
  UnMemoizedGallery,
) as typeof UnMemoizedGallery;
