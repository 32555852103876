import {
  Maybe,
  PathologyPanel,
  PathologyPanelTestResultPositiveNegativeValueOptions,
  PathologyPanelTestResultValue,
  PathologyPanelTestResultValueType,
  PathologyRequest,
  PathologyResultValueInput,
} from 'graphql/types';

type FormRange = {
  high?: Maybe<string>;
  low?: Maybe<string>;
};

export type FormResultValue = {
  default?: Maybe<string>;
  number?: Maybe<string>;
  range?: Maybe<FormRange>;
  type: PathologyPanelTestResultValueType;
  unit?: Maybe<string>;
  value?: Maybe<string>;
};

type FormField = {
  note: string | null;
  result: FormResultValue;
};

export type FormFields = {
  lab?: string;
  resultsUrl?: string;
  doctorId?: string;
  collectedDate?: string;
  panels?: Record<string, Record<string, FormField>>;
};

function isPathologyPanelTestResultPositiveNegativeValueOptions(
  s: string | null | undefined,
): s is PathologyPanelTestResultPositiveNegativeValueOptions {
  return s === 'POSITIVE' || s === 'NEGATIVE';
}

function toInput(
  id: string,
  testResults: FormField,
): PathologyResultValueInput | undefined {
  if (!isResultComplete(testResults)) {
    return undefined;
  }
  const note = testResults.note?.trim() ?? null;

  switch (testResults.result.type) {
    case 'POSITIVE_NEGATIVE':
      return toPositiveNegativeResult(id, note, testResults.result);
    case 'UNIT_WITH_RANGE':
      return toUnitWithRangeResult(id, note, testResults.result);
    default:
      return undefined;
  }
}

function toPositiveNegativeResult(
  id: string,
  note: string | null,
  result: FormResultValue,
): PathologyResultValueInput | undefined {
  if (!isPathologyPanelTestResultPositiveNegativeValueOptions(result.value)) {
    return;
  }

  return {
    pathologyRequestPanelTestId: id,
    note,
    positiveNegativeResult: {
      type: result.type,
      value: result.value,
    },
  };
}

function toUnitWithRangeResult(
  id: string,
  note: string | null,
  result: FormResultValue,
): PathologyResultValueInput | undefined {
  if (!isUnitWithRangeResultComplete(result)) {
    return undefined;
  }
  return {
    pathologyRequestPanelTestId: id,
    note,
    unitWithRangeResult: {
      type: result.type,
      number: parseFloat(result.number ?? ''),
      unit: result.unit ?? '',
      range: {
        low: parseFloat(result.range?.low ?? ''),
        high: parseFloat(result.range?.high ?? ''),
      },
    },
  };
}

export const formatPathologyResultData = (
  data: FormFields,
  pathologyRequest: PathologyRequest,
): PathologyResultValueInput[] => {
  const results: PathologyResultValueInput[] = [];

  pathologyRequest.panels?.forEach((panel) => {
    const panelTests = data.panels?.[panel?.id ?? ''] ?? [];
    Object.entries(panelTests).forEach(([id, testResults]) => {
      const input = toInput(id, testResults);
      if (input) {
        results.push(input);
      }
    });
  });

  return results;
};

export const resultToFormResultValue = (
  result: PathologyPanelTestResultValue,
): FormResultValue => {
  return {
    type: result.type,
    value: result.value,
    unit: result.unit,
    number: numberToString(result.number),
    range: result.range
      ? {
          low: numberToString(result.range?.low),
          high: numberToString(result.range?.high),
        }
      : undefined,
  };
};

function numberToString(n: Maybe<number>): string | null {
  return n !== undefined && n !== null ? n + '' : null;
}

function isNonNegativeNumber(s: Maybe<string>): boolean {
  if (!s) {
    return false;
  }
  const number = parseFloat(s);
  return !isNaN(number) && number >= 0;
}

function isPositiveNegativeResultComplete(result: FormResultValue): boolean {
  return isPathologyPanelTestResultPositiveNegativeValueOptions(result.value);
}

function isUnitWithRangeResultComplete(result: FormResultValue): boolean {
  return (
    isNonNegativeNumber(result.number) &&
    isNonNegativeNumber(result.range?.low) &&
    isNonNegativeNumber(result.range?.high) &&
    !!result.unit &&
    !!result.range?.low &&
    !!result.range?.high &&
    parseFloat(result.range?.low) < parseFloat(result.range?.high)
  );
}

export const isResultComplete = (formField: Maybe<FormField>): boolean => {
  if (!formField) {
    return false;
  }
  const { result } = formField;

  switch (result.type) {
    case 'POSITIVE_NEGATIVE':
      return isPositiveNegativeResultComplete(result);
    case 'UNIT_WITH_RANGE':
      return isUnitWithRangeResultComplete(result);
  }
};

export const showResultInputs = (panels: Maybe<PathologyPanel[]>): boolean => {
  return !!panels?.some((panel) => !!panel.tests?.length);
};
