import React from 'react';
import clsx from 'clsx';
import { AdminPermission } from 'graphql/types';
import { Loading } from './loading';
import { useHasPermissions, getMissingPermissionsMessage } from './permissions';

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'solid' | 'outline' | 'text' | 'link';
  color?: 'primary' | 'success' | 'danger';
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  hoverText?: string;
  type?: 'submit' | 'button';
  size?: 'small' | 'large';
  fillHeight?: boolean;
  fullWidth?: boolean;
}

/**
 * Disables and displays a tooltip to the user if they do not have the `requiredPermissions`.
 * Otherwise renders the button normally to the user
 */
export const ProtectedButton = (
  props: Props & {
    requiredPermissions: AdminPermission[];
  },
): JSX.Element => {
  const hasRequiredPermissions = useHasPermissions(props.requiredPermissions);

  return (
    <Button
      {...props}
      disabled={!hasRequiredPermissions || props.disabled}
      hoverText={
        !hasRequiredPermissions
          ? getMissingPermissionsMessage(props.requiredPermissions)
          : props.hoverText
      }
    >
      {props.children}
    </Button>
  );
};

export const Button = ({
  onClick,
  children,
  variant = 'solid',
  color = 'primary',
  disabled = false,
  loading = false,
  autoFocus = false,
  type = 'button',
  size = 'large',
  hoverText = '',
  fillHeight = false,
  fullWidth,
}: Props): JSX.Element => {
  const solidColors = clsx('text-white', 'font-medium', 'uppercase', {
    'bg-primary hover:bg-primary-400': !disabled && color === 'primary',
    'bg-green-500 hover:bg-green-600': !disabled && color === 'success',
    'bg-red-500 hover:bg-red-700': !disabled && color === 'danger',
    'bg-gray-500': disabled,
  });

  const outlineColors = clsx(
    {
      'text-primary border-primary hover:bg-primary':
        !disabled && color === 'primary',
      'text-green-500 border-green-500 hover:bg-green-500':
        !disabled && color === 'success',
      'text-red-500 border-red-500 hover:bg-red-500':
        !disabled && color === 'danger',
      'text-gray-300 hover:text-gray-300 border-gray-300': disabled,
      'hover:text-white': !disabled,
    },
    'bg-transparent',
    'border-2',
    'uppercase',
    'whitespace-nowrap',
  );

  const textColors = clsx(
    {
      'text-gray-900': !disabled && color === 'primary',
      'text-green-500': !disabled && color === 'success',
      'text-red-500': !disabled && color === 'danger',
      'text-gray-500': disabled,
    },
    'text-sm',
    'uppercase',
    'hover:bg-gray-100',
    'active:bg-gray-200',
  );

  const linkButtonStyle = clsx(
    {
      'text-primary': !disabled && color === 'primary',
      'text-green-500': !disabled && color === 'success',
      'text-red-500': !disabled && color === 'danger',
      'text-gray-500': disabled,
    },
    'text-medium',
    'underline',
  );

  const focusStates = clsx(
    {
      'focus:ring ring-primary ring-opacity-50':
        !disabled && color === 'primary',
      'focus:ring ring-green-500 ring-opacity-50':
        !disabled && color === 'success',
      'focus:ring ring-red-500 ring-opacity-50':
        !disabled && color === 'danger',
    },
    'focus:outline-none',
  );

  const buttonSize = clsx(
    {
      'text-sm tracking-wide': size === 'small' && variant !== 'link',
      'text-base tracking-wider': size === 'large' && variant !== 'link',
    },
    variant === 'outline'
      ? {
          'px-1.5 py-0.5': size === 'small',
          'px-4 py-1.5': size === 'large',
        }
      : {
          'px-2': size === 'small' && variant !== 'link',
          'px-5': size === 'large' && variant !== 'link',
          'py-1': size === 'small',
          'py-2': size === 'large',
        },
  );

  const styles = clsx(
    'flex',
    'relative',
    'items-center',
    'justify-center',
    'font-medium',
    fullWidth && 'w-full',
    'rounded-md',
    'transition-color',
    'transition-opacity',
    'duration-200',
    focusStates,
    buttonSize,
    { 'h-full': fillHeight },
    { [solidColors]: variant === 'solid' },
    { [outlineColors]: variant === 'outline' },
    { [textColors]: variant === 'text' },
    { [linkButtonStyle]: variant === 'link' },
    { 'cursor-not-allowed': disabled },
  );

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={styles}
      autoFocus={autoFocus}
      title={hoverText}
    >
      <span
        className={clsx(
          'transition-opacity duration-300',
          loading ? 'opacity-0' : 'opacity-100',
        )}
      >
        {children}
      </span>
      <span
        className={clsx(
          'transition-opacity duration-300 absolute',
          loading ? 'opacity-100' : 'opacity-0',
        )}
      >
        <Loading />
      </span>
    </button>
  );
};
