import { useCallback, useEffect, useMemo, useState } from 'react';
import { validate } from 'uuid';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { Modal } from 'components/modal';
import {
  CollectionPageQuery,
  CollectionPageQueryVariables,
  ContentItemStatus,
  UpdateCollectionItemsMutation,
  UpdateCollectionItemsMutationVariables,
  CollectionPageContentItemsQuery,
  CollectionPageContentItemsQueryVariables,
} from 'graphql/types';
import { FaBars, FaRegTrashAlt } from 'react-icons/fa';
import { Card } from 'components/card';
import { getCardOutlineClassName } from 'components/education/utils';
import { Tag } from 'components/tag';
import { Colors } from 'utils/misc';
import clsx from 'clsx';

const contentCollectionAddContentItemQuery = gql`
  mutation UpdateCollectionItems($input: UpdateContentCollectionInput!) {
    updateContentCollection(input: $input) {
      collection {
        id
        name
        description
        items {
          id
          status
          ... on VideoContentItem {
            id
            title
            contentfulId
            thumbnailUrl
          }
          ... on VideoWithReadingContentItem {
            id
            title
            contentfulId
            thumbnailUrl
          }
          ... on ReadingContentItem {
            id
            title
            contentfulId
            thumbnailUrl
          }
          ... on RecipeContentItem {
            id
            title
            contentfulId
            thumbnailUrl
          }
        }
      }
    }
  }
`;

const contentItems = gql`
  query CollectionPageContentItems {
    contentItems {
      id
      status
      tags {
        id
        name
        description
      }
      ... on VideoContentItem {
        contentfulId
        title
        description
        playbackUrl
        thumbnailUrl
        durationSeconds
      }
      ... on RecipeContentItem {
        contentfulId
        title
        thumbnailUrl
      }
      ... on FaqContentItem {
        id
        contentfulId
        question
      }
    }
  }
`;

type AddedContentItem =
  | {
      __typename?: 'VideoContentItem';
      id: string;
      title: string;
      contentfulId: string;
      thumbnailUrl: string;
      status: ContentItemStatus;
    }
  | {
      __typename?: 'RecipeContentItem';
      id: string;
      title: string;
      contentfulId: string;
      thumbnailUrl: string;
      status: ContentItemStatus;
    }
  | {
      __typename?: 'FaqContentItem';
      id: string;
      question: string;
      contentfulId: string;
      status: ContentItemStatus;
    }
  | {
      __typename?: 'VideoWithReadingContentItem';
      id: string;
      title: string;
      contentfulId: string;
      thumbnailUrl: string;
      status: ContentItemStatus;
    }
  | {
      __typename?: 'ReadingContentItem';
      id: string;
      title: string;
      contentfulId: string;
      status: ContentItemStatus;
    };

function AddCollectionItemContent(props: {
  collectionId: string;
  itemIds: string[];
  onContentAdded: (item: AddedContentItem) => void;
  onClear: () => void;
}): JSX.Element {
  const { itemIds, onContentAdded, onClear } = props;
  const [showModal, setShowModal] = useState(false);
  const [
    fetchContentCollections,
    { data: collections, loading: collectionsLoading },
  ] = useLazyQuery<
    CollectionPageContentItemsQuery,
    CollectionPageContentItemsQueryVariables
  >(contentItems);

  const onCancel = useCallback(() => {
    onClear();
    setShowModal(false);
  }, [onClear]);
  const onConfirm = () => {
    setShowModal(false);
  };

  const availableContent = useMemo(() => {
    return collections?.contentItems ?? [];
  }, [collections]);

  const contentGroups = availableContent.reduce<{
    videos: AddedContentItem[];
    recipes: AddedContentItem[];
    faqs: AddedContentItem[];
  }>(
    (acc, curr) => {
      if (curr.__typename === 'VideoContentItem') {
        acc.videos.push(curr);
      }
      if (curr.__typename === 'RecipeContentItem') {
        acc.recipes.push(curr);
      }
      if (curr.__typename === 'FaqContentItem') {
        acc.faqs.push(curr);
      }
      return acc;
    },
    {
      videos: [],
      recipes: [],
      faqs: [],
    },
  );
  const contentSections = useMemo(() => {
    return [
      {
        title: 'Videos',
        items: contentGroups.videos,
      },
      {
        title: 'Recipes',
        items: contentGroups.recipes,
      },
      {
        title: 'FAQs',
        items: contentGroups.faqs,
      },
    ];
  }, [contentGroups]);

  return (
    <>
      <Button
        fullWidth
        variant="outline"
        onClick={(): void => {
          fetchContentCollections();
          setShowModal(true);
        }}
      >
        Add content
      </Button>
      <Modal
        show={showModal}
        onClose={(): void => setShowModal(false)}
        width="w-3/4"
        height="h-full"
        isAutoOverflow={false}
      >
        <div className="bg-gray-300 flex flex-col rounded p-4 h-full">
          <div className="flex justify-between flex-none">
            <h1 className="text-2xl mb-6 mt-2 font-bold">Add Content</h1>
            <div className="flex flex-row gap-2 h-10">
              <Button fullWidth variant="outline" onClick={onClear}>
                Clear
              </Button>
              <Button fullWidth variant="outline" onClick={onCancel}>
                Cancel
              </Button>
              <Button fullWidth variant="solid" onClick={onConfirm}>
                Confirm
              </Button>
            </div>
          </div>
          <h2 className="text-lg mb-4">
            Select one or more pieces of content and then click confirm
          </h2>
          {collectionsLoading ? (
            <Loading />
          ) : (
            <div className="grid grid-cols-3 grow gap-2 overflow-y-auto">
              {contentSections.map((section, index) => (
                <div key={index} className="overflow-y-auto">
                  <h2 className="text-lg font-semibold mb-2 sticky top-0 bg-gray-300">
                    {section.title}
                  </h2>
                  <div className="flex flex-col">
                    {section.items.map((c, i) => (
                      <ContentItem
                        key={`${section.title}.${i}`}
                        content={c}
                        selected={itemIds.includes(c.id)}
                        handleContentSelected={onContentAdded}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

const ContentItemTag = (props: {
  content: AddedContentItem;
}): React.ReactElement => {
  let color: Colors;
  let tag;
  switch (props.content.__typename) {
    case 'VideoContentItem':
      color = 'teal';
      tag = 'Video';
      break;
    case 'RecipeContentItem':
      color = 'red';
      tag = 'Recipe';
      break;
    case 'FaqContentItem':
      color = 'blue';
      tag = 'FAQ';
      break;
    default:
      color = 'gray';
      tag = 'Unsupported type';
      break;
  }
  return (
    <Tag size="small" color={color}>
      {tag}
    </Tag>
  );
};

function ContentItem(props: {
  content: AddedContentItem;
  selected: boolean;
  handleContentSelected: (item: AddedContentItem) => void;
}): JSX.Element {
  const { content, selected, handleContentSelected } = props;
  return (
    <button
      key={content.id}
      className={clsx(
        'flex flex-row space-x-2 rounded p-2 mr-2 mb-2 border-2 hover:border-green-500 transition-colors cursor-pointer w-full',
        selected ? 'border-blue-500 bg-blue-200' : 'border-white bg-white',
      )}
      onClick={() => handleContentSelected(content)}
    >
      <>
        {(content.__typename === 'VideoContentItem' ||
          content.__typename === 'RecipeContentItem') && (
          <img src={content.thumbnailUrl} className="max-h-32 rounded" />
        )}
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-col items-start gap-2">
            <div className="text-left">
              {(content.__typename === 'VideoContentItem' ||
                content.__typename === 'RecipeContentItem') &&
                content.title}
              {content.__typename === 'FaqContentItem' && content.question}
            </div>
            <ContentItemTag content={content} />
          </div>
        </div>
      </>
    </button>
  );
}

const contentCollectionsTableQuery = gql`
  query CollectionPage($id: ID!) {
    contentCollection(id: $id) {
      id
      name
      description
      items {
        id
        status
        ... on VideoContentItem {
          id
          title
          contentfulId
          thumbnailUrl
        }
        ... on VideoWithReadingContentItem {
          id
          title
          contentfulId
          thumbnailUrl
        }
        ... on ReadingContentItem {
          id
          title
          contentfulId
        }
        ... on RecipeContentItem {
          id
          title
          contentfulId
          thumbnailUrl
        }
        ... on FaqContentItem {
          id
          contentfulId
          question
        }
      }
    }
  }
`;

export function ContentCollectionPage(): JSX.Element {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { data, loading } = useQuery<
    CollectionPageQuery,
    CollectionPageQueryVariables
  >(contentCollectionsTableQuery, {
    variables: { id: collectionId },
    skip: !validate(collectionId),
  });

  const [collection, _setCollection] =
    useState<CollectionPageQuery['contentCollection']>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const setCollection = useCallback(
    (collection: CollectionPageQuery['contentCollection']) => {
      _setCollection(collection);
      setUnsavedChanges(true);
    },
    [],
  );
  const clearChanges = useCallback(() => {
    if (data?.contentCollection) {
      _setCollection(data.contentCollection);
      setUnsavedChanges(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.contentCollection) {
      _setCollection(data.contentCollection);
      setUnsavedChanges(false);
    }
  }, [data]);

  const [updateCollection, { loading: updatingCollection }] = useMutation<
    UpdateCollectionItemsMutation,
    UpdateCollectionItemsMutationVariables
  >(contentCollectionAddContentItemQuery);

  const saveChanges = useCallback(async () => {
    if (collection) {
      await updateCollection({
        variables: {
          input: {
            id: collection.id,
            itemIds: collection.items.map((i) => i.id),
          },
        },
      });
    }
  }, [collection, updateCollection]);

  const items = useMemo(() => collection?.items || [], [collection?.items]);

  const handleOnDragEnd = useCallback(
    ({ source, destination }: DropResult): void => {
      if (!collection || !destination || destination.index === source.index) {
        return;
      }

      const newItemOrder = items.slice();
      const [movedItem] = newItemOrder.splice(source.index, 1);
      newItemOrder.splice(destination.index, 0, movedItem);

      setCollection({ ...collection, items: newItemOrder });
    },
    [collection, items, setCollection],
  );

  const handleDeletePressed = useCallback(
    (itemId: string): void => {
      if (!collection) return;

      const updatedItems = items.filter((id) => id.id !== itemId);

      setCollection({ ...collection, items: updatedItems });
    },
    [collection, items, setCollection],
  );

  const handleContentAdded = useCallback(
    (item: AddedContentItem) => {
      if (!collection) return;

      if (items.find((i) => i.id === item.id)) {
        handleDeletePressed(item.id);
      } else {
        setCollection({ ...collection, items: items.concat(item) });
      }
    },
    [collection, items, setCollection, handleDeletePressed],
  );

  if (loading) {
    return <Loading />;
  }

  if (!collection) {
    return <>No collection could be found.</>;
  }

  return (
    <>
      <div className="float-right">
        <div className="flex justify-end">
          <span className="font-semibold mr-2">Status</span>
          {unsavedChanges ? (
            <Tag size="small" color="orange">
              Changed
            </Tag>
          ) : (
            <Tag size="small" color="green">
              Published
            </Tag>
          )}
        </div>
        {unsavedChanges && (
          <div className="flex mt-4 space-x-4">
            <Button
              fullWidth
              onClick={saveChanges}
              color="success"
              size="small"
            >
              Save
            </Button>
            <Button
              fullWidth
              onClick={clearChanges}
              color="danger"
              variant="outline"
              size="small"
            >
              Undo
            </Button>
          </div>
        )}
      </div>
      <div className="space-y-4">
        <span className="text-xl mb-3">{data?.contentCollection?.name}</span>

        <div className="mb-3">{data?.contentCollection?.description}</div>

        <Card className={getCardOutlineClassName(unsavedChanges)}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="contentItems">
              {(provided): JSX.Element => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="divide-y"
                >
                  {items.map((item, i) => {
                    let title = '';
                    switch (item.__typename) {
                      case 'FaqContentItem':
                        title = item.question;
                        break;
                      case 'ReadingContentItem':
                      case 'RecipeContentItem':
                      case 'VideoContentItem':
                      case 'VideoWithReadingContentItem':
                        title = item.title;
                        break;
                    }
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={i}
                        isDragDisabled={updatingCollection}
                      >
                        {(provided): JSX.Element => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="flex items-center justify-between py-2 px-4 bg-white"
                          >
                            <div
                              className="w-1/3"
                              {...provided.dragHandleProps}
                            >
                              {title}
                            </div>
                            {item.__typename === 'VideoContentItem' ||
                              (item.__typename === 'RecipeContentItem' && (
                                <img
                                  src={item.thumbnailUrl}
                                  className="max-h-48 rounded"
                                />
                              ))}
                            <div className="w-1/3 flex space-x-3 items-center justify-end">
                              <div className="w-10">
                                <Button
                                  fullWidth
                                  onClick={(): void =>
                                    handleDeletePressed(item.id)
                                  }
                                  disabled={updatingCollection}
                                  color="danger"
                                  variant="text"
                                >
                                  <FaRegTrashAlt />
                                </Button>
                              </div>
                              <div
                                className="p-1"
                                {...provided.dragHandleProps}
                              >
                                <FaBars className="text-sm" />
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {/* maintains height of container during drag */}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Card>

        <AddCollectionItemContent
          collectionId={collectionId}
          itemIds={items.map((i) => i.id)}
          onContentAdded={handleContentAdded}
          onClear={clearChanges}
        />
      </div>
    </>
  );
}
