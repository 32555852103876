import React from 'react';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { AdminNotesStep } from './admin-notes-step';
import { AssignOption, AssignOptionStep } from './assign-option-step';
import { ConfirmStep } from './confirm-step';
import { DoctorAssignStep } from './doctor-assign-step';
import { SkipQuizStep } from './skip-quiz-step';
import { RequestReviewStepTransitionProps } from './types';
import { ProblemType, ReviewReason } from 'graphql/types';
import {
  BookingWindow,
  BookingWindowAssignStep,
} from './booking-window-assign-step';
import { Customer } from 'pages/customer/types';

type RequestReviewStep =
  | 'skipQuiz'
  | 'adminNotesAndReviewReason'
  | 'assignOption'
  | 'doctorAssign'
  | 'bookingWindowAssign'
  | 'confirm';

type RequestReviewProps = {
  onCancel: () => void;
  onCompleted: () => void;
  adminNotes?: string;
  problemType: ProblemType;
  customer: Customer;
};

export const RequestReview: React.FC<RequestReviewProps> = ({
  onCancel,
  onCompleted,
  problemType,
  customer,
}) => {
  const featureFlagClient = useFeatureFlagClient();
  const ffEnableSyncReviewConsults = featureFlagClient.getBoolean(
    'ff-enable-sync-review-consults',
  );

  const [step, setStep] = React.useState<RequestReviewStep>('skipQuiz');
  const [skipQuiz, setSkipQuiz] = React.useState(false);
  const [adminNotes, setAdminNotes] = React.useState<string>('');
  const [reason, setReason] = React.useState<ReviewReason | undefined>(
    undefined,
  );
  const [assignOption, setAssignOption] = React.useState<
    AssignOption | undefined
  >();
  const [doctorId, setDoctorId] = React.useState<string | undefined>();
  const [bookingWindow, setBookingWindow] = React.useState<
    BookingWindow | undefined
  >();

  const stepTransitions: Record<
    RequestReviewStep,
    RequestReviewStepTransitionProps
  > = {
    skipQuiz: {
      previousStep: onCancel,
      nextStep: (): void => setStep('adminNotesAndReviewReason'),
    },
    adminNotesAndReviewReason: {
      previousStep: (): void => setStep('skipQuiz'),
      nextStep: (): void => {
        if (skipQuiz) {
          if (ffEnableSyncReviewConsults) {
            setStep('assignOption');
          } else {
            setStep('doctorAssign');
          }
        } else {
          setStep('confirm');
        }
      },
    },
    assignOption: {
      previousStep: (): void => setStep('adminNotesAndReviewReason'),
      nextStep: (): void =>
        assignOption === 'queue'
          ? setStep('bookingWindowAssign')
          : setStep('doctorAssign'),
    },
    doctorAssign: {
      previousStep: (): void =>
        ffEnableSyncReviewConsults
          ? setStep('assignOption')
          : setStep('adminNotesAndReviewReason'),
      nextStep: (): void => setStep('confirm'),
    },
    bookingWindowAssign: {
      previousStep: (): void => setStep('assignOption'),
      nextStep: (): void => setStep('confirm'),
    },
    confirm: {
      previousStep: (): void => {
        if (skipQuiz) {
          if (assignOption === 'queue') {
            setStep('bookingWindowAssign');
          } else {
            setStep('doctorAssign');
          }
        } else {
          setStep('adminNotesAndReviewReason');
        }
      },
      nextStep: onCompleted,
    },
  };

  const renderStep = (): React.ReactElement => {
    switch (step) {
      case 'skipQuiz':
        return (
          <SkipQuizStep
            {...stepTransitions[step]}
            skipQuiz={skipQuiz}
            setSkipQuiz={setSkipQuiz}
          />
        );
      case 'adminNotesAndReviewReason':
        return (
          <AdminNotesStep
            {...stepTransitions[step]}
            customer={customer}
            skipQuiz={skipQuiz}
            setAdminNotes={setAdminNotes}
            notes={adminNotes}
            setReason={setReason}
            reason={reason}
            problemType={problemType}
          />
        );
      case 'assignOption':
        return (
          <AssignOptionStep
            {...stepTransitions[step]}
            assignOption={assignOption}
            setAssignOption={setAssignOption}
            customerHasMobileNumber={!!customer.phone?.length}
          />
        );
      case 'doctorAssign':
        return (
          <DoctorAssignStep
            {...stepTransitions[step]}
            doctorId={doctorId}
            setDoctorId={setDoctorId}
          />
        );
      case 'bookingWindowAssign':
        return (
          <BookingWindowAssignStep
            {...stepTransitions[step]}
            bookingWindow={bookingWindow}
            setBookingWindow={setBookingWindow}
            problemType={problemType}
          />
        );
      case 'confirm':
        return (
          <ConfirmStep
            {...stepTransitions[step]}
            skipQuiz={skipQuiz}
            adminNotes={adminNotes}
            reason={reason}
            assignOption={assignOption}
            doctorId={doctorId}
            bookingWindow={bookingWindow}
            customerId={customer.id}
            problemType={problemType}
          />
        );
    }
  };

  return (
    <div className="bg-white flex flex-col rounded p-4 space-y-4 w-full">
      {renderStep()}
    </div>
  );
};
