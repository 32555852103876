import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  ProblemType,
  TrackerDetails,
  UpdateWeightJourneyStartDateMutation,
  UpdateWeightJourneyStartDateMutationVariables,
  TrackingCustomerQuery,
  TrackingCustomerQueryVariables,
} from 'graphql/types';
import { useUrlQuery } from 'utils/use-url-query';
import { dedupeArray, formatDate, getProblemTypeFromString } from 'utils/misc';
import CustomerDetails from 'components/customer-details';
import { Loading } from 'components/loading';
import { ProblemTypeTag } from 'components/tags/problem-type';
import { Accordion, AccordionTitleTagProps } from 'components/accordion';
import { WeightLossQuestionnaire } from './questionnaire/weightLoss';
import { SkinTrackerQuestionnaire } from './questionnaire/skinTracker';
import { camelCaseToReadable } from './utils/camelCaseToReadable';
import { RefetchTrackerData } from './types';
import { HairTrackerQuestionnaire } from './questionnaire/hairTracker';
import { useForm } from 'react-hook-form-6';
import { Input } from 'components/react-hook-form-6/input';
import { Button } from 'components/button';
import { startOfDay } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useNotifications } from 'notifications';
import {
  combineRules,
  maxDateValidation,
  requiredValidation,
} from 'utils/form-validation';
import { config } from 'config';
import { FaDownload } from 'react-icons/fa';

const customerQuery = gql`
  query TrackingCustomer($where: UserWhereUniqueInput!) {
    customer: user(where: $where) {
      id
      firstName
      lastName
      fullName
      phone
      email
      weightLossJourneyStartDate
    }
  }
`;

const inputDateFormat = 'yyyy-MM-dd';

const trackingQuizQuery = gql`
  query GetTrackerDetailsForCustomerQuery(
    $customerId: String!
    $problemType: ProblemType!
  ) {
    trackerDetailsForCustomerQuery(
      customerId: $customerId
      problemType: $problemType
    ) {
      id
      submittedAt
      bodyWeight
      nominatedStartingValue
      bodyWeightMeta {
        id
        observationId
        deletedAt
        overrides {
          id
          createdAt
          bodyWeight
          bodyWeightMeta {
            id
            deletedAt
          }
        }
      }
      waistSize
      waistSizeMeta {
        id
        observationId
        deletedAt
        overrides {
          id
          createdAt
          waistSize
          waistSizeMeta {
            id
            deletedAt
          }
        }
      }
      skinConfidence
      skinConfidenceMeta {
        id
        observationId
        deletedAt
      }
      signedPhotoLeft
      signedPhotoCenter
      signedPhotoRight
      signedPhotoTop
      signedPhotoFront
      signedPhotoBack
      skinPhotosMeta {
        id
        observationId
        deletedAt
      }
      quizResponses {
        id
        submittedAt
        quizCode
        responses {
          question {
            id
            title(languageCode: EN_AU)
            options {
              value(languageCode: EN_AU)
            }
            type
          }
          response {
            flag {
              level
            }
            answers(languageCode: EN_AU) {
              value
              imageUrl
            }
          }
        }
      }
      __typename
    }
  }
`;

const convertToCsv = (arr: { [key: string]: string }[]): string => {
  const headerAndRows = [Object.keys(arr[0]), ...arr];
  return headerAndRows.map((row) => Object.values(row).toString()).join('\n');
};

const downloadArrayAsCsv = (
  obj: { [key: string]: string }[],
  fileName: string,
) => {
  const csvString = convertToCsv(obj);
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    URL.createObjectURL(
      new Blob([csvString], { type: 'text/csv;charset=utf-8;' }),
    ),
  );
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const csvDateFormat = 'yyyy-MM-dd HH:mm:ss (z)';

const TrackingCustomer = (): React.ReactElement => {
  const { customerId } = useParams<{ customerId: string }>();
  const urlQuery = useUrlQuery();
  const problemType = getProblemTypeFromString(urlQuery.get('pt') ?? undefined);

  const { data: customerData, loading: customerDataLoading } = useQuery<
    TrackingCustomerQuery,
    TrackingCustomerQueryVariables
  >(customerQuery, { variables: { where: { id: customerId } } });

  const {
    data: quizApplicationData,
    loading: quizApplicationLoading,
    refetch: quizApplicationRefetch,
  } = useQuery<
    {
      trackerDetailsForCustomerQuery: TrackerDetails[];
    },
    { customerId: string; problemType: ProblemType }
  >(trackingQuizQuery, {
    variables: {
      customerId,
      problemType: problemType ?? 'WEIGHT_LOSS',
    },
  });

  const trackerResponses = quizApplicationData?.trackerDetailsForCustomerQuery;
  const customer = customerData?.customer;

  if (quizApplicationLoading || customerDataLoading) {
    return <Loading />;
  }

  if (!problemType) {
    return <p>Problem type unknown.</p>;
  }

  if (!customer) {
    return <p>No patient with ID: {customerId} found.</p>;
  }

  if (!trackerResponses?.length) {
    return (
      <p>
        No tracker quiz responses for patient: {customerId} and problem type:{' '}
        {problemType}.
      </p>
    );
  }

  const quizCodeColorMap: {
    [key in string]: AccordionTitleTagProps['color'];
  } = {
    weightCalibrationQuiz: 'blue',
    weightCheckIn: 'green',
  };

  const getFormattedQuizCodeTagsForTrackerEntry = (
    trackerQuiz: TrackerDetails,
  ): AccordionTitleTagProps[] => {
    const quizCodes = trackerQuiz.quizResponses?.map(
      (quizResponse) => quizResponse.quizCode,
    );
    if (!quizCodes || quizCodes.length === 0) {
      const tags: AccordionTitleTagProps[] = [
        { text: 'observation', size: 'small' },
      ];
      if (trackerQuiz.nominatedStartingValue && !!trackerQuiz.bodyWeight) {
        tags.push({ text: 'starting weight', size: 'small', color: 'blue' });
      }

      return tags;
    }

    return dedupeArray(quizCodes).map((quizCode) => ({
      text: camelCaseToReadable(quizCode),
      size: 'small',
      color: quizCodeColorMap[quizCode] ?? 'primary',
    }));
  };

  return (
    <section className="flex space-x-4">
      <div className="w-3/4 space-y-6">
        {trackerResponses.map((trackerQuiz) => (
          <div className="bg-white" key={trackerQuiz.id}>
            <Accordion
              title={
                trackerQuiz.submittedAt
                  ? formatDate(new Date(trackerQuiz.submittedAt))
                  : 'Submitted date not available'
              }
              tags={getFormattedQuizCodeTagsForTrackerEntry(trackerQuiz)}
              tooltipHoverText={
                trackerQuiz.nominatedStartingValue && !!trackerQuiz.bodyWeight
                  ? 'Admins cannot edit or delete starting weight. Patients can edit their own starting weight via the mobile app.'
                  : undefined
              }
            >
              <TrackerQuestionnaire
                problemType={problemType}
                trackerQuiz={trackerQuiz}
                refetch={quizApplicationRefetch}
              />
            </Accordion>
          </div>
        ))}
      </div>
      <div className="w-1/4 border-l-2 px-4">
        <aside>
          <h2 className="heading-md mb-6">Tracker details</h2>
          {customer && (
            <div className="space-y-8 mb-8">
              <CustomerDetails customer={customer} problemType={problemType} />
              {problemType === 'WEIGHT_LOSS' &&
                customer.weightLossJourneyStartDate && (
                  <WeightLossJourneyStartDate
                    customerId={customer.id}
                    startDate={new Date(customer.weightLossJourneyStartDate)}
                  />
                )}
              {problemType === 'WEIGHT_LOSS' && (
                <Button
                  color="success"
                  fullWidth
                  onClick={() => {
                    const weightTrackerCsvData = trackerResponses.flatMap(
                      (quiz) => {
                        const rows: {
                          Value: string;
                          Type: string;
                          Date: string;
                        }[] = [];

                        if (!quiz.submittedAt) {
                          return rows;
                        }

                        if (
                          quiz.bodyWeight &&
                          !quiz.bodyWeightMeta?.deletedAt
                        ) {
                          rows.push({
                            Value: `${quiz.bodyWeight} kg`,
                            Type: 'Weight',
                            Date: formatInTimeZone(
                              new Date(quiz.submittedAt),
                              config.defaultTimezone,
                              csvDateFormat,
                            ),
                          });
                        }

                        if (quiz.waistSize && !quiz.waistSizeMeta?.deletedAt) {
                          rows.push({
                            Value: `${quiz.waistSize} cm`,
                            Type: 'Waist',
                            Date: formatInTimeZone(
                              new Date(quiz.submittedAt),
                              config.defaultTimezone,
                              csvDateFormat,
                            ),
                          });
                        }

                        return rows;
                      },
                    );

                    downloadArrayAsCsv(
                      weightTrackerCsvData,
                      `weight-tracker-history-${customer.id}.csv`,
                    );
                  }}
                >
                  <div className="flex flex-row gap-3 flex-1 text-sm text-center justify-center items-center">
                    <FaDownload size={20} />
                    Download tracking history
                  </div>
                </Button>
              )}
            </div>
          )}
          {problemType && (
            <>
              <h3 className="heading-sm mb-4">Problem Type</h3>
              <ProblemTypeTag problemType={problemType} />
            </>
          )}
        </aside>
      </div>
    </section>
  );
};

const TrackerQuestionnaire = ({
  trackerQuiz,
  problemType,
  refetch,
}: {
  trackerQuiz: TrackerDetails;
  problemType: ProblemType;
  refetch: RefetchTrackerData;
}): React.ReactElement => {
  switch (problemType) {
    case 'WEIGHT_LOSS':
      return (
        <WeightLossQuestionnaire trackerQuiz={trackerQuiz} refetch={refetch} />
      );
    case 'SKIN_GENERAL':
    case 'ACNE':
    case 'ANTI_AGEING':
      return (
        <SkinTrackerQuestionnaire trackerQuiz={trackerQuiz} refetch={refetch} />
      );
    case 'HAIR':
      return <HairTrackerQuestionnaire trackerQuiz={trackerQuiz} />;
    default:
      return <></>;
  }
};

const WeightLossJourneyStartDate = ({
  customerId,
  startDate,
}: {
  customerId: string;
  startDate: Date;
}): React.ReactElement => {
  const showNotification = useNotifications();
  const [editing, setEditing] = useState(false);
  const { register, handleSubmit, errors } = useForm<{
    selectedStartDate: string;
  }>();
  const formattedStartDateTzInput = formatInTimeZone(
    startDate,
    config.defaultTimezone,
    inputDateFormat,
  );
  const formattedStartDateTzDisplay = formatInTimeZone(
    startDate,
    config.defaultTimezone,
    'do MMM yyyy',
  );

  const [updateStartDate, { loading }] = useMutation<
    UpdateWeightJourneyStartDateMutation,
    UpdateWeightJourneyStartDateMutationVariables
  >(
    gql`
      mutation UpdateWeightJourneyStartDate(
        $input: WeightLossJourneyStartDateInput!
      ) {
        updateCustomerWeightLossJourneyStartDate(input: $input) {
          user {
            id
            weightLossJourneyStartDate
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Start date updated',
        });
        setEditing(false);
      },
    },
  );

  const handleStartDateSubmit = handleSubmit(async ({ selectedStartDate }) => {
    const dayStart = startOfDay(new Date(selectedStartDate));
    const startDateUtc = zonedTimeToUtc(dayStart, config.defaultTimezone);

    await updateStartDate({
      variables: {
        input: {
          userId: customerId,
          startDate: startDateUtc.toISOString(),
        },
      },
    });
  });

  return (
    <div>
      <h3 className="heading-sm mb-3">Weight journey start date</h3>
      <div className="bg-white p-4 shadow">
        {editing ? (
          <form className="space-y-3" onSubmit={handleStartDateSubmit}>
            <Input
              type="date"
              name="selectedStartDate"
              ref={register(
                combineRules(
                  requiredValidation('start date'),
                  maxDateValidation(new Date(), inputDateFormat),
                ),
              )}
              defaultValue={formattedStartDateTzInput}
              errorMessage={errors?.selectedStartDate?.message}
            />
            <div className="flex space-x-2 justify-end">
              <div className="flex-0">
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  onClick={() => setEditing(false)}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
              <div className="flex-0">
                <Button
                  fullWidth
                  size="small"
                  variant="solid"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <div className="flex space-x-2 justify-between items-center">
            <p>{formattedStartDateTzDisplay}</p>
            <div className="flex-0">
              <Button
                fullWidth
                size="small"
                variant="solid"
                onClick={() => setEditing(true)}
              >
                Edit
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingCustomer;
