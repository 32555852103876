import { Button } from 'components/button';
import { AddNoteModalProps } from 'components/notes/add-note/add-note-modal';
import { AddNoteModalButton } from 'components/notes/add-note/add-note-modal-button';
import { NotesTable } from 'components/notes/notes-table';
import { NoteRowFragment } from 'graphql/types';
import { useHistory } from 'react-router-dom';
import { buildRoute } from 'utils/routes';

const classNames = {
  buttonWrapper: 'ml-4',
};

export const ConsultationNotes: React.FC<{
  customerId: string;
  notes: NoteRowFragment[];
  consultation: AddNoteModalProps['consultations'][number];
}> = ({ customerId, notes, consultation }) => {
  const history = useHistory();

  return (
    <div className="bg-white shadow overflow-hidden rounded">
      <div className="px-4 pt-5 pb-2 border-b border-gray-200 text-gray-900 space-y-4">
        <div className="flex space-x-4">
          <h3 className="flex-grow text-lg leading-6 font-medium">
            Consultation Notes{' '}
            <span className="ml-2 text-gray-500">{notes.length}</span>
          </h3>

          <div className={classNames.buttonWrapper}>
            <Button
              variant="outline"
              size="small"
              onClick={() => history.push(buildRoute.customerNotes(customerId))}
            >
              View all notes
            </Button>
          </div>

          <div className={classNames.buttonWrapper}>
            <AddNoteModalButton
              customer={consultation.customer}
              consultations={[consultation]}
              defaultValues={{ consultation, canEditTarget: false }}
            />
          </div>
        </div>

        <div className="text-sm italic">
          Only displaying notes linked to this consult. Select{' '}
          <strong>View all</strong> to see all notes for this patient.
        </div>
      </div>
      <NotesTable notes={notes} />
    </div>
  );
};
