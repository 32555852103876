import React, { useState } from 'react';
import { CustomerOfferingPurchaseGroupView } from './types';
import { Button } from 'components/button';
import { Link } from 'react-router-dom';
import { buildRoute } from 'utils/routes';
import {
  PurchaseCancelReason,
  PurchaseStatus as PurchaseStatuses,
  Maybe,
} from 'graphql/types';
import { Tag } from 'components/tag';
import { FaChevronDown, FaChevronUp, FaInfoCircle } from 'react-icons/fa';
import { Copyable } from 'components/copyable';
import clsx from 'clsx';
import { purchaseCancelReasonToDisplayString } from '../../utils/misc';

export const OfferingSection = ({
  customerId,
  purchaseGroups,
}: {
  customerId: string;
  purchaseGroups: CustomerOfferingPurchaseGroupView[];
}): React.ReactElement | null => {
  return (
    <div className="overflow-hidden rounded shadow bg-white pt-2 border-b border-gray-200">
      <div className="border-b px-4 py-4 border-gray-200 flex justify-between items-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Purchased Offerings
          <span className="ml-2 text-gray-500">{purchaseGroups.length}</span>
        </h3>
      </div>
      {!!purchaseGroups.length && (
        <div className="p-4 space-y-3">
          {purchaseGroups.map((pg) => (
            <CustomerPurchaseGroup
              key={pg.id}
              purchaseGroup={pg}
              customerId={customerId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CustomerPurchaseGroup = ({
  customerId,
  purchaseGroup,
}: {
  purchaseGroup: CustomerOfferingPurchaseGroupView;
  customerId: string;
}): JSX.Element => {
  const [showOverridden, setShowOverridden] = useState(false);

  return (
    <div
      className={clsx('flex flex-col', {
        'border p-1 rounded border-gray-400':
          purchaseGroup.purchases.length > 1,
      })}
    >
      <div className="flex">
        <div className="flex flex-col flex-1 gap-1">
          {purchaseGroup.purchases.map((p) => (
            <CustomerPurchase
              key={p.id}
              purchase={p}
              customerId={customerId}
              showCscStatus={
                purchaseGroup.unmetActivationRequirements.length > 0
              }
            />
          ))}
        </div>
        {purchaseGroup.unmetActivationRequirements.length > 0 && (
          <div className="px-3 flex-1 text-slate-400 max-w-44">
            <div className="flex-1 text-xs flex flex-col gap-2">
              <div className="font-medium text-sm pt-1 pb-0.5">Awaiting</div>
              {purchaseGroup.unmetActivationRequirements.map((r) => (
                <p key={r} className="truncate">
                  {(() => {
                    switch (r) {
                      case 'PAYMENT':
                        return 'Payment';
                      case 'SIGNED_SCRIPTS':
                        return 'Signed Scripts';
                      case 'PRESCRIBED_SEQUENCE':
                        return 'Prescribed Sequence';
                      case 'PATIENT_CONFIRMATION':
                        return 'Patient Confirmation';
                      default:
                        return r;
                    }
                  })()}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
      {purchaseGroup.overridden.length > 0 && (
        <div
          onClick={() => setShowOverridden((s) => !s)}
          className="w-full mt-2 rounded select-none h-6 bg-slate-100 hover:bg-slate-200 cursor-pointer flex justify-center items-center"
        >
          <div className="text-xs text-slate-500 hover:text-slate-600 flex items-center gap-1">
            {showOverridden ? 'Hide' : 'Show'} Overridden
            {showOverridden ? (
              <FaChevronUp className="text-slate-400 hover:text-slate-500" />
            ) : (
              <FaChevronDown className="text-slate-400 hover:text-slate-500" />
            )}
          </div>
        </div>
      )}
      {showOverridden && (
        <div className="w-11/12 mt-2 flex flex-col self-end gap-2">
          <div className="text-xs rounded bg-slate-100 text-slate-500 py-2 px-4 flex items-center gap-4">
            <FaInfoCircle className="text-3xl text-slate-400" />
            Purchase groups are overridden if a customer has paid for another
            purchase group during the same session. This may be either due to
            payment failing for the first purchase or if the customer was
            prescribed something different during the consultation flow.
          </div>
          {purchaseGroup.overridden.map((pg) => (
            <div
              key={pg.id}
              className={clsx('flex flex-col', {
                'border p-1 rounded border-gray-400':
                  purchaseGroup.purchases.length > 1,
              })}
            >
              {pg.purchases.map((purchase) => (
                <div
                  key={purchase.id}
                  className="rounded overflow-hidden border border-gray-500"
                >
                  <div className="flex items-baseline justify-between border-b border-gray-200 bg-gray-100">
                    <div className="px-3 text-sm flex items-center gap-5">
                      <Copyable text={purchase.id}>
                        {(copied) => (
                          <pre className="text-xs cursor-pointer">
                            {copied ? 'Copied' : purchase.id.slice(-6)}
                          </pre>
                        )}
                      </Copyable>
                      <div className="font-medium">{purchase.name}</div>
                      <PurchaseStatus
                        status={purchase.status}
                        cancelReason={purchase.cancelReason}
                      />
                    </div>
                    <div className="p-3">
                      <Link
                        to={buildRoute.customerPurchase(
                          customerId,
                          purchase.id,
                        )}
                      >
                        <Button variant="outline" size="small">
                          View
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-1">
                      {purchase.sequenceContexts.map((sequenceContext) => {
                        return (
                          <div
                            key={sequenceContext.id}
                            className="border-b flex justify-between items-baseline border-gray-200"
                          >
                            <div className="px-3 py-2 text-xs">
                              {sequenceContext.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CustomerPurchase = ({
  purchase,
  showCscStatus,
  customerId,
}: {
  purchase: CustomerOfferingPurchaseGroupView['purchases'][number];
  customerId: string;
  showCscStatus: boolean;
}): JSX.Element => {
  return (
    <div className="flex flex-col gap-4">
      <div className="rounded overflow-hidden border border-gray-500">
        <div className="flex items-baseline justify-between border-b border-gray-200 bg-gray-100">
          <div className="px-3 text-sm flex items-center gap-5">
            <Copyable text={purchase.id}>
              {(copied) => (
                <pre className="text-xs cursor-pointer">
                  {copied ? 'Copied' : purchase.id.slice(-6)}
                </pre>
              )}
            </Copyable>
            <div className="flex flex-col">
              <div className="font-medium text-gray-800">{purchase.name}</div>
              <div className="font-light text-gray-800">
                {purchase.advertisedName}
              </div>
            </div>
            <PurchaseStatus
              status={purchase.status}
              cancelReason={purchase.cancelReason}
            />
          </div>
          <div className="p-3">
            <Link to={buildRoute.customerPurchase(customerId, purchase.id)}>
              <Button variant="outline" size="small">
                View
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            {purchase.sequenceContexts.map((sequenceContext) => (
              <div
                key={sequenceContext.id}
                className="border-b flex justify-between items-baseline border-gray-200"
              >
                <div className="px-3 py-2 text-xs flex flex-row gap-2 items-center">
                  <p>{sequenceContext.name}</p>
                  <div
                    className={
                      sequenceContext.addon ||
                      sequenceContext.prescribedSequences?.length
                        ? 'visible'
                        : 'invisible'
                    }
                  >
                    <Tag size="small" color="primary">
                      {sequenceContext.addon ? 'Add-on' : 'Prescribed'}
                    </Tag>
                  </div>
                </div>
                {!showCscStatus && (
                  <div
                    className={clsx('px-3 text-xs py-2', {
                      'text-green-800': sequenceContext.status === 'ACTIVE',
                      'text-red-800': sequenceContext.status === 'CANCELLED',
                      'text-yellow-800': sequenceContext.status === 'PAUSED',
                      'text-blue-800': sequenceContext.status === 'COMPLETED',
                    })}
                  >
                    {((s) =>
                      ({
                        ACTIVE: 'Active',
                        CANCELLED: 'Cancelled',
                        PAUSED: 'Paused',
                        COMPLETED: 'Completed',
                      })[s])(sequenceContext.status)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const PurchaseStatus = ({
  status,
  cancelReason,
}: {
  status: PurchaseStatuses;
  cancelReason: Maybe<PurchaseCancelReason>;
}): JSX.Element => {
  switch (status) {
    case 'ACTIVE':
      return (
        <Tag size="small" color="green">
          Active
        </Tag>
      );
    case 'CANCELLED': {
      return (
        <Tag size="small" color="red">
          {purchaseCancelReasonToDisplayString(cancelReason)}
        </Tag>
      );
    }
    case 'COMPLETED':
      return (
        <Tag size="small" color="gray">
          Completed
        </Tag>
      );
    case 'PAUSED':
      return (
        <Tag size="small" color="yellow">
          Paused
        </Tag>
      );
    case 'AWAITING_PAYMENT':
      return (
        <Tag size="small" color="yellow">
          Awaiting Payment
        </Tag>
      );
    case 'AWAITING_VALID_SCRIPTS':
      return (
        <Tag size="small" color="yellow">
          Awaiting Valid Scripts
        </Tag>
      );
    case 'AWAITING_PATIENT_CONFIRMATION':
      return (
        <Tag size="small" color="yellow">
          Awaiting Patient Confirmation
        </Tag>
      );
    case 'PENDING_ACTIVATION':
      return (
        <Tag size="small" color="orange">
          Pending Activation
        </Tag>
      );
    default:
      return (
        <Tag size="small" color="gray">
          {status}
        </Tag>
      );
  }
};
