import React, { ReactElement, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useParams,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './components/private-route';
import { routes } from './utils/routes';
import { GlobalLoader } from 'global-loader';
import { AlertProvider } from 'alert';
import { config } from 'config';
import Login from './pages/auth/login';
import Home from './pages/home';
import Consultations from './pages/consultations';
import Consultation from './pages/consultation';
import Customers from './pages/customers';
import Customer from './pages/customer';
import Chat from './pages/customer/chat';
import Clinicians from './pages/clinicians';
import Clinician from './pages/clinician';
import CreateClinician from './pages/clinician/new';
import HealthCoaches from './pages/health-coaches';
import HealthCoach from './pages/health-coach';
import CreateHealthCoach from './pages/health-coach/new';
import Products from './pages/products';
import Product from './pages/product';
import Permissions from './pages/permissions';
import Plans from './pages/plans';
import Plan from './pages/plan';
import CreatePlan from './pages/plans/new';
import Orders from './pages/orders';
import Order from './pages/order';
import Scripts from './pages/scripts';
import Script from './pages/script';
import Delivery from './pages/delivery';
import Pathology from './pages/pathology';
import Pathologies from './pages/pathologies';
import AwaitingScripts from './pages/awaiting-scripts';
import TrackingCustomer from './pages/tracking/tracking-customer';
import { TrackingList } from './pages/tracking/tracking-list';
import Queues from './pages/queues';
import SubscriptionPlans from './pages/subscription-plans';
import SubscriptionPlan from 'pages/subscription-plan';
import CreateSubscriptionPlan from 'pages/subscription-plans/new';
import ProgramsPage from 'pages/education/programs';
import ReadingsPage from 'pages/education/readings';
import ReadingPage from 'pages/education/reading';
import CollectionsPage from './pages/education/collections';
import { HasOneOfPermissions, HasPermissions } from './components/permissions';
import PackingBundles from 'pages/packing-bundles';
import PackingBundle from 'pages/packing-bundle';
import CreatePackingBundle from 'pages/packing-bundles/new';
import ContentItemsPage from 'pages/content/items';
import { ContentCollectionPage } from 'pages/content/collection';
import { ContentCollectionsPage } from 'pages/content/collections';
import { SequencesPage, CreateSequencePage } from './pages/sequences';
import { CreateOffering, ListOfferings } from './pages/offerings';
import Offering from './pages/offering';
import { SequencePage } from 'pages/sequence';
import { Purchase } from 'pages/purchase';
import { CoreOrder } from 'pages/core-order';
import Actions from './pages/actions';
import Pillars from './pages/pillars';
import Goals from './pages/goals';
import Goal from './pages/goal';
import Pillar from './pages/pillar';
import Shop from 'pages/shop';
import Notes from 'pages/notes';
import BatchJob from 'pages/batch-job';
import { CreateBatchJob, ListBatchJobs } from 'pages/batch-jobs';
import HealthCoachingFlows from 'pages/health-coaching';
import HealthCoachingFlow from 'pages/health-coaching/flow';
import { Discounts } from 'pages/discounts';
import Objectives from 'pages/objectives';
import Objective from 'pages/objective';
import Consignments from 'pages/consignments';

// Temporary component to punt user to old admin while pages are being worked on
const createOldAdminLauncher =
  (path: string, id = ''): (() => ReactElement) =>
  (): ReactElement => {
    const history = useHistory();
    const { [id]: itemId } = useParams<Record<typeof id, string>>();
    useEffect(() => {
      history.goBack();
      window.open(
        `${config.quizAdminUrl}${path}${itemId ? `/${itemId}` : ''}`,
        '_blank',
      );
    }, [history, itemId]);
    return <div />;
  };

const Flows = createOldAdminLauncher(routes.flows);

function Routes(): ReactElement {
  return (
    <BrowserRouter>
      <AlertProvider>
        <Switch>
          <Route path={routes.login}>
            <React.Suspense fallback={<GlobalLoader />}>
              <Login />
            </React.Suspense>
          </Route>
          <PrivateRoute path="/" exact>
            <Home />
          </PrivateRoute>
          <PrivateRoute path={routes.consultation}>
            <Consultation />
          </PrivateRoute>
          <PrivateRoute path={routes.consultations}>
            <Consultations />
          </PrivateRoute>
          <PrivateRoute path={routes.customer}>
            <Switch>
              <Route path={routes.customerPurchase}>
                <Purchase />
              </Route>
              <Route path={routes.chat}>
                <Chat />
              </Route>
              <Route path={routes.customerNotes}>
                <Notes />
              </Route>
              <Route>
                <Customer />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.customers}>
            <Customers />
          </PrivateRoute>
          <PrivateRoute path={routes.flows}>
            <Flows />
          </PrivateRoute>
          <PrivateRoute path={routes.clinicians}>
            <Switch>
              <Route path={routes.createClinician}>
                <CreateClinician />
              </Route>
              <Route path={routes.clinician}>
                <Clinician />
              </Route>
              <Route>
                <Clinicians />
              </Route>
            </Switch>
          </PrivateRoute>
          {config.healthCoachCreation && (
            <PrivateRoute path={routes.healthCoaches}>
              <Switch>
                <Route path={routes.createHealthCoach}>
                  <CreateHealthCoach />
                </Route>
                <Route path={routes.healthCoach}>
                  <HealthCoach />
                </Route>
                <Route>
                  <HealthCoaches />
                </Route>
              </Switch>
            </PrivateRoute>
          )}
          <PrivateRoute path={routes.delivery}>
            <HasOneOfPermissions
              permissions={[
                'VIEW_COLD_SHIPPING_PROVIDER',
                'UPDATE_COLD_SHIPPING_PROVIDER',
              ]}
            >
              <Delivery />
            </HasOneOfPermissions>
          </PrivateRoute>
          <PrivateRoute path={routes.salesChannels}>
            <Shop />
          </PrivateRoute>
          <PrivateRoute path={routes.pillar}>
            <Pillar />
          </PrivateRoute>
          <PrivateRoute path={routes.pillars}>
            <Pillars />
          </PrivateRoute>
          <PrivateRoute path={routes.goal}>
            <Goal />
          </PrivateRoute>
          <PrivateRoute path={routes.goals}>
            <Goals />
          </PrivateRoute>
          <PrivateRoute path={routes.product}>
            <Product />
          </PrivateRoute>
          <PrivateRoute path={routes.permissions}>
            <Permissions />
          </PrivateRoute>
          <PrivateRoute path={routes.products}>
            <Products />
          </PrivateRoute>
          <PrivateRoute path={routes.plans}>
            <Switch>
              <Route path={routes.createPlan}>
                <CreatePlan />
              </Route>
              <Route path={routes.plan}>
                <Plan />
              </Route>
              <Route>
                <Plans />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.discounts}>
            <Discounts />
          </PrivateRoute>
          <PrivateRoute path={routes.order}>
            <Order />
          </PrivateRoute>
          <PrivateRoute path={routes.orders}>
            <Orders />
          </PrivateRoute>
          <PrivateRoute path={routes.script}>
            <HasPermissions permissions={['VIEW_SCRIPTS']}>
              <Script />
            </HasPermissions>
          </PrivateRoute>
          <PrivateRoute path={routes.scripts}>
            <HasPermissions permissions={['VIEW_SCRIPTS']}>
              <Scripts />
            </HasPermissions>
          </PrivateRoute>
          <PrivateRoute path={routes.subscriptionPlans}>
            <Switch>
              <Route path={routes.createSubscriptionPlan}>
                <CreateSubscriptionPlan />
              </Route>
              <Route path={routes.subscriptionPlan}>
                <SubscriptionPlan />
              </Route>
              <Route>
                <SubscriptionPlans />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.pathology}>
            <Pathology />
          </PrivateRoute>
          <PrivateRoute path={routes.pathologies}>
            <Pathologies />
          </PrivateRoute>
          <PrivateRoute path={routes.awaitingScripts}>
            <AwaitingScripts />
          </PrivateRoute>
          <PrivateRoute path={routes.trackingCustomer}>
            <TrackingCustomer />
          </PrivateRoute>
          <PrivateRoute path={routes.tracking}>
            <TrackingList />
          </PrivateRoute>
          <PrivateRoute path={routes.queues}>
            <Queues />
          </PrivateRoute>
          <PrivateRoute path={routes.educationPrograms}>
            <ProgramsPage />
          </PrivateRoute>
          <PrivateRoute exact path={routes.educationReadings}>
            <ReadingsPage />
          </PrivateRoute>
          <PrivateRoute path={routes.educationReading}>
            <ReadingPage />
          </PrivateRoute>
          <PrivateRoute path={routes.educationCollections}>
            <CollectionsPage />
          </PrivateRoute>
          <PrivateRoute path={routes.contentItems}>
            <ContentItemsPage />
          </PrivateRoute>
          <PrivateRoute path={routes.contentCollection}>
            <ContentCollectionPage />
          </PrivateRoute>
          <PrivateRoute path={routes.contentCollections}>
            <ContentCollectionsPage />
          </PrivateRoute>
          <PrivateRoute path={routes.packingBundles}>
            <Switch>
              <Route path={routes.createPackingBundle}>
                <HasPermissions
                  permissions={['TRIGGER_PACKING_BUNDLE_CREATION']}
                >
                  <CreatePackingBundle />
                </HasPermissions>
              </Route>
              <Route path={routes.packingBundle}>
                <PackingBundle />
              </Route>
              <Route>
                <PackingBundles />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.sequences}>
            <Switch>
              <Route path={routes.createSequence}>
                <HasPermissions permissions={['EDIT_OFFERINGS']}>
                  <CreateSequencePage />
                </HasPermissions>
              </Route>
              <Route path={routes.sequence}>
                <SequencePage />
              </Route>
              <Route>
                <SequencesPage />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.offerings}>
            <Switch>
              <Route path={routes.createOffering}>
                <HasPermissions permissions={['EDIT_OFFERINGS']}>
                  <CreateOffering />
                </HasPermissions>
              </Route>
              <Route path={routes.offering}>
                <Offering />
              </Route>
              <Route>
                <ListOfferings />
              </Route>
            </Switch>
          </PrivateRoute>
          <PrivateRoute path={routes.coreOrder}>
            <CoreOrder />
          </PrivateRoute>
          <PrivateRoute path={routes.bulkActions}>
            <HasOneOfPermissions
              permissions={['BULK_PAUSE_PURCHASE', 'BULK_CREATE_CONSULTATION']}
            >
              <Switch>
                <Route path={routes.createBulkAction}>
                  <CreateBatchJob />
                </Route>
                <Route path={routes.bulkAction}>
                  <BatchJob />
                </Route>
                <Route>
                  <ListBatchJobs />
                </Route>
              </Switch>
            </HasOneOfPermissions>
          </PrivateRoute>
          <Redirect from="/orders/:orderId" to={routes.order} />
          <PrivateRoute path="/orders">
            <Redirect to={routes.orders} />
          </PrivateRoute>
          {config.healthCoachingFlows && (
            <PrivateRoute path={routes.healthCoachingFlows}>
              <Switch>
                <Route path={routes.healthCoachingFlow} exact>
                  <HealthCoachingFlow />
                </Route>
                <Route>
                  <HealthCoachingFlows />
                </Route>
              </Switch>
            </PrivateRoute>
          )}
          <PrivateRoute path={routes.objective}>
            <Objective />
          </PrivateRoute>
          <PrivateRoute path={routes.objectives}>
            <Objectives />
          </PrivateRoute>
          <PrivateRoute path={routes.actions}>
            <Actions />
          </PrivateRoute>
          <PrivateRoute path={routes.consignments}>
            <Consignments />
          </PrivateRoute>
          <Route>404</Route>
        </Switch>
      </AlertProvider>
    </BrowserRouter>
  );
}

export default Routes;
