import { Accordion } from 'components/accordion';
import { useForm, useFieldArray } from 'react-hook-form-6';
import { Button } from 'components/button';
import { useAlert } from 'alert';
import { Tooltip } from 'components/tooltip';
import React from 'react';
import { ErxMedicine } from 'graphql/types';
import { RxMedicationAccordionContents } from './medication-accordion';
import { useNotifications } from '../../../notifications';
import { useErxMedicineCrud } from '../../../utils/use-erx-medicine-crud';
import { removeBlankAttributesInObject } from '../../../utils/remove-blank-attributes';

const MIMS_UPDATES_EXPLANATION_LINK =
  'https://www.notion.so/Meds-Database-MIMS-Integration-20ebf823568e4b289b37d6be48beba92#596a2f8dfb834d3ba9aac1ed8d51ce8c';

export const RxMedicationsSection = ({
  register,
  control,
  setValue,
  getValues,
  errors,
  productId,
  refetch,
  trigger,
}: {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  setValue: ReturnType<typeof useForm>['setValue'];
  getValues: ReturnType<typeof useForm>['getValues'];
  errors: ReturnType<typeof useForm>['errors'];
  productId: string;
  refetch: () => Promise<void>;
  trigger: ReturnType<typeof useForm>['trigger'];
}): React.ReactElement => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'erxMedicines',
    keyName: 'key',
  });
  const showAlert = useAlert();
  const showNotification = useNotifications();
  const { createErxMedicine, updateErxMedicine, deleteErxMedicine } =
    useErxMedicineCrud();

  const onDeleteMedication = async (
    medicationNumber: number,
  ): Promise<void> => {
    const medicineSelected =
      getValues().erxMedicines[medicationNumber]?.productName;
    const medicineId = getValues().erxMedicines[medicationNumber]?.id;

    if (!!medicineSelected || !!medicineId) {
      const result = await showAlert({
        content: `Are you sure you want to delete this medication? Other unsaved changes on the page will be discarded`,
      });
      if (!result) {
        return;
      }
    }
    if (medicineId) {
      try {
        await deleteErxMedicine(medicineId);
        showNotification({
          message: `deleted medication`,
          type: 'success',
        });
      } catch {
        showNotification({
          message: `failed to delete the medication`,
          type: 'error',
        });
      }
    }
    remove(medicationNumber);
    await refetch();
  };

  const onSaveMedicine = async (medicationNumber: number): Promise<void> => {
    const valid = await trigger();
    if (!valid) return;
    const erxMedicine = getValues().erxMedicines[medicationNumber];
    try {
      await updateErxMedicine({
        ...removeBlankAttributesInObject(erxMedicine),
      });
      showNotification({
        message: `updated medication`,
        type: 'success',
      });
    } catch {
      showNotification({
        message: `failed to save the medication`,
        type: 'error',
      });
    }
  };

  const onCreateMedicine = async (medicationNumber: number): Promise<void> => {
    const valid = await trigger();
    if (!valid) return;
    const erxMedicine = getValues().erxMedicines[medicationNumber];
    try {
      await createErxMedicine({
        productId,
        ...erxMedicine,
        id: undefined,
        mimsSearch: undefined,
      });
      showNotification({
        message: `created medication`,
        type: 'success',
      });
    } catch {
      showNotification({
        message: `failed to create medication`,
        type: 'error',
      });
    }
    await refetch();
  };

  return (
    <div>
      <div className="flex width-full justify-between mb-2">
        <h2 className="heading-md inline-block">
          Medications
          <Tooltip
            hoverText="We are now listing medications individually. Click for more info."
            link={MIMS_UPDATES_EXPLANATION_LINK}
          />
        </h2>
        <div>
          {fields.length < 4 && (
            <Button
              fullWidth
              type="button"
              onClick={(): void => {
                append({
                  name: `Medication ${fields.length + 1}`,
                });
              }}
            >
              + Add medication
            </Button>
          )}
        </div>
      </div>
      <ul>
        <div className="grid grid-cols-2 gap-x-5">
          {fields.map((medication, i) => (
            <li key={medication.key}>
              <div className="bg-white mt-2 mb-2">
                <input
                  name={`erxMedicines[${i}].id`}
                  value={medication.id}
                  className="hidden"
                  ref={register()}
                  readOnly
                />
                <Accordion title={`medication ${i + 1}`} overflow="visible">
                  <>
                    <RxMedicationAccordionContents
                      register={register}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      medicationNumber={i}
                      medication={medication as ErxMedicine}
                      onDelete={onDeleteMedication}
                      onSave={onSaveMedicine}
                      onCreate={onCreateMedicine}
                    />
                  </>
                </Accordion>
              </div>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};
