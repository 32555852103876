import {
  ConsultationPatientQuestion,
  ConsultationQuestion,
} from 'graphql/types';

function isConsultationQuestion(
  question: ConsultationQuestion | ConsultationPatientQuestion,
): question is ConsultationQuestion {
  return (question as ConsultationQuestion).allowedAnswerType !== undefined;
}

export const isImageAnswer = (
  item: ConsultationQuestion | ConsultationPatientQuestion,
): boolean => {
  if (isConsultationQuestion(item)) {
    return item.allowedAnswerType === 'IMAGE';
  } else {
    return false;
  }
};

export const isObservationAnswer = (
  item: ConsultationQuestion | ConsultationPatientQuestion,
): boolean => {
  if (isConsultationQuestion(item)) {
    return item.allowedAnswerType === 'OBSERVATION';
  } else {
    return false;
  }
};

export const isNoReplyAnswer = (
  item: ConsultationQuestion | ConsultationPatientQuestion,
): boolean => {
  if (isConsultationQuestion(item)) {
    return item.allowedAnswerType === 'NO_REPLY';
  } else {
    return false;
  }
};
