import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { useFeatureFlagClient } from '@eucalyptusvc/react-ff-client';
import { Button, ProtectedButton } from 'components/button';
import { CopyToClipboardButton } from 'components/copy-to-clipboard-button';
import { DataField } from 'components/data-field';
import { Loading } from 'components/loading';
import { Tag } from 'components/tag';
import {
  SequenceStatus as SequenceStatusType,
  UpdateSequenceMutation,
  UpdateSequenceMutationVariables,
  SequencePageQuery,
  DeprecateSequenceMutation,
  DeprecateSequenceMutationVariables,
  SequencePageQueryVariables,
  CreateSequenceNodeInput,
  DuplicateSequenceMutationVariables,
  DuplicateSequenceMutation,
  AddSafetyInformationMutation,
  AddSafetyInformationMutationVariables,
  AppendSequenceFaqMutation,
  AppendSequenceFaqMutationVariables,
  UpdateSequenceExternalLinksMutation,
  UpdateSequenceExternalLinksMutationVariables,
} from 'graphql/types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  upperSnakeCaseToCapitalCase,
  getProblemTypeColor,
  getProblemTypeEmoji,
} from 'utils/misc';
import { Faq } from './faq';
import { SequenceSafetyInformation } from './safetyInformation';
import { useFieldArray, useForm } from 'react-hook-form-6';
import { requiredValidation } from 'utils/form-validation';
import { Input } from 'components/react-hook-form-6/input';
import { Node, sequenceNodeFragment } from 'components/sequence-editor/node';
import {
  SequenceEditor,
  queryNodesToSequenceEditorNodes,
} from 'components/sequence-editor/editor';
import { useNotifications } from 'notifications';
import { routes } from 'utils/routes';
import { v4, validate } from 'uuid';
import { usePrevious } from 'utils/use-previous';
import { useHasPermissions } from 'components/permissions';
import { ContextMenu } from 'components/context-menu';
import { CreatableDropdown } from 'components/creatable-dropdown';

const headingStyle = 'flex px-4 py-5 border-b border-grey-200';

export const SequencePage = (): JSX.Element => {
  const history = useHistory();
  const showNotification = useNotifications();
  const canEditOfferings = useHasPermissions(['EDIT_OFFERINGS']);
  const [showSafetyInformation, setShowSafetyInformation] = useState(false);
  const [showCreateFaqForm, setShowCreateFaqForm] = useState(false);
  const [nodes, setNodes] = useState<Array<Node> | undefined>(undefined);
  const { sequenceId } = useParams<{ sequenceId: string }>();
  const previousSequenceId = usePrevious(sequenceId);
  const [loadingSequence, setLoadingSequence] = useState(validate(sequenceId));
  const canUpdateSequenceStatus = useHasPermissions(['UPDATE_SEQUENCE_STATUS']);

  const featureFlagClient = useFeatureFlagClient();
  const validInternalTriggerTopics = featureFlagClient.getJson(
    'internal-trigger-valid-message-topics',
    { defaultValue: [] },
  );

  useEffect(() => {
    if (previousSequenceId && sequenceId !== previousSequenceId) {
      setLoadingSequence(true);
    }
  }, [sequenceId, previousSequenceId]);

  const sequenceDetailsForm = useForm<{
    internalName: string;
    friendlyName: string;
    description: string;
    tags: { label: string; value: string }[];
  }>();
  const externalLinkForm = useForm<{
    links?: { linkId?: string; url?: string; text?: string }[];
  }>();
  const linksArray = useFieldArray({
    name: 'links',
    control: externalLinkForm.control,
  });
  const [updateExternalLinks, { loading: updateExternalLinksLoading }] =
    useMutation<
      UpdateSequenceExternalLinksMutation,
      UpdateSequenceExternalLinksMutationVariables
    >(
      gql`
        mutation UpdateSequenceExternalLinks(
          $input: UpdateSequenceExternalLinksInput!
        ) {
          updateSequenceExternalLinks(input: $input) {
            sequence {
              id
              externalLinks {
                id
                url
                text
              }
            }
          }
        }
      `,
      {
        onCompleted: (data) => {
          externalLinkForm.reset({
            links:
              data.updateSequenceExternalLinks?.sequence?.externalLinks.map(
                (l) => ({
                  linkId: l.id,
                  url: l.url,
                  text: l.text,
                }),
              ) || [],
          });
        },
      },
    );

  const { data, error: getSequenceError } = useQuery<
    SequencePageQuery,
    SequencePageQueryVariables
  >(
    gql`
      query SequencePage($id: ID!) {
        sequence(id: $id) {
          id
          friendlyName
          internalName
          problemTypes
          description
          status
          canBeDeprecated
          externalLinks {
            id
            url
            text
          }
          tags
          nodes {
            ...SequenceEditorSequenceNode
          }
          startNode {
            id
          }
          safetyInformation {
            id
            doctorLetterTemplate
            cmiUrl
            cmiCopyMarkdown
          }
          faqs {
            id
            question
            answerMarkdown
            videoUrl
            rank
          }
        }
        variants {
          id
          name
          inventory {
            id
            sku
          }
          product {
            id
            name
            flexiCompatible
            productType
          }
        }
        selectableSequenceTags
      }
      ${sequenceNodeFragment}
    `,
    {
      variables: {
        id: sequenceId,
      },
      skip: !validate(sequenceId),
      onError: () => {
        setLoadingSequence(false);
      },
      onCompleted: (data) => {
        if (data.sequence?.safetyInformation) {
          setShowSafetyInformation(true);
        } else {
          setShowSafetyInformation(false);
        }
        setShowCreateFaqForm(false);

        sequenceDetailsForm.reset({
          internalName: data.sequence?.internalName,
          friendlyName: data.sequence?.friendlyName,
          description: data.sequence?.description,
          tags: (data.sequence?.tags ?? []).map((tag) => ({
            label: tag,
            value: tag,
          })),
        });
        setNodes(queryNodesToSequenceEditorNodes(data.sequence?.nodes || []));
        setLoadingSequence(false);

        externalLinkForm.reset({
          links:
            data.sequence?.externalLinks.map((l) => ({
              linkId: l.id,
              url: l.url,
              text: l.text,
            })) || [],
        });
      },
    },
  );

  const [updateSequence, { loading: loadingUpdateSequence }] = useMutation<
    UpdateSequenceMutation,
    UpdateSequenceMutationVariables
  >(
    gql`
      mutation UpdateSequence($input: UpdateSequenceInput!) {
        updateSequence(input: $input) {
          sequence {
            id
            friendlyName
            internalName
            description
            status
            tags
            nodes {
              id
              nextNodeId
              ... on WaitSequenceNode {
                intervalType: type
                count
              }
              ... on EventSequenceNode {
                name
              }
              ... on VariantSequenceNode {
                price
                quantity
                variant {
                  id
                }
                maximumDurationInDaysToNextVariantNode
              }
              ... on InternalTriggerSequenceNode {
                triggerName
                topic
                label
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Sequence updated successfully',
        });
      },
    },
  );

  const [deprecateSequence, { loading: loadingDeprecateSequence }] =
    useMutation<DeprecateSequenceMutation, DeprecateSequenceMutationVariables>(
      gql`
        mutation DeprecateSequence($input: DeprecateSequenceInput!) {
          deprecateSequence(input: $input) {
            sequence {
              id
              status
              canBeDeprecated
            }
          }
        }
      `,
    );

  const [addSafetyInfo] = useMutation<
    AddSafetyInformationMutation,
    AddSafetyInformationMutationVariables
  >(addSafetyInfoMutation, {
    onError: () =>
      showNotification({
        type: 'warning',
        message: 'Failed to add sequence safety information',
      }),
  });
  const [appendSequenceFaq] = useMutation<
    AppendSequenceFaqMutation,
    AppendSequenceFaqMutationVariables
  >(appendSequenceFaqMutation, {
    onError: () =>
      showNotification({
        type: 'warning',
        message: 'Failed to append FAQ',
      }),
  });

  const [duplicatingSequence, setDuplicatingSequence] = useState(false);

  const [duplicateSequence] = useMutation<
    DuplicateSequenceMutation,
    DuplicateSequenceMutationVariables
  >(duplicateSequenceMutation);

  const duplicateSequenceWithSafetyInfoAndFaqs = async (
    sequence: NonNullable<SequencePageQuery['sequence']>,
  ) => {
    setDuplicatingSequence(true);
    // A bit odd, but we have both these mapping functions already, so
    // why not use them rather than creating a new function that directly maps.
    const originalSequenceNodes = nodeToCreateSequenceNodeInputs(
      queryNodesToSequenceEditorNodes(sequence.nodes),
    );
    const [nodes, startNodeId] = regenerateNodeIds(
      originalSequenceNodes,
      sequence.startNode.id,
    );
    try {
      const { data } = await duplicateSequence({
        variables: {
          duplicateSequenceInput: {
            description: sequence.description,
            friendlyName: `(COPY) ${sequence.friendlyName}`,
            internalName: `(COPY) ${sequence.internalName}`,
            nodes,
            problemTypes: sequence.problemTypes,
            status: 'DRAFT',
            startNodeId,
          },
        },
      });

      const createdSequenceId = data?.createSequence?.sequence.id;
      if (!createdSequenceId) {
        throw new Error('Expected the id for the new sequence');
      }
      const additionalDataPromises = [];
      if (sequence?.safetyInformation) {
        additionalDataPromises.push(
          addSafetyInfo({
            variables: {
              input: {
                sequenceId: createdSequenceId,
                cmiUrl: sequence.safetyInformation.cmiUrl,
                cmiCopyMarkdown: sequence.safetyInformation.cmiCopyMarkdown,
                doctorLetterTemplate:
                  sequence.safetyInformation.doctorLetterTemplate,
              },
            },
          }),
        );
      }
      additionalDataPromises.push(
        ...(sequence?.faqs || []).map((f) =>
          appendSequenceFaq({
            variables: {
              input: {
                sequenceId: createdSequenceId,
                answerMarkdown: f.answerMarkdown,
                question: f.question,
                videoUrl: f.videoUrl || undefined,
              },
            },
          }),
        ),
      );
      await Promise.allSettled(additionalDataPromises);
      history.push(`${routes.sequences}/${createdSequenceId}`);
      showNotification({
        type: 'success',
        message: 'Successfully duplicated sequence',
      });
    } catch {
      showNotification({
        type: 'error',
        message: 'Error duplicating sequence',
      });
    }
    setDuplicatingSequence(false);
  };

  const sequence = data?.sequence;

  if (loadingSequence) {
    return <Loading />;
  }

  if (getSequenceError) {
    return <div>Error. Please contact #help-technology.</div>;
  }

  if (!sequence) {
    return (
      <div>No sequence with the id: &quot;{sequenceId}&quot; was found.</div>
    );
  }

  const onSubmitSequenceDetailsForm = sequenceDetailsForm.handleSubmit(
    async (values) => {
      await updateSequence({
        variables: {
          input: {
            id: sequence.id,
            status: sequence.status,
            internalName: values.internalName,
            friendlyName: values.friendlyName,
            description: values.description,
            tags: values.tags.map(({ value }) => value),
          },
        },
      });
    },
  );

  const submitSequenceNodes = async (nodes: Array<Node>) => {
    // Ensure the save/discard buttons are hidden on sequence editor
    setNodes(nodes);
    const sn = nodeToCreateSequenceNodeInputs(nodes);

    const updateSequenceInput: UpdateSequenceMutationVariables['input'] = {
      id: sequence.id,
      status: sequence.status,
      internalName: sequence.internalName,
      friendlyName: sequence.friendlyName,
      description: sequence.description,
      tags: sequence.tags,
      nodes: sn,
      startNodeId: sn[0].id,
    };

    try {
      await updateSequence({
        variables: {
          input: updateSequenceInput,
        },
      });
    } catch (e) {
      if (e instanceof ApolloError) {
        // Ensure save / discard buttons are shown in sequence editor
        // to allow another attempt at saving
        setNodes(queryNodesToSequenceEditorNodes(data.sequence?.nodes || []));
      }
    }
  };

  const sortedFaqs = [...sequence.faqs].sort(
    (f1, f2) => f1?.rank || 0 - f2?.rank || 0,
  );

  const variants: Parameters<typeof SequenceEditor>[0]['variants'] = [];
  for (const v of data?.variants ?? []) {
    if (v.product.flexiCompatible) {
      variants.push({
        id: v.id,
        name: v.name,
        productId: v.product.id,
        productName: v.product.name,
        productType: v.product.productType,
        sku: v.inventory?.sku || '',
        maximumDurationInDaysToNextVariantNode: null,
      });
    }
  }

  return (
    <>
      <div className="flex flex-col space-y-8">
        <div className="bg-white shadow overflow-hidden rounded mb-2">
          <div className="flex flex-row items-center justify-between">
            <div>
              <h2 className={`${headingStyle} text-lg font-medium`}>
                Sequence
              </h2>
            </div>
            <div className="mx-4">
              <ContextMenu
                copy="Actions"
                disabled={!canEditOfferings}
                loading={
                  (sequence.status !== 'AVAILABLE' && loadingUpdateSequence) ||
                  loadingDeprecateSequence
                }
                options={(() => {
                  const options = [
                    {
                      copy: 'Make Available',
                      onClick: async () => {
                        await updateSequence({
                          variables: {
                            input: {
                              id: sequence.id,
                              description: sequence.description,
                              friendlyName: sequence.friendlyName,
                              internalName: sequence.internalName,
                              tags: sequence.tags,
                              status: 'AVAILABLE',
                            },
                          },
                        });
                      },
                      disabled:
                        !(
                          sequence.status === 'DRAFT' ||
                          sequence.status === 'UNAVAILABLE' ||
                          sequence.status === 'PHASING_OUT' ||
                          sequence.status === 'DEPRECATED'
                        ) || !canUpdateSequenceStatus,
                    },
                    {
                      copy: 'Phase Out',
                      onClick: async () => {
                        await updateSequence({
                          variables: {
                            input: {
                              id: sequence.id,
                              description: sequence.description,
                              friendlyName: sequence.friendlyName,
                              internalName: sequence.internalName,
                              tags: sequence.tags,
                              status: 'PHASING_OUT',
                            },
                          },
                        });
                      },
                      disabled:
                        sequence.status === 'PHASING_OUT' ||
                        !canUpdateSequenceStatus,
                    },
                    {
                      copy: 'Make unavailable',
                      onClick: async () => {
                        await updateSequence({
                          variables: {
                            input: {
                              id: sequence.id,
                              description: sequence.description,
                              friendlyName: sequence.friendlyName,
                              internalName: sequence.internalName,
                              tags: sequence.tags,
                              status: 'UNAVAILABLE',
                            },
                          },
                        });
                      },
                      disabled:
                        sequence.status === 'UNAVAILABLE' ||
                        sequence.status === 'DEPRECATED' ||
                        !canUpdateSequenceStatus,
                    },
                    {
                      copy: 'Deprecate Sequence',
                      onClick: async () => {
                        await deprecateSequence({
                          variables: { input: { sequenceId: sequence.id } },
                        });
                      },
                      disabled:
                        !sequence.canBeDeprecated || !canUpdateSequenceStatus,
                    },
                    {
                      copy: 'Duplicate',
                      onClick: () =>
                        duplicateSequenceWithSafetyInfoAndFaqs(sequence),
                      disabled: duplicatingSequence,
                    },
                  ].filter((o) => !o.disabled);

                  return options;
                })()}
              />
            </div>
          </div>
          <div>
            <DataField label="ID">
              <span className="text-xs">{sequence.id}</span>{' '}
              <CopyToClipboardButton value={sequence.id} />
            </DataField>
            <DataField label="Status">
              <SequenceStatus status={sequence.status} />
            </DataField>
            <DataField label="Problem Types">
              {sequence.problemTypes.map((pt) => (
                <span key={pt} className="px-1">
                  <Tag size="small" color={getProblemTypeColor(pt)}>
                    <span role="img" className="pr-1">
                      {getProblemTypeEmoji(pt)}
                    </span>
                    {upperSnakeCaseToCapitalCase(pt)}
                  </Tag>
                </span>
              ))}
            </DataField>
          </div>
        </div>

        <div className="bg-white shadow rounded mb-2 pb-4">
          <h2 className={`${headingStyle} text-lg font-medium`}>
            Sequence Details
          </h2>
          <form onSubmit={onSubmitSequenceDetailsForm}>
            <div className="space-y-4 p-4">
              <Input
                name="internalName"
                label="Internal Name"
                ref={sequenceDetailsForm.register(
                  requiredValidation('internal name'),
                )}
                errorMessage={sequenceDetailsForm.errors.internalName?.message}
              />
              <Input
                name="friendlyName"
                label="Patient Facing Friendly Name"
                ref={sequenceDetailsForm.register(
                  requiredValidation('friendly name'),
                )}
                errorMessage={sequenceDetailsForm.errors.friendlyName?.message}
              />

              <Input
                name="description"
                label="Description"
                ref={sequenceDetailsForm.register(
                  requiredValidation('description'),
                )}
                errorMessage={sequenceDetailsForm.errors.description?.message}
              />
              <CreatableDropdown
                name="tags"
                label="Tags"
                control={sequenceDetailsForm.control}
                rules={{
                  validate: (tags) => {
                    if (Array.isArray(tags)) {
                      let exclusiveTags = 0;
                      for (const t of tags) {
                        if ('value' in t && typeof t.value === 'string') {
                          exclusiveTags += Number(
                            t.value === 'initial' || t.value === 'repeat',
                          );
                        }
                      }

                      if (exclusiveTags > 1) {
                        return 'Only one of "initial" or "repeat" tags can be selected';
                      }
                    }

                    return true;
                  },
                }}
                tooltip={{ hoverText: 'Add/Update tags to a sequence.' }}
                errorMessage={
                  // @ts-expect-error - RHF incorrectly believes that these objects
                  // are nested form elements rather than an array of objects as
                  // a value.
                  sequenceDetailsForm.errors.tags?.message
                }
                options={(data.selectableSequenceTags ?? []).map((tag) => ({
                  label: tag,
                  value: tag,
                }))}
              />
              <div className="flex justify-end">
                <ProtectedButton
                  requiredPermissions={['EDIT_OFFERINGS']}
                  loading={loadingUpdateSequence}
                  type="submit"
                >
                  Update
                </ProtectedButton>
              </div>
            </div>
          </form>
        </div>

        <div className="bg-white shadow overflow-hidden rounded mb-2 pb-4">
          <h2 className={`${headingStyle} text-lg font-medium`}>
            Safety Information
          </h2>
          {!sequence.safetyInformation && (
            <div className="space-y-2 p-4">
              <div className="font-bold">
                There is no safety information associated with this sequence.
              </div>
              {!showSafetyInformation && (
                <div className="w-2/5 m-1">
                  <Button
                    fullWidth
                    onClick={(): void => setShowSafetyInformation(true)}
                  >
                    Add Safety Information
                  </Button>
                </div>
              )}
            </div>
          )}

          {showSafetyInformation && (
            <div className="mx-4">
              <SequenceSafetyInformation
                sequenceId={sequence.id}
                safetyInformation={sequence.safetyInformation}
                hideShowSafetyInformation={(): void =>
                  setShowSafetyInformation(false)
                }
              />
            </div>
          )}
        </div>

        {nodes && (
          <div className="bg-white shadow overflow-hidden rounded mb-2">
            <h2 className={`${headingStyle} text-lg font-medium`}>
              Sequence Preview
            </h2>
            <div className="h-128">
              {sequence.status === 'DRAFT' ? (
                <SequenceEditor
                  nodes={nodes}
                  variants={variants}
                  onSave={(nodes) => {
                    submitSequenceNodes(nodes);
                  }}
                  validTopics={validInternalTriggerTopics}
                />
              ) : (
                <SequenceEditor
                  nodes={nodes}
                  variants={variants}
                  readOnly={true}
                  validTopics={validInternalTriggerTopics}
                />
              )}
            </div>
          </div>
        )}
        <div>
          <h2 className={`py-5 text-lg font-medium`}>
            Frequently Asked Question{sequence.faqs.length !== 1 && 's'}
          </h2>
          {sortedFaqs.length === 0 && (
            <div>There are no FAQs associated with this sequence.</div>
          )}
          <div className="flex flex-wrap w-full">
            {sortedFaqs.map((faq) => {
              return (
                <Faq
                  sequenceId={sequence.id}
                  key={faq.id}
                  mode={'EDIT'}
                  faq={faq}
                />
              );
            })}

            {showCreateFaqForm && (
              <Faq
                mode={'CREATE'}
                sequenceId={sequence.id}
                setShowFaqFormFalse={(): void => setShowCreateFaqForm(false)}
              />
            )}
          </div>
          {!showCreateFaqForm && (
            <div className="pt-2 float-right w-1/5">
              <ProtectedButton
                fullWidth
                requiredPermissions={['EDIT_OFFERINGS']}
                onClick={(): void => setShowCreateFaqForm(true)}
              >
                Add FAQ
              </ProtectedButton>
            </div>
          )}
        </div>
        <div>
          <h2 className="py-5 text-lg font-medium">External Links</h2>

          <form
            onSubmit={externalLinkForm.handleSubmit(async (data) => {
              await updateExternalLinks({
                variables: {
                  input: {
                    links:
                      data.links?.map((l) => ({
                        id: l.linkId || v4(),
                        text: l.text || '',
                        url: l.url || '',
                      })) || [],
                    sequenceId: sequence.id,
                  },
                },
                onCompleted: () => {
                  showNotification({
                    type: 'success',
                    message: 'External links updated successfully',
                  });
                },
              });
            })}
            className="flex flex-col w-full bg-white rounded shadow p-4 m-2"
          >
            {sequence.externalLinks.length === 0 && (
              <div>
                There are no external links associated with this sequence.
              </div>
            )}
            {linksArray.fields.map((link, i) => {
              return (
                <div
                  key={link.id}
                  className="p-4 border-2 bg-white rounded m-2 w-full gap-2 flex flex-col"
                >
                  <Input
                    ref={externalLinkForm.register()}
                    label="Text"
                    name={`links.[${i}].text`}
                    errorMessage={
                      externalLinkForm.errors.links?.[i]?.text?.message
                    }
                  />
                  <Input
                    ref={externalLinkForm.register({
                      validate: {
                        isUrl: (value: string | undefined) => {
                          if (!value) return false;
                          try {
                            new URL(value);
                            return true;
                          } catch {
                            return 'Please enter a valid URL including http:// or https://';
                          }
                        },
                        noWhitespace: (value: string | undefined) => {
                          if (value?.includes(' ')) {
                            return 'URL cannot contain whitespace';
                          }
                          return true;
                        },
                      },
                    })}
                    label="Url"
                    name={`links.[${i}].url`}
                    errorMessage={
                      externalLinkForm.errors.links?.[i]?.url?.message
                    }
                  />
                  <div>
                    Preview:{' '}
                    <a
                      className="text-blue-600 underline"
                      href={externalLinkForm.watch()?.links?.[i]?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {externalLinkForm.watch()?.links?.[i]?.text}
                    </a>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      size="small"
                      color="danger"
                      type="button"
                      onClick={() => linksArray.remove(i)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
            <div className="pt-2 w-full flex flex-row gap-4 justify-end">
              <ProtectedButton
                requiredPermissions={['EDIT_OFFERINGS']}
                onClick={() => linksArray.append({})}
              >
                Add External Link
              </ProtectedButton>
              <ProtectedButton
                requiredPermissions={['EDIT_OFFERINGS']}
                color="success"
                loading={updateExternalLinksLoading}
                type="submit"
                disabled={!externalLinkForm.formState.isDirty}
              >
                Save
              </ProtectedButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const SequenceStatus = ({
  status,
}: {
  status: SequenceStatusType;
}): JSX.Element => {
  switch (status) {
    case 'AVAILABLE':
      return (
        <Tag size="small" color="green">
          Available
        </Tag>
      );
    case 'DRAFT':
      return (
        <Tag size="small" color="gray">
          Draft
        </Tag>
      );
    case 'UNAVAILABLE':
      return (
        <Tag size="small" color="blue">
          Unavailable
        </Tag>
      );
    case 'PHASING_OUT':
      return (
        <Tag size="small" color="yellow">
          Phasing Out
        </Tag>
      );
    case 'DEPRECATED':
      return (
        <Tag size="small" color="red">
          Deprecated
        </Tag>
      );
    default:
      return <div>{status}</div>;
  }
};

function nodeToCreateSequenceNodeInputs(
  nodes: Node[],
): CreateSequenceNodeInput[] {
  const sn: CreateSequenceNodeInput[] = [];

  for (const n of nodes) {
    let node: CreateSequenceNodeInput;

    switch (n.nodeType) {
      case 'wait': {
        node = {
          id: n.id,
          nextNodeId: n.nextNodeId,
          type: 'WAIT',
          wait: {
            count: n.count,
            interval: n.intervalType,
          },
        };
        break;
      }
      case 'event': {
        node = {
          id: n.id,
          nextNodeId: n.nextNodeId,
          type: 'EVENT',
          event: {
            body: '',
            name: n.name,
          },
        };
        break;
      }
      case 'variant': {
        node = {
          id: n.id,
          nextNodeId: n.nextNodeId,
          type: 'VARIANT',
          variant: {
            id: n.variantId,
            price: n.price,
            quantity: n.quantity,
            maximumDurationInDaysToNextVariantNode:
              n.maximumDurationInDaysToNextVariantNode,
          },
        };
        break;
      }
      case 'internal_trigger': {
        node = {
          id: n.id,
          nextNodeId: n.nextNodeId,
          type: 'INTERNAL_TRIGGER',
          internalTrigger: {
            name: n.triggerName,
            topic: n.topic,
            label: n.label,
          },
        };
        break;
      }
    }

    sn.push(node);
  }

  return sn;
}

function regenerateNodeIds(
  nodes: CreateSequenceNodeInput[],
  startNodeId: string,
): [CreateSequenceNodeInput[], string] {
  const newNodes: CreateSequenceNodeInput[] = [];
  const oldNodeIdToNewNodeId = new Map<string, string>();

  // First pass to generate new ids for all the nodes
  for (const node of nodes) {
    const newNodeId = v4();
    oldNodeIdToNewNodeId.set(node.id, newNodeId);
    newNodes.push({ ...node, id: newNodeId });
  }

  // Second pass to replace nextNodeIds
  for (const node of newNodes) {
    if (node.nextNodeId) {
      const newNodeId = oldNodeIdToNewNodeId.get(node.nextNodeId);
      if (newNodeId) {
        node.nextNodeId = newNodeId;
      }
    }
  }

  const newStartNodeId = oldNodeIdToNewNodeId.get(startNodeId);
  if (!newStartNodeId) {
    throw new Error('Somehow lost the start node');
  }

  return [newNodes, newStartNodeId];
}

const duplicateSequenceMutation = gql`
  mutation DuplicateSequence($duplicateSequenceInput: CreateSequenceInput!) {
    createSequence(input: $duplicateSequenceInput) {
      sequence {
        id
      }
    }
  }
`;

const addSafetyInfoMutation = gql`
  mutation AddSafetyInformation($input: AddSequenceSafetyInfoInput!) {
    addSequenceSafetyInfo(input: $input) {
      sequence {
        id
        safetyInformation {
          id
          cmiUrl
          doctorLetterTemplate
          cmiCopyMarkdown
        }
      }
    }
  }
`;

const appendSequenceFaqMutation = gql`
  mutation AppendSequenceFaq($input: AppendSequenceFaqInput!) {
    appendSequenceFaq(input: $input) {
      sequence {
        id
        faqs {
          id
          question
          answerMarkdown
          videoUrl
        }
      }
    }
  }
`;
