import { config } from 'config';

export const routes = {
  home: '/',
  login: '/login',
  consultations: '/consultations',
  consultation: '/consultations/:consultationId',
  customers: '/customers',
  customer: '/customers/:customerId',
  chat: '/customers/:customerId/chat',
  customerPurchase: '/customers/:customerId/purchases/:purchaseId',
  flows: '/flows',
  clinicians: '/clinicians',
  clinician: '/clinicians/:clinicianId',
  createClinician: '/clinicians/new',
  healthCoaches: '/health-coaches',
  healthCoach: '/health-coaches/:healthCoachId',
  createHealthCoach: '/health-coaches/new',
  products: '/products',
  product: '/products/:productId',
  plans: '/plans',
  plan: '/plans/:planId',
  createPlan: '/plans/new',
  delivery: '/delivery',
  legacyDiscounts: '/discounts/legacy',
  legacyDiscount: '/discounts/legacy/:discountId',
  createLegacyDiscount: '/discounts/legacy/new',
  couponCampaignSummaries: '/discounts/coupon-campaigns',
  couponCampaign: '/discounts/coupon-campaigns/:couponCampaignId',
  discounts: '/discounts',
  orders: '/orders/engine',
  order: '/orders/engine/:orderId',
  scripts: '/scripts',
  script: '/scripts/:scriptId',
  pathology: '/pathology/:pathologyRequestId',
  pathologies: '/pathology',
  permissions: '/permissions',
  awaitingScripts: '/awaiting-scripts',
  trackingCustomer: '/tracking/:customerId',
  tracking: '/tracking',
  subscriptionPlans: '/subscription-plans',
  subscriptionPlan: '/subscription-plans/:subscriptionPlanId',
  createSubscriptionPlan: '/subscription-plans/new',
  educationPrograms: '/education/programs',
  educationReadings: '/education/readings',
  educationReading: '/education/readings/:readingId',
  educationCollections: '/education/collections',
  packingBundles: '/packing-bundles',
  packingBundle: '/packing-bundles/:bundleId',
  createPackingBundle: '/packing-bundles/create',
  contentItems: '/content/items',
  contentCollections: '/content/collections',
  contentCollection: '/content/collections/:collectionId',
  sequences: '/sequences',
  createSequence: '/sequences/new',
  sequence: '/sequences/:sequenceId',
  offerings: '/offerings',
  createOffering: '/offerings/new',
  offering: '/offerings/:offeringId',
  bulkActions: '/bulk-actions',
  bulkAction: '/bulk-actions/:batchJobId',
  createBulkAction: '/bulk-actions/new',
  coreOrder: '/orders/core/:coreOrderId',
  queues: '/queues',
  pillars: '/pillars',
  pillar: '/pillars/:pillarTemplateId',
  goals: '/goals',
  goal: '/goals/:goalTemplateId',
  salesChannels: '/sales-channels',
  customerNotes: '/customers/:customerId/notes',
  healthCoachingFlows: '/health-coaching-flows',
  healthCoachingFlow: '/health-coaching-flows/:flowId',
  objectives: '/objectives',
  objective: '/objectives/:objectiveId',
  actions: '/actions',
  consignments: '/consignments',
} as const;

export const buildRoute = {
  consultation: (id: string) => `/consultations/${id}`,
  customer: (id: string) => `/customers/${id}`,
  chat: (id: string) => `/customers/${id}/chat`,
  product: (id: string) => `/products/${id}`,
  pathology: (id: string) => `/pathology/${id}`,
  plan: (id: string) => `/plans/${id}`,
  order: (id: string) => `/orders/engine/${id}`,
  discountCode: (id: string) => `/discounts/${id}`,
  couponCampaign: (id: string) => `/discounts/coupon-campaigns/${id}`,
  script: (id: string) => `/scripts/${id}`,
  subscriptionPlan: (id: string) => `/subscription-plans/${id}`,
  offering: (id: string) => `/offerings/${id}`,
  sequence: (id: string) => `/sequences/${id}`,
  contentCollection: (id: string) => `/content/collections/${id}`,
  customerPurchase: (customerId: string, purchaseId: string) =>
    `/customers/${customerId}/purchases/${purchaseId}`,
  sequenceContext: (
    customerId: string,
    purchaseId: string,
    sequenceContextId: string,
  ) =>
    `/customers/${customerId}/purchases/${purchaseId}?csc=${sequenceContextId}`,
  coreOrder: (id: string) => `/orders/core/${id}`,
  pillar: (id: string, tab: 'details' | 'levels' = 'details') =>
    `/pillars/${id}?tab=${tab}`,
  goal: (id: string) => `/goals/${id}`,
  goals: (params?: {
    pillarId?: string;
    levelId?: string;
    createGoal?: string;
  }) => buildParams(`/goals`, params),
  shopifyOrder: (id: string) =>
    `https://${config.shopifyHostname}/admin/orders/${id}?orderListBeta=true`,
  aponsOrder: (id: string) =>
    `https://www.dhl.de/de/privatkunden.html?piececode=${id}`,
  stripePayment: (id: string) => `https://dashboard.stripe.com/payments/${id}`,
  zipPayment: (id: string) => `https://merchant.zip.co/orders/${id}`,
  customerNotes: (customerId: string) => `/customers/${customerId}/notes`,
  clinician: (id: string) => `/clinicians/${id}`,
  healthCoachingFlow: (id: string) => `/health-coaching-flows/${id}`,
  objective: (id: string) => `/objectives/${id}`,
} as const;

const buildParams = (path: string, params?: Record<string, string>) => {
  if (!params) {
    return path;
  }
  const searchParams = new URLSearchParams(params);
  return `${path}?${searchParams.toString()}`;
};
